import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useToasts } from "$src/stores/useToast";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";

import type { BFFInput } from "@tracksuit/bff/trpc";

import { raygun } from "../lib/raygun";

const sortUsers = ({ email: a }: any, { email: b }: any) => {
  return a!.localeCompare(b);
};

export const useSettings = () => {
  const { data, isLoading: loading } =
    apiQueryClient.settings.listUsers.useQuery();

  return {
    data: data?.sort(sortUsers),
    loading,
  };
};

export const useMutateSettings = () => {
  const accounts = useAccount((s) => s.accounts);
  const queryClient = useQueryClient();
  const utils = apiQueryClient.useUtils();
  const addToast = useToasts((s) => s.add);
  const getAccounts = (accountIds: number[]) =>
    accountIds.map((id) => accounts?.find((acc: any) => id === acc.accountId));

  const onSettled = () => utils.settings.listUsers.invalidate();
  const onError = (err: any, data: any, context: any) => {
    utils.settings.listUsers.setData(undefined, context.previousState);
    switch (err?.response?.status) {
      case 409:
        raygun?.("send", err);
        addToast("error", {
          id: "settings-error",
          title: "A user with that email address already exists",
        });
        break;
      default:
        raygun?.("send", err);
        addToast("error", {
          id: "settings-error",
          title: "Updating settings failed",
        });
    }
  };
  const getOnMutate = (
    method: "addUser" | "updateUser" | "deleteUser",
    data?:
      | BFFInput["settings"]["updateUser"]
      | BFFInput["settings"]["addUser"]
      | BFFInput["settings"]["deleteUser"],
  ) => {
    return async function () {
      await queryClient.cancelQueries({
        queryKey: getQueryKey(apiQueryClient.settings.listUsers),
      });

      const previousState = utils.settings.listUsers.getData();

      utils.settings.listUsers.setData(undefined, (old: any): any => {
        switch (method) {
          case "addUser":
            return [
              ...(old ?? []),
              {
                userId: "",
                email: (data as BFFInput["settings"]["addUser"]).email,
                role: (data as BFFInput["settings"]["addUser"]).role,
                status: "FORCE_CHANGE_PASSWORD",
                accounts: getAccounts(
                  (data as BFFInput["settings"]["addUser"]).accountIds ?? [],
                ),
              },
            ].sort(sortUsers);
          case "updateUser":
            return [
              ...(old?.filter(
                (user: any) =>
                  user.userId !==
                  (data as BFFInput["settings"]["updateUser"]).id,
              ) ?? []),
              {
                userId: (data as BFFInput["settings"]["updateUser"]).id,
                email: (data as BFFInput["settings"]["updateUser"]).email,
                role: (data as BFFInput["settings"]["updateUser"]).role,
                accounts: getAccounts(
                  (data as BFFInput["settings"]["updateUser"]).accountIds ?? [],
                ),
                status: old?.find(
                  (user: any) =>
                    user.userId ===
                    (data as BFFInput["settings"]["updateUser"]).id,
                )?.status,
              },
            ].sort(sortUsers);
          case "deleteUser":
            return old
              ?.filter((user: any) => user.userId !== data)
              .sort(sortUsers);
          default:
            return old;
        }
      });

      return { previousState };
    };
  };

  return {
    updateUser: apiQueryClient.settings.updateUser.useMutation({
      onMutate: (data) => getOnMutate("updateUser", data),
      onSettled,
      onError,
    }),
    addUser: apiQueryClient.settings.addUser.useMutation({
      onMutate: (data) => getOnMutate("addUser", data),
      onSettled,
      onError,
    }),
    deleteUser: apiQueryClient.settings.deleteUser.useMutation({
      onMutate: (data) => getOnMutate("deleteUser", data),
      onSettled,
      onError,
    }),
  };
};
