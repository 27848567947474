import type { RouteObject } from "react-router-dom";

import { SettingsLayout } from "./layout";
import { MfaSettingsRoute } from "./routes/mfa/mfa";
import { TokensSettingsRoute } from "./routes/tokens/tokens";
import { UsersSettingsRoute } from "./routes/users/users";
import { SettingsRoute } from "./settings";

export const settingsRoutes: RouteObject["children"] = [
  {
    Component: SettingsLayout,
    children: [
      {
        index: true,
        Component: SettingsRoute,
      },
      { path: "mfa", Component: MfaSettingsRoute },
      { path: "tokens", Component: TokensSettingsRoute },
      { path: "users", Component: UsersSettingsRoute },
    ],
  },
];
