import { Button } from "$src/components/button/button";
import { IconButton } from "$src/components/icon-button/icon-button";
import { Modal } from "$src/components/modal/modal";
import { useAmplifyAuth } from "$src/hooks/useAmplifyAuth";
import { HoverContext } from "$src/hooks/useHover";
import { raygun } from "$src/lib/raygun";
import { cx } from "$src/lib/utils";
import { useMutateTokens } from "$src/queries/useTokens";
import { useToasts } from "$src/stores/useToast";
import { format, isAfter, parseISO } from "date-fns";
import { Trash2 } from "lucide-react";
import { useState } from "react";

import type { BFFOutput } from "@tracksuit/bff/trpc";

import { Column } from "../../column/column";
import styles from "./token-row.module.css";

export type Token = NonNullable<BFFOutput["settings"]["listTokens"]>[0];

export type TokenRowProps = {
  token: Token;
};

enum TokenStatus {
  Active = "Active",
  Expired = "Expired",
  Revoked = "Revoked",
}

type TokenStatusType = `${TokenStatus}`;

export const TokenRow = ({ token }: TokenRowProps) => {
  const [hovering, setHovering] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { user } = useAmplifyAuth();
  const { revokeToken } = useMutateTokens();
  const addToast = useToasts((s) => s.add);

  // v8 ignore next
  const deleteToken = async () => {
    const revokeTokenError = () => {
      raygun?.("send", new Error(`Failed to delete ${token.name}`));
      addToast("error", {
        id: "tokens-error",
        title: `Failed to delete ${token.name}`,
      });
    };
    revokeToken.mutate(
      { userId: user?.username ?? "", tokenId: token.id },
      {
        onError: () => {
          revokeTokenError();
        },
      },
    );
    setDeleteOpen(false);
  };

  const checkStatus = (): TokenStatusType => {
    if (token.revokedAt) {
      return TokenStatus.Revoked;
    }
    if (isAfter(new Date(), new Date(token.expiresAt))) {
      return TokenStatus.Expired;
    }
    return TokenStatus.Active;
  };

  const statusCircleStyling = () => {
    if (checkStatus() === "Expired") {
      return styles["status-circle-orange"];
    } else if (checkStatus() === "Revoked") {
      return styles["status-circle-red"];
    }
    return;
  };

  return (
    <HoverContext.Provider value={{ hovering, setHovering }}>
      <Column first>
        <span className={styles.clipped} data-testid="token-name">
          {token.name}
        </span>
      </Column>
      <Column data-testid="date-created">
        <span className={styles.date}>
          {format(parseISO(token.createdAt), "dd MMM yyyy")}
        </span>
      </Column>
      <Column>
        <span>{format(parseISO(token.expiresAt), "dd MMM yyyy")}</span>
      </Column>
      <Column>
        <div className={styles["status"]} data-testid="status">
          <span
            className={cx(styles["status-circle"], statusCircleStyling())}
          />
          <span>{checkStatus()}</span>
        </div>
      </Column>
      <Column className={styles.actions} last data-testid="row">
        {token.revokedAt ?? checkStatus() === TokenStatus.Expired ? null : (
          <IconButton
            tip={"Delete token"}
            icon={Trash2}
            hidden={!hovering}
            onClick={() => setDeleteOpen(true)}
            data-testid="delete-icon"
          />
        )}
      </Column>

      <Modal
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        heading="You are about to delete the following token"
      >
        <p>{token.name}</p>
        <div className={styles.actions}>
          <Button
            theme="ghost"
            label="Cancel"
            onClick={() => setDeleteOpen(false)}
          />
          <Button label="Delete this token" onClick={() => deleteToken()} />
        </div>
      </Modal>
    </HoverContext.Provider>
  );
};
