import type { BFFOutput } from "@tracksuit/bff/trpc";

export function getMaxValue(
  data?: BFFOutput["statements"]["getMetrics"]["statements"],
) {
  return data
    ? Number(
        Math.max(
          ...data.map((brand) =>
            Math.max(
              ...(brand.metrics?.map((metric) => metric.percentage) ?? []),
            ),
          ),
        ).toFixed(1),
      ) + 0.1
    : 0;
}
