import { Checkbox } from "$src/components/checkbox/checkbox";
import { ControlledCollapsible } from "$src/components/collapsible/collapsible";
import {
  Dropdown,
  DropdownItem,
  DropdownSection,
} from "$src/components/dropdown/dropdown";
import { RadioButton } from "$src/components/radio-button/radio-button";
import { useActiveFiltersLabel } from "$src/hooks/useActiveFiltersLabel";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useFunnelQuestions } from "$src/hooks/useFunnelQuestions";
import { regionalize } from "$src/lib/regionalization";
import { addAllToSet, cx } from "$src/lib/utils";
import { Question } from "$src/models/Funnel";
import { useFunnel } from "$src/queries/funnel";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { HiddenItems } from "$src/views/hidden-items/hidden-items";
import { useDeepCompareEffect } from "@react-hookz/web";
import { sentenceCase } from "change-case";
import { chunk } from "lodash-es";
import {
  type ComponentProps,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import type { BFFOutput } from "@tracksuit/bff/trpc";
import type { ConversionQuestionType } from "@tracksuit/frontend/schemas";
import { sortBrands } from "@tracksuit/frontend/utils";

import styles from "./conversion.module.css";
import { BrandConversionFunnel } from "./lib/brand-funnel/brand-conversion-funnel";
import { useChunkLength } from "./lib/useChunkLength";

export type ConversionTargets = {
  sourceMetrics: Set<ConversionQuestionType>;
  intermediateMetrics: Set<ConversionQuestionType>;
  targetMetrics: Set<ConversionQuestionType>;
};

export type Conversion =
  | "awrToCns"
  | "cnsToPrf"
  | "cnsToInv"
  | "cnsToUsg"
  | "invToUsg"
  | "usgToPrf";

export type ConversionOptions = {
  awrToCns: boolean;
  cnsToPrf: boolean;
  cnsToInv: boolean;
  cnsToUsg: boolean;
  invToUsg: boolean;
  usgToPrf: boolean;
};

export const ConversionContext = createContext<{
  conversions?: ConversionOptions;
  conversionTargets?: ConversionTargets;
}>({});

/**
 * @component
 * Single conversion table
 */
const FunnelConversionTable = ({
  data,
  className,
  ...props
}: {
  data?: BFFOutput["funnel"]["getMetrics"]["brands"];
} & ComponentProps<"div">) => {
  const chunkLength = useChunkLength();
  const account = useAccount((s) => s.active);
  const { loading } = useFunnel();
  const stages = useFunnelQuestions({
    unprompted: false,
  });

  if (!chunkLength) {
    return null;
  }

  return (
    <div
      data-testid="conversion-table"
      className={cx(styles.funnel, className)}
      {...props}
    >
      <div className={styles.rows}>
        {stages.map((_, i) => (
          <div className={cx(styles.row, loading && styles.loading)} key={i} />
        ))}
      </div>
      <div className={styles.labels}>
        {stages.map((stage) => (
          <span className={styles.label} key={stage}>
            {sentenceCase(
              regionalize(Question[stage] ?? "", account?.geography.name),
            )}
          </span>
        ))}
      </div>
      {loading
        ? Array.from({ length: chunkLength }).map((_, i) => (
            <div className={styles.placeholder} key={i} />
          ))
        : data?.map((data, i) => (
            <BrandConversionFunnel
              className={styles["funnel-brand"]}
              data={data}
              key={i}
            />
          ))}
    </div>
  );
};

/**
 * @component
 * Funnel showing conversion between stages
 */
export const FunnelConversion = ({
  className,
  ...props
}: ComponentProps<"div">) => {
  const { brandIdList } = useFilters((s) => s.filters);
  const { data, loading } = useFunnel();
  const activeFiltersLabel = useActiveFiltersLabel();
  const stages = useFunnelQuestions({});
  const [chunks, setChunks] = useState([data?.brands]);
  const chunkLength = useChunkLength();
  const activeAccountBrand = useAccount((s) => s.active);
  const [useRecommendedOptions, setUseRecommendedOptions] = useState(true);
  const [conversionTargets, setConversionTargets] = useState<ConversionTargets>(
    {
      sourceMetrics: new Set(),
      intermediateMetrics: new Set(),
      targetMetrics: new Set(),
    },
  );
  const [conversions, setConversions] = useState<ConversionOptions>({
    awrToCns: false,
    cnsToPrf: false,
    cnsToInv: false,
    cnsToUsg: false,
    invToUsg: false,
    usgToPrf: false,
  });
  const options: {
    [key in Conversion]: {
      label: string;
      disabled: boolean;
    };
  } = useMemo(
    () => ({
      awrToCns: {
        label: "Awareness to Consideration",
        disabled: false,
      },
      cnsToPrf: {
        label: "Consideration to Preference",
        disabled:
          !useRecommendedOptions &&
          !conversions.cnsToPrf &&
          (conversions.cnsToInv ||
            conversions.cnsToUsg ||
            conversions.invToUsg ||
            conversions.usgToPrf),
      },
      cnsToInv: {
        label: "Consideration to Investigation",
        disabled:
          !useRecommendedOptions &&
          !conversions.cnsToInv &&
          (conversions.cnsToPrf || conversions.cnsToUsg),
      },
      cnsToUsg: {
        label: "Consideration to Usage",
        disabled:
          !useRecommendedOptions &&
          !conversions.cnsToUsg &&
          (conversions.cnsToInv ||
            conversions.cnsToPrf ||
            conversions.invToUsg),
      },
      invToUsg: {
        label: "Investigation to Usage",
        disabled:
          !useRecommendedOptions &&
          !conversions.invToUsg &&
          (conversions.cnsToPrf || conversions.cnsToUsg),
      },
      usgToPrf: {
        label: "Usage to Preference",
        disabled:
          !useRecommendedOptions &&
          !conversions.usgToPrf &&
          conversions.cnsToPrf,
      },
    }),
    [useRecommendedOptions, conversions],
  );
  const optionGroups: {
    single: Conversion[];
    multiple: Conversion[];
  } = useMemo(
    () => ({
      single: [
        "awrToCns",
        stages.includes("INVESTIGATION") && "cnsToInv",
        stages.includes("INVESTIGATION") && "invToUsg",
        !stages.includes("INVESTIGATION") && "cnsToUsg",
        "usgToPrf",
      ].filter(Boolean) as Conversion[],
      multiple: [
        stages.includes("INVESTIGATION") && "cnsToUsg",
        "cnsToPrf",
      ].filter(Boolean) as Conversion[],
    }),
    [stages],
  );

  useEffect(() => {
    setConversions({
      awrToCns: useRecommendedOptions,
      cnsToPrf: useRecommendedOptions,
      cnsToInv: false,
      cnsToUsg: false,
      invToUsg: false,
      usgToPrf: false,
    });
  }, [useRecommendedOptions]);

  useEffect(() => {
    setConversionTargets({
      sourceMetrics: new Set(),
      intermediateMetrics: new Set(),
      targetMetrics: new Set(),
    });

    conversions.awrToCns &&
      setConversionTargets((s) => ({
        sourceMetrics: s.sourceMetrics.add("PROMPTED_AWARENESS"),
        intermediateMetrics: s.intermediateMetrics,
        targetMetrics: s.targetMetrics.add("CONSIDERATION"),
      }));

    conversions.cnsToPrf &&
      setConversionTargets((s) => ({
        sourceMetrics: s.sourceMetrics.add("CONSIDERATION"),
        intermediateMetrics: addAllToSet<ConversionQuestionType>(
          ["INVESTIGATION", "USAGE"],
          s.intermediateMetrics,
        ),
        targetMetrics: s.targetMetrics.add("PREFERENCE"),
      }));

    conversions.cnsToInv &&
      setConversionTargets((s) => ({
        sourceMetrics: s.sourceMetrics.add("CONSIDERATION"),
        intermediateMetrics: s.intermediateMetrics,
        targetMetrics: s.targetMetrics.add("INVESTIGATION"),
      }));

    conversions.cnsToUsg &&
      setConversionTargets((s) => ({
        sourceMetrics: s.sourceMetrics.add("CONSIDERATION"),
        intermediateMetrics: s.intermediateMetrics.add("INVESTIGATION"),
        targetMetrics: s.targetMetrics.add("USAGE"),
      }));

    conversions.invToUsg &&
      setConversionTargets((s) => ({
        sourceMetrics: s.sourceMetrics.add("INVESTIGATION"),
        intermediateMetrics: s.intermediateMetrics,
        targetMetrics: s.targetMetrics.add("USAGE"),
      }));

    conversions.usgToPrf &&
      setConversionTargets((s) => ({
        sourceMetrics: s.sourceMetrics.add("USAGE"),
        intermediateMetrics: s.intermediateMetrics,
        targetMetrics: s.targetMetrics.add("PREFERENCE"),
      }));
  }, [conversions]);

  useDeepCompareEffect(() => {
    const filtered = data?.brands
      ?.map((item) => ({
        ...item,
        metrics: item.metrics.filter(
          ({ questionType }: any) => questionType !== "UNPROMPTED_AWARENESS",
        ),
      }))
      .filter((brand) => brandIdList?.includes(brand.brandId ?? 0))
      .sort(sortBrands);

    setChunks(chunk(filtered, chunkLength));
  }, [chunkLength, data?.brands, brandIdList]);

  const analyticsTrack = useAnalyticsTrack(
    conversions,
    options,
    optionGroups,
    useRecommendedOptions,
  );

  useEffect(() => {
    // We don't want to track the default option on initial render
    // Skip calling from here if the default option true because we're calling the tracking function from the Recommended radio button click to avoid duplicate tracking
    if (useRecommendedOptions) {
      return;
    }
    analyticsTrack();
  }, [useRecommendedOptions, conversions]);

  return (
    <div className={cx(className)} {...props}>
      <div className={styles.header}>
        <div className={styles["header-inner"]}>
          <h1 className={styles.heading}>
            <span>{activeAccountBrand?.brandName}</span> vs Competitor Brands
          </h1>

          <Dropdown
            data-x-hidden-from-screenshot
            label="Conversion options"
            theme="pill"
            align="left"
            className={styles.dropdown}
          >
            <div className={styles["dropdown-inner"]}>
              <DropdownItem
                id="conversion-option"
                onClick={() => {
                  analyticsTrack();
                  setUseRecommendedOptions(true);
                }}
              >
                <RadioButton
                  name="conversion-option"
                  label="Recommended"
                  subtext="Awareness to Consideration, Consideration to Preference"
                  className={styles["dropdown-item-label"]}
                  checked={useRecommendedOptions}
                />
              </DropdownItem>
              <DropdownItem
                id="conversion-option"
                onClick={() => setUseRecommendedOptions(false)}
              >
                <RadioButton
                  name="conversion-option"
                  label="Custom"
                  subtext="Choose your conversion metrics"
                  checked={!useRecommendedOptions}
                  className={styles["dropdown-item-label"]}
                />
              </DropdownItem>
              <ControlledCollapsible open={!useRecommendedOptions}>
                <DropdownSection label="Consecutive metrics">
                  {optionGroups.single.map((conversion) => (
                    <DropdownItem
                      id="conversion-option"
                      key={conversion}
                      className={styles["dropdown-item"]}
                      disabled={options[conversion].disabled}
                      onClick={() => {
                        setConversions((s) => ({
                          ...s,
                          [conversion]: !conversions[conversion],
                        }));
                      }}
                    >
                      <Checkbox
                        label={options[conversion].label}
                        className={styles["dropdown-item-label"]}
                        checked={
                          !useRecommendedOptions && conversions[conversion]
                        }
                      />
                    </DropdownItem>
                  ))}
                </DropdownSection>
                <DropdownSection label="Other metrics">
                  {optionGroups.multiple.map((conversion) => (
                    <DropdownItem
                      id="conversion-option"
                      key={conversion}
                      className={styles["dropdown-item"]}
                      disabled={options[conversion].disabled}
                      onClick={() => {
                        setConversions((s) => ({
                          ...s,
                          [conversion]: !conversions[conversion],
                        }));
                      }}
                    >
                      <Checkbox
                        label={options[conversion].label}
                        className={styles["dropdown-item-label"]}
                        checked={
                          !useRecommendedOptions && conversions[conversion]
                        }
                      />
                    </DropdownItem>
                  ))}
                </DropdownSection>
              </ControlledCollapsible>
            </div>
          </Dropdown>
        </div>
        <span className={styles["filters-label"]}>
          Filtered by: {activeFiltersLabel}
        </span>
      </div>
      <div className={styles["funnel-wrapper"]}>
        <div>
          <ConversionContext.Provider
            value={{ conversions, conversionTargets }}
          >
            {(loading ? Array.from({ length: 1 }) : chunks).map((data, i) => (
              <FunnelConversionTable
                key={i}
                data={data as (typeof chunks)[0]}
              />
            ))}
          </ConversionContext.Provider>
        </div>
      </div>
      <HiddenItems type="brands" data-testid="hidden-items" />
    </div>
  );
};

const useAnalyticsTrack = (
  conversions: ConversionOptions,
  options: {
    awrToCns: { label: string; disabled: boolean };
    cnsToPrf: { label: string; disabled: boolean };
    cnsToInv: { label: string; disabled: boolean };
    cnsToUsg: { label: string; disabled: boolean };
    invToUsg: { label: string; disabled: boolean };
    usgToPrf: { label: string; disabled: boolean };
  },
  optionGroups: { single: Conversion[]; multiple: Conversion[] },
  tracksuitDefault: boolean,
) => {
  const analytics = useAnalytics();

  const analyticsTrack = useCallback(() => {
    const metrics = Object.keys(conversions)
      .filter((key) => conversions[key as keyof typeof conversions])
      .map((key) => {
        return options[key as keyof typeof options].label;
      });

    const consecutiveMetrics = optionGroups.single.some(
      (key) => conversions[key as keyof typeof conversions],
    );

    const otherMetrics = optionGroups.multiple.some(
      (key) => conversions[key as keyof typeof conversions],
    );

    analytics?.track(AnalyticsEvents.ChangedConversionOptions, {
      option: tracksuitDefault ? "Recommended" : "Custom",
      metrics,
      types: {
        consecutiveMetrics,
        otherMetrics,
      },
    });
  }, [conversions, options, optionGroups, analytics, tracksuitDefault]);

  return analyticsTrack;
};
