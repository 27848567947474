import { cx } from "$src/lib/utils";
import { motion } from "framer-motion";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import type { ReactNode } from "react";

import { Tooltip } from "../tooltip/tooltip";
import styles from "./toggle.module.css";

type ToggleOptionsProps = {
  label: string;
  value: string;
  disabled?: boolean;
  tip?: ReactNode;
};

export type ToggleProps = {
  options: ToggleOptionsProps[];
  selected: string;
  onChange: (value: string) => void;
  animated?: boolean;
  className?: string;
  compact?: boolean;
};

export function Toggle({
  options,
  compact = false,
  selected,
  onChange,
  animated = true,
  className,
  ...props
}: ToggleProps) {
  const [group, setGroup] = useState<string>();

  useEffect(() => {
    setGroup(nanoid());
  }, []);

  return group ? (
    <motion.form
      layout
      layoutRoot
      className={cx(styles.form, className)}
      {...props}
    >
      {options.map((option) => (
        <Tooltip tip={option.tip} disabled={!option.tip} key={option.value}>
          <label
            className={cx(
              styles.toggle,
              compact && styles.compact,
              option.disabled && styles.disabled,
            )}
            data-testid={`label-${option.value}`}
            key={option.value}
          >
            {option.value === selected && (
              <motion.div
                layout={animated ? "position" : undefined}
                layoutId={animated ? group : undefined}
                className={styles.highlight}
              />
            )}
            <input
              type="radio"
              radioGroup={group}
              onChange={({ target }) =>
                target.checked && onChange(option.value)
              }
              value={option.value}
              data-testid={option.value}
              checked={option.value === selected}
              hidden
            />
            <span
              className={cx(
                styles.label,
                option.value === selected && styles.active,
              )}
            >
              {option.label}
            </span>
          </label>
        </Tooltip>
      ))}
    </motion.form>
  ) : null;
}
