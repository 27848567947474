import { z } from "zod";

import { naturalNumber } from "./numbers";

const brand = z.object({
  id: naturalNumber,
  name: z.string(),
  brandCategoryGeographyId: naturalNumber,
});

/**
 * Account response from metadata
 */
export const account = z.object({
  accountId: naturalNumber.optional(),
  accountBrandId: naturalNumber,
  brandId: naturalNumber,
  clientId: naturalNumber.or(z.string()),
  brandName: z.string(),
  accountName: z.string().optional(),
  brandCategoryGeographyId: naturalNumber.optional(),
  category: z.object({
    id: naturalNumber,
    name: z.string(),
  }),
  geography: z.object({
    id: naturalNumber,
    name: z.string(),
  }),
  competitors: brand.array().optional(),
  trackers: z
    .object({
      id: naturalNumber,
      name: z.string(),
      stateCode: z.string().optional(),
      stateName: z.string().optional(),
    })
    .array(),
  featuresConfig: z.object({
    statementsEnabled: z.boolean(),
    profileEnabled: z.boolean(),
    imageryEnabled: z.boolean(),
    investigationEnabled: z.boolean(),
    unpromptedEnabled: z.boolean(),
    usageEnabled: z.boolean(),
  }),
});

export const fullAccount = account.extend({
  otherBrands: brand.array().optional(),
});
