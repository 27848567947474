import { useRequiredDatesCount } from "$src/hooks/useRequiredDatesCount";
import { useValidDatePeriod } from "$src/hooks/useValidDatePeriod";
import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";

import { FunnelQuestionType } from "@tracksuit/frontend/schemas";

export const useFunnelComparison = ({
  question,
}: {
  question?: FunnelQuestionType;
}) => {
  const account = useAccount((s) => s.active);
  const [{ datePeriod, demographics, brandIdList }, filtersReady] = useFilters(
    (s) => [s.filters, s.ready],
  );
  const sufficient = useRequiredDatesCount(6);
  const valid = useValidDatePeriod();
  const { data, isLoading: loading } =
    apiQueryClient.funnel.getComparison.useQuery(
      {
        accountBrandId: account!.accountBrandId,
        startDate: datePeriod.start!,
        endDate: datePeriod.end!,
        filters: demographics?.map(({ filter }) => filter) ?? [],
        filterIds: demographics?.map(({ id }) => id) ?? [],
        comparisonPeriod: datePeriod.comparisonPeriod!,
        questionType: question!,
        brandIds: brandIdList,
      },
      {
        enabled:
          sufficient &&
          valid.comparison &&
          !!account &&
          !!question &&
          filtersReady,
      },
    );

  return {
    data,
    sufficient,
    loading,
  };
};
