import { DIRECTIONS } from "$src/components/difference-label/difference-label";
import {
  formatNumberRepresentation,
  percentageFormatter,
} from "$src/lib/utils";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { ComponentProps, useMemo } from "react";

import type { DataRepresentation } from "@tracksuit/frontend/schemas";

import type { LineData } from "../line-chart";

const OUTER_WIDTH = 105;
const OUTER_HEIGHT = 30;
const DIFFERENCE_MARGIN = 4;
const DIFFERENCE_PADDING = 6;
const DIFFERENCE_WIDTH = 50;
const DIFFERENCE_ICON_SIZE = 14;
const DIFFERENCE_HEIGHT = OUTER_HEIGHT - DIFFERENCE_MARGIN * 2;
const COLOR = {
  positive: "var(--color-green-500)",
  negative: "var(--color-red-500)",
  neutral: "var(--color-text)",
};

export const MarkerEnd = ({
  data,
  numberFormat,
  y = 0,
  ...props
}: {
  data: LineData;
  numberFormat: DataRepresentation;
} & ComponentProps<"svg">) => {
  const direction: keyof typeof DIRECTIONS = useMemo(() => {
    if (Math.abs(data.difference.percentage) < 0.01) {
      return "neutral";
    }
    return data.difference.percentage > 0 ? "positive" : "negative";
  }, [data]);

  const Icon = DIRECTIONS[direction].icon;

  return (
    <svg
      y={(y as number) - OUTER_HEIGHT / 2}
      id={`${data.id}-active-end`}
      data-testid="line-chart-marker-end"
      {...props}
    >
      <rect
        width={OUTER_WIDTH}
        height={OUTER_HEIGHT}
        ry={OUTER_HEIGHT / 2}
        fill={data.color}
      />
      <rect
        x={OUTER_WIDTH - DIFFERENCE_WIDTH - DIFFERENCE_MARGIN}
        y={DIFFERENCE_MARGIN}
        width={DIFFERENCE_WIDTH}
        height={DIFFERENCE_HEIGHT}
        ry={DIFFERENCE_HEIGHT / 2}
        fill="var(--color-bg)"
      />
      <Text
        x={DIFFERENCE_PADDING + DIFFERENCE_MARGIN}
        y={OUTER_HEIGHT / 2}
        width={
          OUTER_WIDTH -
          DIFFERENCE_WIDTH -
          DIFFERENCE_PADDING * 2 -
          DIFFERENCE_MARGIN * 2
        }
        textAnchor="start"
        verticalAnchor="middle"
        fill="var(--color-off-white)"
        scaleToFit="shrink-only"
        style={{
          fontWeight: "var(--weight-semibold)",
        }}
      >
        {formatNumberRepresentation(
          data.points[data.points.length - 1]?.y ?? 0,
          numberFormat,
        )}
      </Text>
      <Icon
        width={DIFFERENCE_ICON_SIZE}
        height={DIFFERENCE_ICON_SIZE}
        stroke={
          data.difference.isSignificant ? COLOR[direction] : COLOR.neutral
        }
        x={
          OUTER_WIDTH -
          DIFFERENCE_WIDTH +
          DIFFERENCE_PADDING -
          DIFFERENCE_MARGIN
        }
        y={OUTER_HEIGHT / 2 - DIFFERENCE_ICON_SIZE / 2}
      />
      <Text
        x={OUTER_WIDTH - DIFFERENCE_MARGIN - DIFFERENCE_PADDING}
        y={OUTER_HEIGHT / 2}
        width={DIFFERENCE_WIDTH - DIFFERENCE_ICON_SIZE - DIFFERENCE_PADDING}
        textAnchor="end"
        verticalAnchor="middle"
        fill={data.difference.isSignificant ? COLOR[direction] : COLOR.neutral}
        scaleToFit="shrink-only"
        style={{
          font: "var(--text-sm)",
          fontWeight: "var(--weight-semibold)",
        }}
      >
        {direction === "neutral"
          ? "Nil"
          : percentageFormatter
              .format(data.difference.percentage)
              .replace("-", "")}
      </Text>
    </svg>
  );
};
