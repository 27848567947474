import { type DropdownProps } from "$src/components/dropdown/dropdown";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useFunnelQuestions } from "$src/hooks/useFunnelQuestions";
import { regionalize } from "$src/lib/regionalization";
import { Question } from "$src/models/Funnel";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { SampleIndicator } from "$src/views/sample-indicator/sample-indicator";
import { sentenceCase } from "change-case";
import { useEffect, useMemo } from "react";

import type { QuestionType, SampleQuality } from "@tracksuit/frontend/schemas";

import { MultiSelectable } from "../mutli-selectable/multi-selectable";

export type FilterQuestionProps = {
  /** Quality of the sample */
  quality?: SampleQuality;
  /** Whether the filter is multiselect */
  multiselect?: boolean;
  /** Optional config for the list */
  config?: {
    category?: boolean;
    unprompted?: boolean;
    investigation?: boolean;
  };
  className?: string;
} & Partial<DropdownProps>;

/**
 * @component
 * Question filter
 */
export const FilterQuestions = ({
  quality,
  multiselect = false,
  config: { category = false, unprompted = true, investigation = true } = {},
  className,
}: FilterQuestionProps) => {
  const account = useAccount((s) => s.active);
  const analytics = useAnalytics();
  const questions = useFunnelQuestions({ unprompted, investigation });
  const allQuestions = useMemo(
    () =>
      [...questions, category && "QUALIFYING"].filter(
        Boolean,
      ) as QuestionType[],
    [questions, category],
  );
  const [{ question, questionList }, setFilters] = useFilters((s) => [
    s.filters,
    s.set,
  ]);
  const activeLabel = useMemo(() => {
    if (multiselect && questionList.length) {
      return questionList.length > 1
        ? `Multiple metrics (${questionList.length})`
        : sentenceCase(
            regionalize(
              Question[questionList[0] as QuestionType] as string,
              account?.geography.name,
            ),
          );
    }

    if (!multiselect && question) {
      return sentenceCase(regionalize(Question[question] as string));
    }

    return undefined;
  }, [questionList, question]);

  const isActive = (q: QuestionType) => {
    if (multiselect) {
      return !!questionList?.includes(q);
    }

    return q === question;
  };

  const updateQuestion = (q: QuestionType) => {
    if (multiselect === true && q !== undefined) {
      setFilters({
        questionList: isActive(q)
          ? questionList?.filter((qu) => qu !== q)
          : [...questionList, q],
      });
    } else {
      setFilters({
        question: q,
      });
    }
  };

  useEffect(() => {
    if (
      (!category && question === "QUALIFYING") ||
      (!unprompted && question === "UNPROMPTED_AWARENESS") ||
      (!investigation && question === "INVESTIGATION")
    ) {
      setFilters({ question: "PROMPTED_AWARENESS" });
    }
  }, [category, unprompted, investigation, question, setFilters]);

  useEffect(() => {
    const checkFlag = (flag: boolean, question: QuestionType) => {
      if (!flag && questionList.includes(question)) {
        setFilters({
          questionList: questionList.filter((q) => q !== question),
        });
      }
    };

    checkFlag(category, "QUALIFYING");
    checkFlag(unprompted, "UNPROMPTED_AWARENESS");
    checkFlag(investigation, "INVESTIGATION");
  }, [category, unprompted, investigation, questionList]);

  useEffect(() => {
    if (question) {
      analytics?.track(AnalyticsEvents.ChangeMetric, {
        metric: sentenceCase(question),
      });
    }
  }, [analytics, question]);

  return (
    <MultiSelectable
      multiselect={multiselect}
      label="Metric"
      selected={activeLabel}
      items={allQuestions.map((q) => ({
        label: regionalize(Question[q] as string, account?.geography.name),
        isActive: isActive(q),
        onClick: () => updateQuestion(q),
      }))}
      selectAll={{
        isActive: allQuestions.length === questionList.length,
        onChange: (state) =>
          setFilters({
            questionList: state ? allQuestions : [],
          }),
      }}
      className={className}
    >
      {quality !== "STRONG" && (
        <SampleIndicator quality={quality} context="question" />
      )}
    </MultiSelectable>
  );
};
