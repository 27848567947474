import {
  ControlledDropdown,
  DropdownItem,
} from "$src/components/dropdown/dropdown";
import {
  EMPTY_STATE_CONTENT,
  EmptyState,
} from "$src/components/empty-state/empty-state";
import { Layout } from "$src/components/layout/layout";
import { Summary } from "$src/components/summary/summary";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { api } from "$src/lib/api";
import { cx } from "$src/lib/utils";
import {
  useImageryBrandSummary,
  useImageryComparisonSummary,
} from "$src/queries/useImagery";
import { imageryThemes } from "$src/routes/app/routes/imagery/use-theme/useTheme";
import { useWords } from "$src/routes/app/routes/imagery/use-words/useWords";
import { useAccount } from "$src/stores/useAccount";
import { Download } from "$src/views/download/download";
import { FilterBar } from "$src/views/filter-bar/filter-bar";
import { Imagery as ImageryView } from "$src/views/imagery/imagery";
import { useFlags } from "launchdarkly-react-client-sdk";
import { differenceBy } from "lodash-es";
import { Suspense, useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";

import { BrandPerceptionExplanation } from "./brand-perception-explanation/brand-perception-explanation";
import styles from "./imagery.module.css";

const Imagery = () => {
  const accountBrand = useAccount((s) => s.active);
  const [themeDropdownOpen, setThemeDropdownOpen] = useState(false);
  const imageAreaElement = useRef<HTMLDivElement>(null);
  const { imageryDownloadsDisabled } = useFlags();
  const {
    brands,
    leftBrand,
    setLeftBrand,
    leftWords,
    rightBrand,
    setRightBrand,
    rightWords,
    theme,
    toggleTheme,
    setTheme,
    loading,
  } = useWords();

  const [summaryOpen, setSummaryOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const comparisonSummary = useImageryComparisonSummary({
    leftBrand,
    rightBrand,
    enabled: summaryOpen,
  });
  const leftBrandSummary = useImageryBrandSummary({
    brandCategoryGeographyId: leftBrand?.brandCategoryGeographyId,
    enabled: summaryOpen,
  });
  const rightBrandSummary = useImageryBrandSummary({
    brandCategoryGeographyId: rightBrand?.brandCategoryGeographyId,
    enabled: summaryOpen,
  });
  const analytics = useAnalytics();
  const getExportUrl = async () => {
    const res = await api.exports.surveyDetails.query({
      accountBrandId: accountBrand!.accountBrandId,
      subType: "IMAGERY",
      format: "CSV",
    });

    return res?.exportUrl;
  };

  /* v8 ignore next */
  // Not tested as it just calls Segment if open
  useEffect(() => {
    analytics?.track(AnalyticsEvents.SwappedCompetitor, {
      brands: [leftBrand?.brandName, rightBrand?.brandName],
    });
  }, [leftBrand, rightBrand]);

  useEffect(() => {
    analytics?.track(AnalyticsEvents.FilterUniqueWords, {
      theme: theme,
    });
  }, [theme, analytics]);

  return !!accountBrand && !accountBrand.featuresConfig.imageryEnabled ? (
    <Navigate to="/funnel" replace />
  ) : (
    <Layout>
      {!brands?.length && !loading ? (
        <EmptyState {...EMPTY_STATE_CONTENT.imagery} />
      ) : (
        <>
          <div className={cx(styles.container)}>
            <BrandPerceptionExplanation
              brandName={accountBrand?.brandName ?? ""}
              loading={false}
            />
            <FilterBar
              customFilters={
                <ControlledDropdown
                  className={styles.filter}
                  open={themeDropdownOpen}
                  onChange={setThemeDropdownOpen}
                  theme="select"
                  label="Themes"
                  selected={theme}
                >
                  {imageryThemes.map((item, index) => (
                    <DropdownItem
                      data-testid={`theme-${index}`}
                      id="imagery-filter"
                      key={item}
                      active={item === theme}
                      onClick={() => {
                        setTheme(item);
                        setThemeDropdownOpen(false);
                      }}
                    >
                      {item}
                    </DropdownItem>
                  ))}
                </ControlledDropdown>
              }
              actions={{
                summarise: () => setSummaryOpen((s) => !s),
                copyAsImage: imageAreaElement,
                download: imageryDownloadsDisabled
                  ? undefined
                  : () => setDownloadOpen(true),
              }}
            />

            <div className={styles.imagery} ref={imageAreaElement}>
              <ImageryView
                accent="purple"
                brand={leftBrand}
                words={leftWords}
                loading={loading}
                brandPicker={
                  <ImageryView.BrandPicker
                    brand={leftBrand}
                    setBrand={setLeftBrand}
                    brandOptions={differenceBy(
                      brands,
                      [rightBrand],
                      "brandCategoryGeographyId",
                    )}
                  />
                }
                themeToggles={
                  <ImageryView.ThemeToggles
                    accent="purple"
                    theme={theme}
                    toggleTheme={toggleTheme}
                    oppositeBrand={rightBrand}
                  />
                }
              />
              <ImageryView
                accent="pink"
                brand={rightBrand}
                loading={loading}
                words={rightWords}
                brandPicker={
                  <ImageryView.BrandPicker
                    brand={rightBrand}
                    setBrand={setRightBrand}
                    brandOptions={differenceBy(
                      brands,
                      [leftBrand],
                      "brandCategoryGeographyId",
                    )}
                  />
                }
                themeToggles={
                  <ImageryView.ThemeToggles
                    accent="pink"
                    theme={theme}
                    toggleTheme={toggleTheme}
                    oppositeBrand={leftBrand}
                  />
                }
              />
            </div>
          </div>

          <Summary
            open={summaryOpen}
            onClose={() => setSummaryOpen(false)}
            summaries={[
              {
                label: "Difference between these brands",
                content: comparisonSummary.data?.summary,
                loading: comparisonSummary.loading,
                error: comparisonSummary.error,
                type: "Difference",
                eventMetadata: {
                  competitor: rightBrand?.brandName,
                },
              },
              {
                label: leftBrand?.brandName,
                content: leftBrandSummary.data?.summary,
                loading: leftBrandSummary.loading,
                error: leftBrandSummary.error,
                type: "Brand",
                eventMetadata: {
                  brand: leftBrand?.brandName,
                  isAccountBrand:
                    accountBrand!.brandCategoryGeographyId ===
                    leftBrand?.brandCategoryGeographyId,
                },
              },
              {
                label: rightBrand?.brandName,
                content: rightBrandSummary.data?.summary,
                loading: rightBrandSummary.loading,
                error: rightBrandSummary.error,
                type: "Brand",
                eventMetadata: {
                  brand: rightBrand?.brandName,
                  isAccountBrand:
                    accountBrand!.brandCategoryGeographyId ===
                    rightBrand?.brandCategoryGeographyId,
                },
              },
            ]}
          />

          {!imageryDownloadsDisabled && (
            <Download
              open={downloadOpen}
              onClose={() => setDownloadOpen(false)}
              heading="Downloading your raw imagery response"
              label="Raw responses"
              fetcher={getExportUrl}
              format="CSV"
              stonly="exporting-your-imagery-results-CqsSRbZpxf"
            >
              <p>
                This will export the raw, verbatim comments from respondents
                about each brand (which sit behind the bubbles). This is
                currently only available in .csv format.
              </p>
            </Download>
          )}
        </>
      )}
    </Layout>
  );
};

export const ImageryRoute = () => (
  <Suspense>
    <Imagery />
  </Suspense>
);
