import { Card } from "$src/components/card/card";
import { Collapsible } from "$src/components/collapsible/collapsible";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useFunnelQuestions } from "$src/hooks/useFunnelQuestions";
import { regionalize, useRegionalize } from "$src/lib/regionalization";
import { cx } from "$src/lib/utils";
import { Question } from "$src/models/Funnel";
import { useSurveyDetails } from "$src/queries/useSurveyDetails";
import { useAccount } from "$src/stores/useAccount";
import {
  type ComponentProps,
  type ReactNode,
  useEffect,
  useState,
} from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import styles from "./survey-structure.module.css";

export const QUESTION_CARD = "Why is this asked in the survey?";

export const SurveyStructure = ({ className }: ComponentProps<"div">) => {
  const accountBrand = useAccount((s) => s.active);
  const { data, loading } = useSurveyDetails();
  const competitorsString: string | ReactNode = loading ? (
    <Skeleton />
  ) : (
    `${accountBrand?.brandName}, ${data?.selectedCompetitors
      ?.map((item) => item.name)
      .join(", ")}`
  );
  const stages = useFunnelQuestions({});

  return (
    <SkeletonTheme baseColor="var(--color-purple-200)" width="15ch">
      <div className={cx(styles.container, className)}>
        <h2 className={styles["header"]}>Survey Structure</h2>
        <InnerSection header="1. Demographics" metric="Demographics">
          <div className={styles["question"]}>
            <ul className={styles["question-ul"]}>
              {loading ? (
                <div data-testid="skeleton-demographics">
                  <Skeleton width="25ch" count={5} inline={false} />
                </div>
              ) : (
                data?.demographics?.map((question: string) => (
                  <li
                    key={question}
                    data-testid="survey-structure-demographics"
                  >
                    {question}
                  </li>
                ))
              )}
            </ul>
          </div>
          <Card heading={QUESTION_CARD}>
            To understand how different demographics contribute to your brand
            health.
          </Card>
        </InnerSection>
        <InnerSection header="2. Qualifier" metric="Qualifier">
          <div className={styles["question"]}>
            {data?.qualifyingQuestion?.questionText}
            <ul className={styles["question-ul"]}>
              {loading ? (
                <div data-testid="skeleton-qualifier">
                  <Skeleton width="25ch" count={5} inline={false} />
                </div>
              ) : (
                data?.qualifyingQuestion?.options &&
                data?.qualifyingQuestion.options?.map((option: any) => (
                  <li key={option.id}>
                    {option.optionText}{" "}
                    {option.qualifyingOption &&
                      "(Qualified — went on to complete the survey)"}
                  </li>
                ))
              )}
            </ul>
          </div>
          <Card heading={QUESTION_CARD}>
            To make sure those completing the survey are relevant to your
            category. Only respondents who qualify will go on to complete the
            rest of the survey.
          </Card>
        </InnerSection>
        <InnerSection
          header="3. Brand Funnel Metrics"
          metric="Brand Funnel Metrics"
        >
          <div className={styles["question"]}>
            <ul className={styles["question-ul"]}>
              {loading ? (
                <div data-testid="skeleton-funnel-metrics">
                  <Skeleton width="25ch" count={5} inline={false} />
                </div>
              ) : (
                data?.funnelQuestion
                  ?.filter((question) =>
                    stages.includes(question?.questionType as any),
                  )
                  .map((question) => (
                    <li key={question?.questionType}>
                      <strong>
                        {(Question as any)[question?.questionType as any]}:
                      </strong>
                      &nbsp;
                      {question?.questionText}
                      <span className={styles["question-guideline"]}>
                        {(() => {
                          if (
                            question?.questionType === "UNPROMPTED_AWARENESS"
                          ) {
                            return "Multiple free-text fields are shown, allowing the respondent to list the brands that come to mind.";
                          }
                          return `Please select ${
                            question?.questionType === "PREFERENCE"
                              ? "only one"
                              : "all that apply"
                          }: ${competitorsString}`;
                        })()}
                      </span>
                    </li>
                  ))
              )}
            </ul>
          </div>
          <Card heading={QUESTION_CARD}>
            To understand how your brand is performing throughout the funnel.
          </Card>
        </InnerSection>
        <InnerSection header="4. Imagery" metric="Imagery">
          <div className={styles["question"]}>
            <p>
              These questions are only shown to respondents that are aware of a
              brand.
            </p>
            <p>When thinking about {accountBrand?.category.name}</p>
            <ul className={styles["question-ul"]}>
              {loading ? (
                <div data-testid="skeleton-imagery">
                  <Skeleton width="25ch" count={5} inline={false} />
                </div>
              ) : (
                <>
                  <li>
                    What comes to mind when you think about{" "}
                    {accountBrand?.brandName}?
                    <span className={styles["question-guideline"]}>
                      Open text field
                    </span>
                  </li>
                  {data?.selectedCompetitors?.map((competitor) => (
                    <li key={competitor.id}>
                      What comes to mind when you think about {competitor.name}?
                      <span className={styles["question-guideline"]}>
                        Open text field
                      </span>
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>
          <Card heading={QUESTION_CARD}>
            {`To get a qualitative assessment of how your brand is perceived. These are ${useRegionalize(
              "unprompted",
            )}.`}
          </Card>
        </InnerSection>
        {accountBrand?.featuresConfig.statementsEnabled && (
          <InnerSection header="5. Statements" metric="Statements">
            <div className={styles["question"]}>
              <p>
                These questions are only shown to respondents that are aware of
                a brand.
              </p>
              <span>
                Do you think{" "}
                {loading ? <Skeleton width="5ch" /> : accountBrand?.brandName}:
              </span>
              <ul className={styles["question-ul"]}>
                {loading ? (
                  <div data-testid="skeleton-statements">
                    <Skeleton width="25ch" count={5} inline={false} />
                  </div>
                ) : (
                  data?.statements?.map((statement) => (
                    <li key={statement}>{statement}</li>
                  ))
                )}
              </ul>
              <span className={styles["question-guideline"]}>
                (Repeated for all competitors).
              </span>
            </div>
            <Card heading={QUESTION_CARD}>
              {`These are ${regionalize(
                "prompted",
                accountBrand.geography.name,
              )} brand statements that allow you to assess how your brand is performing against given associations.`}
            </Card>
          </InnerSection>
        )}
      </div>
    </SkeletonTheme>
  );
};

export const InnerSection = ({
  header,
  metric,
  ...props
}: { header: string; metric: string } & ComponentProps<"div">) => {
  const [open, setOpen] = useState(false);
  const analytics = useAnalytics();

  /* v8 ignore next */
  // Not tested as it just calls Segment if open
  useEffect(() => {
    open &&
      analytics?.track(AnalyticsEvents.ExpandAccordion, {
        view: "Survey Details",
        metadata: {
          metric,
        },
      });
  }, [open, analytics, metric]);

  return (
    <div className={styles["inner-section"]}>
      <Collapsible
        color="purple"
        trigger={<h3 className={styles["subheader"]}>{header}</h3>}
        onChange={setOpen}
      >
        <div>{props.children}</div>
      </Collapsible>
    </div>
  );
};
