import { Badge } from "$src/components/badge/badge";
import { Collapsible } from "$src/components/collapsible/collapsible";
import { Tooltip } from "$src/components/tooltip/tooltip";
import { cx, percentageFormatter, roundNumber } from "$src/lib/utils";
import { useFunnel } from "$src/queries/funnel";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { type ComponentProps, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import styles from "./category-penetration.module.css";
import { PenetrationChart } from "./penetration-chart/penetration-chart";

/**
 * @component
 * Category penetration card
 */
export const CategoryPenetration = ({
  className,
  ...props
}: ComponentProps<"div">) => {
  const { hasNoPopulationData } = useFilters((s) => s.filters);
  const { data, loading } = useFunnel();
  const [expanded, setExpanded] = useState(false);
  const account = useAccount((s) => s.active);
  const geography = useMemo(
    () =>
      account &&
      account.trackers.length > 0 &&
      account.trackerTopLevel === "REGIONAL"
        ? account.trackers.map(({ stateName }) => stateName).join(", ")
        : account?.geography.name,
    [account],
  );

  return (
    <div className={cx(className, styles.wrapper)} {...props}>
      {data?.quality !== "INSUFFICIENT" && (
        <>
          <Collapsible
            color="green"
            className={cx(styles.info, expanded && styles.open)}
            onChange={setExpanded}
            disabled={loading}
            trigger={
              <div>
                <h2 className={styles.heading}>
                  {loading ? (
                    <Skeleton width="15ch" baseColor="var(--color-green-200)" />
                  ) : (
                    data?.category?.name
                  )}
                </h2>
                <div className={styles.subheading}>
                  {loading ? (
                    <Skeleton width="15ch" baseColor="var(--color-green-200)" />
                  ) : (
                    <span data-testid="category-penetration-geography">
                      {geography}
                    </span>
                  )}
                </div>
              </div>
            }
          >
            <Collapsible.Card
              className={styles["info-question"]}
              heading="How is your category defined in the survey?"
            >
              {data?.category?.question?.questionText}
              {data?.category?.question?.options && (
                <ul>
                  {data?.category?.question.options.map((option) => (
                    <li key={option.optionText}>
                      {option.qualifyingOption ? (
                        <>
                          <strong>{option.optionText}</strong> (Qualifying)
                        </>
                      ) : (
                        option.optionText
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </Collapsible.Card>
            <h3 className={styles["sample-heading"]}>
              Total sample size ={" "}
              {Number(
                data?.category?.sampleIncidence.qualified,
              ).toLocaleString()}
            </h3>
            <p>
              We’ve approached{" "}
              {Number(data?.category?.sampleIncidence.total).toLocaleString()}{" "}
              people and{" "}
              {Number(
                data?.category?.sampleIncidence.qualified,
              ).toLocaleString()}{" "}
              qualified to participate in {data?.category?.name} research since
              we began tracking the category.
            </p>
          </Collapsible>
          {hasNoPopulationData && (
            <div className={styles.callout}>
              <span className={styles.title}>Why is some data missing?</span>
              <br />
              <span>
                &apos;People in your category&apos; and &apos;Total adults in{" "}
                {geography}&apos; can only be calculated with Age, Gender or
                Region filters
              </span>
            </div>
          )}
          <div className={styles.penetration}>
            <div className={cx(styles.badge)}>
              <span className={styles["badge-label"]}>
                People in your category
              </span>
              {hasNoPopulationData ? (
                <Badge className={styles["no-data-badge"]}>-</Badge>
              ) : (
                <Tooltip
                  disabled={loading}
                  tip={
                    <span>
                      {percentageFormatter.format(
                        data?.category?.percentage ?? 0,
                      )}{" "}
                      (
                      <span>
                        {roundNumber(data?.category?.categorySize ?? 0)}
                      </span>
                      ) of the total population falls into the{" "}
                      {data?.category?.name} category
                    </span>
                  }
                >
                  <Badge className={styles["category-badge"]}>
                    {loading
                      ? null
                      : roundNumber(data?.category?.categorySize ?? 0)}
                  </Badge>
                </Tooltip>
              )}
            </div>

            <PenetrationChart
              className={styles.section}
              label="Category penetration"
              title={`${percentageFormatter.format(
                data?.category?.percentage ?? 0,
              )}
                  of the total population`}
              tip={<span>fall into the {data?.category?.name} category.</span>}
            />
            <div
              className={cx(
                styles.badge,
                styles.section,
                hasNoPopulationData ? styles.disabled : "",
              )}
            >
              <span className={styles["badge-label"]}>
                Total adults in {geography}
              </span>
              {hasNoPopulationData ? (
                <Badge className={styles["no-data-badge"]}>-</Badge>
              ) : (
                <Tooltip
                  disabled={loading}
                  tip={
                    <span>
                      Based on recent population estimates of{" "}
                      <strong>
                        {roundNumber(data?.category?.totalPopulation ?? 0)}
                      </strong>{" "}
                      adults in {geography}
                    </span>
                  }
                >
                  <Badge className={styles["population-badge"]}>
                    {loading
                      ? null
                      : roundNumber(data?.category?.totalPopulation ?? 0)}
                  </Badge>
                </Tooltip>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
