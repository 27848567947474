import { AccountSelect } from "$src/components/account-select/account-select";
import { Button } from "$src/components/button/button";
import {
  ControlledDropdown,
  DropdownItem,
} from "$src/components/dropdown/dropdown";
import { IconButton } from "$src/components/icon-button/icon-button";
import { Input } from "$src/components/input/input";
import { Modal } from "$src/components/modal/modal";
import { HoverContext } from "$src/hooks/useHover";
import { api } from "$src/lib/api";
import { cx } from "$src/lib/utils";
import { UserStatus } from "$src/models/User";
import { useMutateSettings } from "$src/queries/useSettings";
import { useToasts } from "$src/stores/useToast";
import mixins from "$src/styles/mixins.module.css";
import { sentenceCase, snakeCase } from "change-case";
import { Edit, Mail, MailCheck, Trash2 } from "lucide-react";
import { useState } from "react";

import type { BFFOutput } from "@tracksuit/bff/trpc";

import { Column } from "../../column/column";
import styles from "./user-row.module.css";

type User = NonNullable<BFFOutput["settings"]["listUsers"]>[0];

export type UserRowProps = {
  user: User;
  onEdit?: (editing: boolean) => void;
  editing?: boolean;
};

const ROLES = [
  { role: "user", description: "View, interact, & export" },
  {
    role: "admin",
    description: "Manage account level settings and users",
  },
];

/**
 * @component
 * Row of users for admin view
 */
export const UserRow = ({ user, onEdit, editing }: UserRowProps) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [hovering, setHovering] = useState<boolean>(false);
  const [invited, setInvited] = useState(false);
  const [data, setData] = useState(user);
  const {
    addUser,
    updateUser,
    deleteUser: deleteUserMutation,
  } = useMutateSettings();
  const [roleOpen, setRoleOpen] = useState(false);
  const [accountsOpen, setAccountsOpen] = useState(false);
  const toast = useToasts((s) => s.add);

  const close = () => {
    setData(user);
    onEdit?.(false);
  };

  // v8 ignore next --@preserve
  const sendInvite = async () => {
    try {
      await api.settings.inviteUser.mutate(user.userId);
      setInvited(true);
      setTimeout(() => setInvited(false), 3000);
      close();
    } catch {
      toast("error", {
        id: "invite-fail",
        title: "Failed to send invite",
        message: "Something went wrong, please try again or contact our team",
      });
    }
  };

  // v8 ignore next --@preserve
  const deleteUser = async () => {
    deleteUserMutation.mutate(user.userId);
    setDeleteOpen(false);
  };

  // v8 ignore next --@preserve
  const save = async () => {
    if (data.role !== "super_admin" && !data.accounts.length) {
      toast("error", {
        id: "user-dashboards-error",
        title: "User must have access to at lesat 1 dashboard",
      });
      return;
    }

    if (user.userId) {
      updateUser.mutate({
        id: user.userId,
        email: data.email,
        role: data.role,
        accountIds: data.accounts.map(({ accountId }) => accountId) ?? [],
      });
    } else {
      addUser.mutate({
        email: data.email,
        role: data.role,
        accountIds: data.accounts.map(({ accountId }) => accountId) ?? [],
      });
    }

    onEdit?.(false);
  };

  return (
    <HoverContext.Provider value={{ hovering, setHovering }}>
      <Column active={editing} first>
        {editing ? (
          <Input
            className={styles.input}
            label="Email"
            type="email"
            name="email"
            value={data.email}
            onChange={({ target }) =>
              setData((s) => ({ ...s, email: target.value }))
            }
          />
        ) : (
          <span className={styles.clipped} data-testid="email">
            {user.email}
          </span>
        )}
      </Column>

      <Column active={editing}>
        {editing ? (
          <ControlledDropdown
            className={styles.dropdown}
            open={roleOpen}
            theme="select"
            label="Role"
            selected={sentenceCase(data.role)}
            onChange={setRoleOpen as any}
            data-testid="role-dropdown"
          >
            {ROLES.map(({ role, description }) => (
              <DropdownItem
                id="role-dropdown"
                onClick={() => {
                  setRoleOpen(false);
                  setData((s) => ({ ...s, role: role as any }));
                }}
                key={role}
                data-testid={`role-dropdown-${snakeCase(role)}`}
              >
                {sentenceCase(role)}
                <span className={styles["role-description"]}>
                  {description}
                </span>
              </DropdownItem>
            ))}
          </ControlledDropdown>
        ) : (
          <span className={styles.role}>{sentenceCase(user.role)}</span>
        )}
      </Column>
      <Column active={editing}>
        {editing ? (
          <AccountSelect
            open={accountsOpen}
            openChange={setAccountsOpen as any}
            multiselect
            selected={data.accounts}
            onChange={(accounts) => setData((d) => ({ ...d, accounts } as any))}
            disabled={data.role === "super_admin"}
          />
        ) : (
          <span className={mixins["clamped-2"]}>
            {user.accounts.map((acc) => acc.accountName).join(", ")}
          </span>
        )}
      </Column>
      {editing ? (
        <Column active={editing} span={2} last className={styles.actions}>
          <Button type="button" theme="ghost" label="Cancel" onClick={close} />
          <Button onClick={save} type="submit" label={"Save"} />
        </Column>
      ) : (
        <>
          <Column active={editing}>
            <div className={styles["status"]}>
              <span
                className={cx(
                  styles["status-circle"],
                  user.status === "FORCE_CHANGE_PASSWORD" && styles.pending,
                )}
              />
              {UserStatus[user.status as keyof typeof UserStatus]}
            </div>
          </Column>
          <Column active={editing} className={styles.actions} last>
            {invited ? (
              <div className={styles["invite-sent"]}>
                <MailCheck
                  data-testid="mail-check-icon"
                  className={styles.icon}
                />
                <span>Invite Sent</span>
              </div>
            ) : (
              <>
                {user.status === "FORCE_CHANGE_PASSWORD" && (
                  <IconButton
                    tip="Resend email invite"
                    icon={Mail}
                    hidden={!hovering}
                    onClick={sendInvite}
                    data-testid="mail-icon"
                  />
                )}
                <IconButton
                  icon={Edit}
                  tip="Edit user details"
                  hidden={!hovering}
                  onClick={() => onEdit?.(true)}
                  data-testid="edit-icon"
                />
                <IconButton
                  tip="Delete user"
                  icon={Trash2}
                  hidden={!hovering}
                  onClick={() => setDeleteOpen(true)}
                  data-testid="delete-icon"
                />
              </>
            )}
          </Column>
        </>
      )}

      <Modal
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        heading="You are about to delete the following user"
      >
        <p>{user.email}</p>
        <div className={styles.actions}>
          <Button
            data-testid="close-modal-button"
            theme="ghost"
            label="Cancel"
            onClick={() => setDeleteOpen(false)}
          />
          <Button
            data-testid="delete-confirmation-button"
            label="Delete this user"
            onClick={deleteUser}
          />
        </div>
      </Modal>
    </HoverContext.Provider>
  );
};
