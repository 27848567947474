import { Button } from "$src/components/button/button";
import {
  DropdownItem,
  DropdownSection,
} from "$src/components/dropdown/dropdown";
import { Modal } from "$src/components/modal/modal";
import { useState } from "react";

import styles from "./disabled-explainer.module.css";

/**
 * Disabled explanation modal
 */
export const DisabledExplainer = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <DropdownSection>
        <DropdownItem id="date-disabled-text">
          <span
            className={styles["disabled-info"]}
            onClick={() => setOpen(true)}
          >
            When will these options unlock?
          </span>
        </DropdownItem>
      </DropdownSection>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        heading="🤔 When will these options unlock?"
      >
        <div className={styles["disabled-content"]}>
          <p>
            📅 Tracksuit gets better the longer you’re tracking with us.
            Additional date options will become available after a period of
            time. Check back at a later date.
          </p>
        </div>
        <div className={styles["disabled-action"]}>
          <Button label="OK, got it!" onClick={() => setOpen(false)} />
        </div>
      </Modal>
    </>
  );
};
