import { cx } from "$src/lib/utils";
import type { ComponentProps } from "react";

import styles from "./empty-state.module.css";

export type EmptyStateProps = {
  emoji?: string;
  heading?: string;
  text?: string;
} & ComponentProps<"div">;

export const EMPTY_STATE_CONTENT: Record<string, EmptyStateProps> = {
  sample: {
    emoji: "🤖",
    heading: `Uh oh... try changing the filters`,
    text: `The filters you have selected are too granular for us to show you meaningful data. You can make this sample larger by selecting additional demographics or extending the date range.`,
  },
  brands: {
    emoji: "🧳",
    heading: "Your data has gone on a little vacation",
    text: "Select one or more brands in the filter bar to view this page",
  },
  imagery: {
    emoji: "🥸",
    heading:
      "Awesome, fabulous, stellar... well, that’s what we at Tracksuit think of you.",
    text: "We are waiting on enough data to populate this for you. Come back soon to check out how you are perceived.",
  },
};

/**
 * @component
 * Preconfigured empty state screens
 */
export const EmptyState = ({
  heading,
  emoji,
  text,
  children,
  className,
  ...props
}: EmptyStateProps) => {
  return (
    <div
      className={cx(styles.wrapper, className)}
      {...props}
      data-testid="empty-state"
    >
      <span className={styles.emoji}>{emoji}</span>
      <h1 className={styles.heading}>{heading}</h1>
      <p className={styles.text}>{text}</p>
      {children && <div className={styles["children-wrapper"]}>{children}</div>}
    </div>
  );
};
