import type { DataRepresentation } from "@tracksuit/frontend/schemas";

export const FUNNEL_QUESTIONS = [
  "UNPROMPTED_AWARENESS",
  "PROMPTED_AWARENESS",
  "CONSIDERATION",
  "INVESTIGATION",
  "USAGE",
  "PREFERENCE",
];

/**
 * Minimal replacement for classnames and clsx
 */
export function cx(...args: (string | boolean | undefined | null)[]) {
  return args.filter(Boolean).join(" ");
}

/**
 * Format percentages
 * Not tested since it's just a configured Intl instance
 */
/* v8 ignore next */
export const percentageFormatter = Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
});

/**
 * Round numbers to textual representations
 */
export function roundNumber(number: number, isApprox?: boolean) {
  if (Math.abs(number) < 1000) {
    return (isApprox ? "~" : "") + String(Math.round(number));
  }

  return (
    (isApprox ? "~" : "") +
    (Math.abs(number) < 1000000
      ? `${parseFloat((number / 1000).toFixed(0))}K`
      : `${parseFloat((number / 1000000).toFixed(1))}M`)
  );
}

/**
 * Formats based on data representation
 */
export function formatNumberRepresentation(
  value: number,
  format: DataRepresentation,
) {
  return format === "percentage"
    ? percentageFormatter.format(value)
    : roundNumber(value);
}

/**
 * Sort by question type
 */
export function sortQuestionTypes(a: any, b: any) {
  return (
    FUNNEL_QUESTIONS.indexOf(a.questionType) -
    FUNNEL_QUESTIONS.indexOf(b.questionType)
  );
}

/**
 * Check for external URLs
 */
export function isExternalURL(url: string) {
  return url.startsWith("http");
}

/**
 * Utility to add all items in an array to a Set
 */
export function addAllToSet<T>(array: T[], set: Set<T>): Set<T> {
  array.forEach((item) => set.add(item));
  return set;
}
