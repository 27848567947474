import { PopoutArrow, usePopout } from "$src/hooks/usePopout";
import { cx } from "$src/lib/utils";
import { format } from "date-fns";
import { useRef } from "react";
import {
  type DateRange,
  DayPicker,
  type DayPickerProps,
} from "react-day-picker";
import "react-day-picker/dist/style.css";

import { toDate } from "@tracksuit/frontend/utils";

import { FloatingLabel } from "../floating-label/floating-label";
import styles from "./date-picker.module.css";

export type DatePickerProps = {
  /** Start date */
  startDate?: Date;
  /** End date */
  endDate?: Date;
  /** Callback for date changes */
  onChange({ start, end }: { start?: Date; end?: Date }): void;
  /** Whether the datepicker is a range */
  range?: boolean;
} & DayPickerProps;

/**
 * @component
 * Visual date picker
 */
export function DatePicker({
  range,
  startDate,
  endDate,
  onChange,
  className,
  // eslint-disable-next-line
  selected,
  // eslint-disable-next-line
  mode,
  ...props
}: DatePickerProps) {
  const arrowRef = useRef(null) as any;
  const {
    context,
    refs,
    getReferenceProps,
    getFloatingProps,
    floatingStyles,
    open,
  } = usePopout({
    placement: "bottom",
    arrowRef,
    interactions: { click: true, hover: false },
  });

  return (
    <div
      className={cx(className, styles.datepicker, !range && styles.single)}
      ref={refs.setReference as any}
      {...getReferenceProps()}
      data-testid="datepicker"
    >
      <FloatingLabel
        className={styles.input}
        label="Start date"
        value={startDate ? format(toDate(startDate), "dd/MM/yyyy") : undefined}
      />
      {range && (
        <FloatingLabel
          className={styles.input}
          label="End date"
          value={endDate ? format(toDate(endDate), "dd/MM/yyyy") : undefined}
        />
      )}
      {open && (
        <div
          className={styles.popout}
          style={floatingStyles}
          ref={refs.setFloating}
          {...getFloatingProps()}
        >
          <DayPicker
            mode={range ? "range" : ("single" as any)}
            selected={range ? { from: startDate, to: endDate } : startDate}
            onSelect={
              /* v8 ignore next: 3rd party */
              range
                ? (dates: DateRange | undefined) =>
                    onChange({ start: dates?.from, end: dates?.to })
                : (date: any) => onChange({ start: date })
            }
            {...props}
            data-testid="daypicker"
          />
          <PopoutArrow ref={arrowRef} context={context} />
        </div>
      )}
    </div>
  );
}
