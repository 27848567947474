import { cx } from "$src/lib/utils";
import type { ComponentProps } from "react";

import styles from "./desktop-only.module.css";

/**
 * @component
 * Desktop only warning splash page
 */
export function DesktopOnly({ className, ...props }: ComponentProps<"div">) {
  return (
    <div
      className={cx(styles.wrapper, className)}
      {...props}
      data-testid="desktop-only"
    >
      <span className={styles.emoji}>😅</span>
      <h1 className={styles.heading}>
        We’re not broken we just prefer things bigger
      </h1>
      <p className={styles.text}>
        As much as we’d love for you to see this, you’ll have a much better
        experience when you view this on a bigger device
      </p>
    </div>
  );
}
