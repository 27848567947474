import { raygun } from "$src/lib/raygun";
import { useEffect, useState } from "react";

import { Header } from "../header/header";
import styles from "./error.module.css";

type HeadingType = {
  emoji: string;
  text: string;
};

export const HEADINGS: HeadingType[] = [
  { emoji: "🥲", text: "Some days you just can’t make it off the start line" },
  { emoji: "😵", text: "Computer says no" },
  { emoji: "❤️‍🩹", text: "Even the best athletes have a bad day" },
  { emoji: "😥", text: "Pain is temporary, brand is forever" },
  { emoji: "🏃‍♀️", text: "The Track-team is out of action right now" },
  { emoji: "😱", text: "Your brand is so awesome it broke the platform!" },
];

/**
 * @component
 * Error view for error boundaries in the router
 */
export function ErrorPage({ error }: { error?: any }) {
  const [content, setContent] = useState<{
    heading?: HeadingType;
  }>({});

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * HEADINGS.length);
    setContent({
      heading: HEADINGS[randomIndex],
    });
  }, []);

  useEffect(() => {
    if (!error) {
      return;
    }
    /* v8 ignore next */
    raygun?.("send", error);
    /* v8 ignore next */
    console.error(error);
  }, [error]);

  return (
    <>
      <Header />
      <div data-testid="error-page" className={styles.container}>
        <div className={styles.emoji}>{content.heading?.emoji}</div>
        <h1 className={styles.heading} data-testid="heading">
          {content.heading?.text}
        </h1>
        <p className={styles.message}>Something went wrong</p>
        <p className={styles.subtext}>
          <a href="/funnel">Go back to your dashboard</a> or reach out to our
          awesome <a href="https://help.gotracksuit.com/">Customer Team here</a>
          .
        </p>
      </div>
    </>
  );
}
