import styles from "./thanks-for-voting.module.css";

export function ThanksForVotingRoute() {
  return (
    <div className={styles.layout}>
      <div className={styles.inner}>
        <span className={styles.emoji}>🎉</span>
        <h1 className={styles.heading}>Your vote is in!</h1>
        <p>It’s safe to close this tab now</p>
      </div>
    </div>
  );
}
