/* v8 ignore start */
// These are just configured tooltips, covered by Tooltip unit tests
import { percentageFormatter } from "$src/lib/utils";

import type { ConversionQuestionType } from "@tracksuit/frontend/schemas";

export const tip = (data: {
  sourceMetric: ConversionQuestionType;
  targetMetric: ConversionQuestionType;
  brandName: string;
  conversion: number;
}) => {
  let title = "";
  let tipContent = null;

  switch (data.sourceMetric) {
    case "PROMPTED_AWARENESS":
      title = "Top of Funnel Conversion";
      tipContent = (
        <span>
          Of those who are <strong>Aware</strong> of {data.brandName},
          approximately {percentageFormatter.format(data.conversion)} have been
          converted to <strong>Consider</strong> {data.brandName}.
        </span>
      );
      break;
    case "CONSIDERATION":
      switch (data.targetMetric) {
        case "INVESTIGATION":
          title = "Middle of Funnel Conversion";
          tipContent = (
            <span>
              Of those who <strong>Consider</strong> {data.brandName},
              approximately {percentageFormatter.format(data.conversion)} have
              been converted to <strong>Investigate</strong> {data.brandName}.
            </span>
          );
          break;
        case "USAGE":
          title = "Middle of Funnel Conversion";
          tipContent = (
            <span>
              Of those who <strong>Consider</strong> {data.brandName},
              approximately {percentageFormatter.format(data.conversion)} have
              been converted to <strong>Use</strong> {data.brandName}.
            </span>
          );
          break;
        case "PREFERENCE":
          title = "Bottom of Funnel Conversion";
          tipContent = (
            <span>
              Of those who <strong>Consider</strong> {data.brandName},
              approximately {percentageFormatter.format(data.conversion)} have
              been converted to <strong>Prefer</strong> {data.brandName}.
            </span>
          );
          break;
        default:
          break;
      }
      break;
    case "INVESTIGATION":
      title = "Middle of Funnel Conversion";
      tipContent = (
        <span>
          Of those who <strong>Investigate</strong> {data.brandName},
          approximately {percentageFormatter.format(data.conversion)} have been
          converted to <strong>Use</strong> {data.brandName}.
        </span>
      );
      break;
    case "USAGE":
      title = "Bottom of Funnel Conversion";
      tipContent = (
        <span>
          Of those who <strong>Use</strong> {data.brandName}, approximately{" "}
          {percentageFormatter.format(data.conversion)} have been converted to{" "}
          <strong>Prefer</strong> {data.brandName}.
        </span>
      );
      break;
    case "PREFERENCE":
  }
  return { title, tip: tipContent };
};
/* v8 ignore end */
