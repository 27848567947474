import { cx, percentageFormatter } from "$src/lib/utils";
import { motion } from "framer-motion";
import { type CSSProperties, type ComponentProps } from "react";

import { Tip, Tooltip } from "../tooltip/tooltip";
import styles from "./bead-chart.module.css";

type DataPoint = {
  id: number;
  value: number;
  color: {
    default: string;
    active: string;
  };
  label: string;
};

export type BeadChartProps = {
  /** Data for the chart */
  data: DataPoint[];
  /** Which datapoint is active */
  activeDataPoints?: number[];
  /** Callback when active point changed */
  onActiveDataPointChange?: (active: number) => void;
  /** Whether to adapt value range */
  maxValue?: number;
  adaptiveRange?: boolean;
} & ComponentProps<"div">;

/**
 * @component
 * Bead chart, used for Statements
 */
export function BeadChart({
  data,
  activeDataPoints,
  onActiveDataPointChange,
  maxValue = 1,
  className,
  ...props
}: BeadChartProps) {
  return (
    <div className={cx(styles.chart, className)} {...props}>
      <div className={styles["inner-track"]}>
        {data
          .sort((a, b) => a.value - b.value)
          .map((point) => (
            <motion.div
              initial={{ left: 0 }}
              animate={{ left: `${(point.value / maxValue) * 100}%` }}
              className={cx(
                styles["bead-wrap"],
                activeDataPoints?.includes(point.id) && styles.active,
              )}
              style={
                {
                  "--bead-color": activeDataPoints?.includes(point.id)
                    ? point.color?.active
                    : point.color?.default,
                } as CSSProperties
              }
              data-testid="bead-chart-bead"
              data-active={
                activeDataPoints?.includes(point.id) ? "true" : "false"
              }
              key={point.id}
            >
              {activeDataPoints?.includes(point.id) && (
                <Tip
                  compact
                  withArrow
                  className={styles.tip}
                  tip={<BeadTip point={point} />}
                />
              )}

              <Tooltip
                style={{ zIndex: "var(--layer-3)" }}
                compact
                disabled={activeDataPoints?.includes(point.id)}
                tip={<BeadTip point={point} />}
              >
                <div
                  data-testid="bead-chart-bead-inner"
                  className={styles.bead}
                  onClick={() => onActiveDataPointChange?.(point.id)}
                />
              </Tooltip>
            </motion.div>
          ))}
      </div>
    </div>
  );
}

function BeadTip({ point }: { point: DataPoint }) {
  return (
    <div>
      <span className={styles["tip-value"]}>
        {percentageFormatter.format(point.value)}
      </span>
      <span className={styles["tip-label"]}>{point.label}</span>
    </div>
  );
}
