import { TokensView } from "$src/views/settings/lib/tokens-view/tokens-view";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { SettingsSublayout } from "../../sublayout/sublayout";

export function TokensSettingsRoute() {
  const { apiTokens } = useFlags();
  const navigate = useNavigate();

  useEffect(() => {
    if (!apiTokens) {
      navigate("/settings");
    }
  }, [apiTokens, navigate]);

  return (
    <SettingsSublayout view="tokens">
      <TokensView />
    </SettingsSublayout>
  );
}
