import { useNav } from "$src/hooks/useNav";
import { cx } from "$src/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { Menu, X } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./mobile-menu.module.css";

/**
 * @component
 * Header for mobile and tablet.
 */
export function MobileMenu() {
  const [open, setOpen] = useState(false);
  const nav = useNav();
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.style.overflow = open ? "hidden" : "";
  }, [open]);

  return (
    <div className={cx(styles.container)}>
      <div
        className={cx(styles.icon, open && styles.close)}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {open ? <X data-testid="close" /> : <Menu data-testid="hamburger" />}
      </div>
      <AnimatePresence>
        {open && (
          <motion.nav
            className={cx(
              styles["menu-container"],
              styles["expanded"],
              open && styles["stop-scroll"],
            )}
            data-testid="motion-div"
            initial={{ y: "-100%" }}
            animate={{ y: 0 }}
            exit={{ y: "-100%" }}
            transition={{ stiffness: 200, damping: 20 }}
          >
            {nav.map(({ label, path }) => (
              <div
                className={cx(
                  styles["link-container"],
                  pathname.includes(path) && styles.active,
                )}
                key={path}
              >
                <Link
                  className={cx(
                    styles.link,
                    pathname.includes(path) && styles.active,
                  )}
                  to={path}
                  onClick={() => setOpen(!open)}
                >
                  {label}
                </Link>
              </div>
            ))}
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  );
}
