import { ControlledCollapsible } from "$src/components/collapsible/collapsible";
import { cx } from "$src/lib/utils";
import { ChevronDown } from "lucide-react";
import { type ComponentProps, useState } from "react";

import { DropdownItem } from "../dropdown-item/dropdown-item";
import styles from "./dropdown-group.module.css";

export type DropdownGroupProps = {
  /** Whether the group is open */
  open: boolean;
  /** ID of the group, for highlights */
  id: string;
  /** Label for the group */
  label: string;
  /** Callback on open change */
  onChange(open: boolean): void;
} & Omit<ComponentProps<"div">, "onChange">;

export function DropdownGroup({
  open,
  id,
  label,
  onChange,
  children,
  className,
  ...props
}: DropdownGroupProps) {
  const [opened, setOpened] = useState(open);

  return (
    <DropdownItem
      id={id}
      className={cx(styles.group, className)}
      noHighlight={opened}
      {...props}
    >
      <div className={styles.trigger} onClick={() => onChange(!open)}>
        <div className={styles["trigger-content"]}>{label}</div>
        <ChevronDown
          data-testid="collapsible-icon"
          className={cx(styles.icon, open && styles.open)}
        />
      </div>
      <ControlledCollapsible onChange={setOpened} open={open}>
        {children}
      </ControlledCollapsible>
    </DropdownItem>
  );
}
