import { z } from "zod";

export const token = z.object({
  id: z.string().uuid(),
  name: z.string(),
  createdAt: z.string().datetime(),
  revokedAt: z.string().datetime().nullish(),
  expiresAt: z.string().datetime(),
});

export const accessToken = z.object({
  accessToken: z.string(),
  expiresAt: z.string(),
});
