import { CopyButton } from "$src/components/copy-button/copy-button";
import {
  ControlledDropdown,
  DropdownItem,
} from "$src/components/dropdown/dropdown";
import {
  EMPTY_STATE_CONTENT,
  EmptyState,
} from "$src/components/empty-state/empty-state";
import { PackChart } from "$src/components/pack-chart/pack-chart";
import { Tag } from "$src/components/tag/tag";
import { Tooltip } from "$src/components/tooltip/tooltip";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { cx } from "$src/lib/utils";
import type { ImageryTheme } from "$src/routes/app/routes/imagery/use-theme/useTheme";
import { useAccount } from "$src/stores/useAccount";
import { sentenceCase } from "change-case";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  type ComponentPropsWithoutRef,
  type ReactNode,
  useRef,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";

import type { BFFOutput } from "@tracksuit/bff/trpc";

import styles from "./imagery.module.css";
import { useWordVerbatimModal } from "./lib/word-verbatim/word-verbatim";

type ImageryBrand = NonNullable<BFFOutput["imagery"]["words"]>[0] & {
  isAccountBrand: boolean;
};

type ImageryWord = {
  word: string;
  weightedFrequency: number;
  common: boolean;
};

type Accent = "purple" | "pink";

type Props = {
  accent: Accent;
  brand?: ImageryBrand;
  loading: boolean;
  words?: ImageryWord[];
  brandPicker: ReactNode;
  themeToggles: ReactNode;
};

const COLORS: Record<
  Props["accent"],
  Record<"dark" | "light" | "text", string>
> = {
  purple: {
    dark: "var(--color-purple-600)",
    light: "var(--color-purple-200)",
    text: "var(--color-purple-700)",
  },
  pink: {
    dark: "var(--color-pink-600)",
    light: "var(--color-pink-200)",
    text: "var(--color-pink-600)",
  },
};

export const Imagery = ({
  accent,
  brand,
  words,
  loading,
  brandPicker,
  themeToggles,
}: Props) => {
  const [wordVerbatimDialog, openWordVerbatimDialog] = useWordVerbatimModal({
    brand,
  });
  const analytics = useAnalytics();
  const accountBrand = useAccount((s) => s.active);
  const isEmpty = words?.length === 0;

  const data: Maybe<ComponentPropsWithoutRef<typeof PackChart>["data"]> = words
    ?.sort((a, b) => b.weightedFrequency - a.weightedFrequency)
    .slice(0, 18)
    .map(({ word, weightedFrequency, common }) => ({
      label: word,
      value: weightedFrequency,
      highlighted: !common,
      tooltipLabel: `Click to view a range of raw responses for "${sentenceCase(
        word,
      )}"`,
      onClick() {
        trackOpenVerbatim({ word });
        openWordVerbatimDialog({ word });
      },
    }));

  const colors = {
    primary: {
      background: COLORS[accent].light,
      text: COLORS[accent].text,
    },
    secondary: {
      background: COLORS[accent].dark,
      text: "var(--color-off-white)",
    },
  };

  /* v8 ignore next */
  // Not tested as it just calls Segment if open
  const trackOpenVerbatim = ({ word }: { word: string }) => {
    analytics?.track(AnalyticsEvents.Verbatim, {
      type: "Brand",
      metadata: {
        brand: brand?.brandName,
        isAccountBrand:
          accountBrand?.brandCategoryGeographyId ===
          brand?.brandCategoryGeographyId,
        word: word,
      },
    });
  };

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className={cx(styles.container, styles[accent])} ref={ref}>
      <div className={styles["top-toolbar"]}>
        {loading ? <Skeleton width="10ch" /> : brandPicker}
        <CopyButton
          imageAreaElement={ref}
          className={styles["copy-button"]}
          data-testid="copy-as-image"
        />
      </div>
      {isEmpty && (
        <EmptyState
          {...EMPTY_STATE_CONTENT.imagery}
          className={styles["empty-state"]}
        />
      )}
      {!isEmpty && !loading && data && (
        <PackChart
          data-testid="imagery-bubble-chart"
          data={data}
          colors={colors}
        />
      )}
      {!isEmpty && loading && <div className={styles.placeholder} />}
      <div className={styles["theme-toggles"]}>{themeToggles}</div>
      {wordVerbatimDialog}
    </div>
  );
};

type ImageryBrandPickerProps = {
  brand?: ImageryBrand;
  brandOptions: ImageryBrand[];
  setBrand: (brand: ImageryBrand) => void;
};

Imagery.BrandPicker = function ImageryBrandPicker({
  brand,
  brandOptions,
  setBrand,
}: ImageryBrandPickerProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ControlledDropdown
      theme="pill"
      label={brand?.brandName}
      open={isOpen}
      onChange={setIsOpen}
    >
      {brandOptions.map((brandOption, index) => (
        <DropdownItem
          key={brandOption.brandCategoryGeographyId}
          id="brand-option"
          active={
            brand?.brandCategoryGeographyId ===
            brandOption.brandCategoryGeographyId
          }
          data-testid={`brand-${index}`}
          onClick={() => {
            setBrand(brandOption);
            setIsOpen(false);
          }}
        >
          {brandOption.brandName}
        </DropdownItem>
      ))}
    </ControlledDropdown>
  );
};

type ImageryBrandHeadingProps = {
  brand: ImageryBrand;
};

Imagery.BrandHeading = function ImageryBrandHeading({
  brand,
}: ImageryBrandHeadingProps) {
  return <div className={styles["brand-heading"]}>{brand.brandName}</div>;
};

type ImageryThemeTogglesProps = {
  accent: Accent;
  theme: ImageryTheme;
  toggleTheme: (theme: ImageryTheme) => void;
  oppositeBrand?: ImageryBrand;
};

Imagery.ThemeToggles = function ImageryThemeToggles({
  accent,
  theme,
  toggleTheme,
  oppositeBrand,
}: ImageryThemeTogglesProps) {
  return (
    <>
      {(["Common themes", "Unique themes"] as const).map((option) => (
        <Tooltip
          key={option}
          tip={
            option === "Common themes"
              ? `Themes that are the same as ${oppositeBrand?.brandName}`
              : `Themes that are completely different to ${oppositeBrand?.brandName}`
          }
        >
          <Tag
            color={
              option === "Common themes"
                ? COLORS[accent].dark
                : COLORS[accent].light
            }
            className={styles.tag}
            label={option}
            onClick={() => toggleTheme(option)}
            active={theme === option}
          />
        </Tooltip>
      ))}
    </>
  );
};
