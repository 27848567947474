import { Summary } from "$src/components/summary/summary";
import { api } from "$src/lib/api";
import { COMPETITOR_AVERAGE_ID, METRIC_COLORS } from "$src/lib/consts";
import { regionalize } from "$src/lib/regionalization";
import { sortQuestionTypes } from "$src/lib/utils";
import { Question } from "$src/models/Funnel";
import {
  useFunnelTimelineMetricSummary,
  useFunnelTimelineOverview,
} from "$src/queries/funnel";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { Download } from "$src/views/download/download";
import { FilterBar } from "$src/views/filter-bar/filter-bar";
import { useTimelineConfig } from "$src/views/timeline/lib/useTimelineConfig";
import { useChartMilestones } from "$src/views/timeline/lib/utils";
import { Timeline } from "$src/views/timeline/timeline";
import { sentenceCase } from "change-case";
import { useMemo, useRef, useState } from "react";

export const FunnelTimelineOverviewRoute = () => {
  const { demographics, dateRange, brandId, questionList } = useFilters(
    (s) => s.filters,
  );
  const account = useAccount((s) => s.active);
  const { availableFilters } = useAvailableFilters();
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [summaryOpen, setSummaryOpen] = useState(false);
  const timelineRef = useRef(null);
  const [selectedQuestions, setSelectedQuestions] = useState([
    "PROMPTED_AWARENESS",
  ]);
  const brand = useMemo(
    () => availableFilters?.brands?.find(({ id }) => id === brandId),
    [brandId, availableFilters.brands],
  );
  const { loading, data } = useFunnelTimelineOverview();
  const timelineConfig = useTimelineConfig((s) => s.config);
  const {
    data: summary,
    loading: summaryLoading,
    error: summaryError,
  } = useFunnelTimelineMetricSummary({
    enabled: summaryOpen,
  });
  const chartData = useMemo(
    () =>
      data?.funnel.sort(sortQuestionTypes).map((question) => ({
        label: sentenceCase(
          regionalize(
            Question[question.questionType] ?? "",
            account?.geography.name,
          ),
        ),
        id: question.questionType,
        color:
          METRIC_COLORS[question.questionType] ?? "var(--color-purple-500)",
        metrics: question.metrics,
        difference: question.difference,
      })),
    [data],
  );
  const milestones = useChartMilestones([brandId as number]);

  const getExportUrl = async () => {
    if (!account) {
      throw new Error("Account not defined");
    }

    const res = await api.exports.timelineFunnel.query({
      accountBrandId: account.accountBrandId,
      demographicFilter: demographics.map(({ filter }) => filter),
      demographicFilterIds: demographics.map(({ id }) => id),
      waveStartDate: dateRange.start ?? "",
      waveEndDate: dateRange.end ?? "",
      format: "XLSX",
      brandId: Number(brandId),
      hiddenMetrics: [],
      includeCategoryPenetration: timelineConfig.categoryPenetration,
    });

    return res?.exportUrl;
  };

  return (
    <>
      <FilterBar
        filters={{
          demographic: true,
          date: { type: "range" },
          brand: { includeCompetitorAverage: true },
          question: {
            multiselect: true,
            config: {
              category: false,
              unprompted: true,
              investigation: true,
            },
          },
        }}
        actions={{
          download: () => setDownloadOpen(true),
          summarise:
            brandId === COMPETITOR_AVERAGE_ID
              ? undefined
              : () => setSummaryOpen((s) => !s),
          copyAsImage: timelineRef,
        }}
        actionsDisabled={
          !data?.funnel.some((question) => question.metrics.length > 2)
        }
        quality={data?.quality}
      />
      <Timeline
        title={`${brand?.name ? `${brand.name}: ` : ""}Funnel over time`}
        data={chartData}
        quality={data?.quality}
        loading={loading}
        milestones={milestones}
        ref={timelineRef}
        activeDatasets={selectedQuestions}
        dataUnselected={
          !questionList.length && !timelineConfig.categoryPenetration
        }
        onActiveDatasetsChange={setSelectedQuestions}
        categoryPenetration={data?.categoryPenetration}
      />
      <Download
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
        fetcher={getExportUrl}
        heading="Downloading the timeline response"
        label="Timeline"
        format="XLSX"
      >
        This is currently only available in Excel format.
      </Download>
      <Summary
        open={summaryOpen}
        onClose={() => setSummaryOpen(false)}
        summaries={[
          {
            content: summary?.summary,
            loading: summaryLoading,
            error: summaryError,
          },
        ]}
      />
    </>
  );
};
