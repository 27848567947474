import { HoverContext } from "$src/hooks/useHover";
import { cx } from "$src/lib/utils";
import { type ComponentProps, useContext } from "react";

import styles from "./column.module.css";

type ColumnProps = {
  // Whether this column should have styles for first in the row
  first?: boolean;
  // Whether this column should have styles for last in the row
  last?: boolean;
  // Optionally span multiple columns
  span?: number;
  // Whether row is hoverable
  hoverable?: boolean;
  // Whether row is active
  active?: boolean;
  // Whether column represents table header
  header?: boolean;
} & ComponentProps<"div">;

export function Column({
  span,
  first,
  last,
  hoverable = true,
  active = false,
  header = false,
  children,
  className,
  ...props
}: ColumnProps) {
  const { hovering, setHovering } = useContext(HoverContext);

  return (
    <div
      style={span ? { gridColumn: `span ${span}` } : {}}
      onMouseEnter={() => hoverable && setHovering(true)}
      onMouseLeave={() => hoverable && setHovering(false)}
      className={cx(
        styles.column,
        header && styles.header,
        hoverable && styles.hoverable,
        hovering && styles.hovering,
        active && styles.active,
        first && styles.first,
        last && styles.last,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
