import { Button } from "$src/components/button/button";
import { Modal } from "$src/components/modal/modal";
import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import mixins from "$src/styles/mixins.module.css";
import { sentenceCase } from "change-case";
import { type ComponentPropsWithRef, Suspense, useState } from "react";
import Skeleton from "react-loading-skeleton";

import styles from "./word-verbatim.module.css";

type WordVerbatimProps = {
  isOpen: boolean;
  brand: any;
  word: string;
  onClose: () => void;
} & ComponentPropsWithRef<"div">;

/**
 * @component
 * Imagery word verbatim in modal
 */
function WordVerbatim({
  isOpen,
  brand,
  word,
  onClose,
  ...props
}: WordVerbatimProps) {
  const accountBrand = useAccount((s) => s.active);

  if (!accountBrand) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      heading={`A selection of raw responses for: "${sentenceCase(word)}"`}
    >
      <div className={styles.container} {...props}>
        <div className={mixins.scrollable}>
          <span className={styles.question}>
            Thinking about {accountBrand.category.name}, what comes to mind when
            you think about {brand.brandName}?
          </span>
          <p className={styles.information}>
            These are a selection of raw responses we have received related to:{" "}
            {sentenceCase(word)}. Download the raw responses to see the full
            list.
          </p>
          <Suspense fallback={<Loading />}>
            <WordVerbatimResponses
              accountBrandId={accountBrand.accountBrandId}
              brand={brand}
              word={word}
            />
          </Suspense>
        </div>
        <div className={styles.actions}>
          <Button label="Close" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
}

function WordVerbatimResponses({
  accountBrandId,
  brand,
  word,
}: {
  accountBrandId: number;
  brand: any;
  word: string;
}) {
  // `data` is guaranteed to be defined when returned from a suspense query
  // but the type definitions in tRPC mistakenly thinks that it's nullable.
  const [data] = apiQueryClient.imagery.verbatims.useSuspenseQuery({
    accountBrandId,
    brandCategoryGeographyId: brand.brandCategoryGeographyId,
    word,
  });

  return (
    <div className={styles.response}>
      {/* TODO: we need an unique identifier for responses from the backend instead of array indices */}
      {data?.filter(Boolean).map(({ verbatim, respondent }, i) => (
        <div className={styles["response-child"]} key={i}>
          <div className={styles.verbatim}>{verbatim}</div>
          <div className={styles.respondent}>
            {respondent.surveyDate}&nbsp;|&nbsp;
            {respondent.age}&nbsp;|&nbsp;{respondent.gender}
            &nbsp;|&nbsp;{respondent.location}&nbsp;|&nbsp;
            {respondent.income}
          </div>
        </div>
      ))}
    </div>
  );
}

const Loading = () => (
  <div className={styles.response}>
    {[...Array(4)].map((i) => (
      <div className={styles["response-child"]} key={i}>
        <div className={styles.verbatim}>
          <Skeleton width="15ch" inline={true} />
        </div>
        <div className={styles.respondent}>
          <Skeleton width="5ch" inline={true} />
          &nbsp;|&nbsp;
          <Skeleton width="5ch" inline={true} />
          &nbsp;|&nbsp;
          <Skeleton width="5ch" inline={true} />
          &nbsp;|&nbsp;
          <Skeleton width="5ch" inline={true} />
          &nbsp;|&nbsp;
          <Skeleton width="5ch" inline={true} />
        </div>
      </div>
    ))}
  </div>
);

export function useWordVerbatimModal({ brand }: { brand?: any }) {
  const [isOpen, setIsOpen] = useState(false);
  const [word, setWord] = useState<string>();

  const dialog = brand !== undefined && word !== undefined && (
    <WordVerbatim
      isOpen={isOpen}
      brand={brand}
      word={word}
      onClose={() => setIsOpen(false)}
    />
  );

  const openDialog = ({ word }: { word: string }) => {
    setWord(word);
    setIsOpen(true);
  };

  return [dialog, openDialog] as const;
}
