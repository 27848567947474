import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";

import { FunnelQuestionType } from "@tracksuit/frontend/schemas";

export const useFunnelTimelineCompetitorSummary = ({
  question,
  enabled,
}: {
  question?: FunnelQuestionType;
  enabled: boolean;
}) => {
  const [{ dateRange, demographics, brandIdList }, filtersReady] = useFilters(
    (s) => [s.filters, s.ready],
  );
  const accountBrand = useAccount((s) => s.active);
  const {
    data,
    isLoading: loading,
    error,
  } = apiQueryClient.timeline.getCompetitorSummary.useQuery(
    {
      accountBrandId: accountBrand!.accountBrandId,
      questionType: question as FunnelQuestionType,
      demographicFilterIds: demographics?.map(({ id }) => id) ?? [],
      waveRange: {
        start: dateRange.start!,
        end: dateRange.end!,
      },
      includedBrandIds: brandIdList,
    },
    {
      enabled: filtersReady && !!question && !!accountBrand && enabled,
    },
  );

  return {
    data,
    loading,
    error,
  };
};
