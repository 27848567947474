import { ErrorPage } from "$src/components/error/error";
import { useAmplifyAuth } from "$src/hooks/useAmplifyAuth";
import { NotFound } from "$src/views/not-found/not-found";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";

import { App } from "./app";
import { AppLayout } from "./app/layout";
import { ConversionRoute } from "./app/routes/conversion/conversion";
import { funnelRoutes } from "./app/routes/funnel/routes";
import { ImageryRoute } from "./app/routes/imagery/imagery";
import { MilestonesRoute } from "./app/routes/milestones/milestones";
import { PlaygroundRoute } from "./app/routes/playground/playground";
import { ProfileRoute } from "./app/routes/profile/profile";
import { settingsRoutes } from "./app/routes/settings/routes";
import { statementsRoutes } from "./app/routes/statements/routes";
import { SurveyDetailsRoute } from "./app/routes/surveydetails/surveydetails";
import { TLDRRoute } from "./app/routes/tldr/tldr";
import { AuthLayout } from "./auth/layout";
import { ActivateRoute } from "./auth/routes/activate";
import { ForgotPasswordRoute } from "./auth/routes/forgot-password";
import { LoginRoute } from "./auth/routes/login";
import { PublicLayout } from "./public/layout";
import { ThanksForVotingRoute } from "./public/routes/thanks-for-voting/thanks-for-voting";
import { surveyRoutes } from "./survey/routes";

const getRoutes = (flags: { [flag: string]: boolean }) => {
  return createBrowserRouter([
    {
      Component: App,
      errorElement: <RouterError />,
      children: [
        // Private Routes
        {
          Component: AuthenticatedRoute,
          children: [
            {
              Component: AppLayout,
              errorElement: <RouterError />,
              children: [
                {
                  path: "/",
                  Component: () => <Navigate to="/funnel" />,
                },
                {
                  path: "/tldr",
                  Component: TLDRRoute,
                },
                {
                  path: "funnel",
                  children: funnelRoutes,
                },
                {
                  path: "conversion",
                  Component: ConversionRoute,
                },
                {
                  path: "statements",
                  children: statementsRoutes,
                },
                {
                  path: "profile",
                  Component: ProfileRoute,
                },
                {
                  path: "imagery",
                  Component: ImageryRoute,
                },
                {
                  path: "settings",
                  children: settingsRoutes,
                },
                {
                  path: "milestones",
                  Component: MilestonesRoute,
                },
                {
                  path: "surveydetails",
                  Component: SurveyDetailsRoute,
                },
                {
                  path: "playground",
                  Component: PlaygroundRoute,
                },
                {
                  path: "*",
                  Component: NotFound,
                },
              ],
            },
          ],
        },

        // Unauthenticated Routes
        {
          Component: UnauthenticatedRoute,
          children: [
            {
              Component: AuthLayout,
              children: [
                {
                  path: "login",
                  Component: LoginRoute,
                },
                {
                  path: "activate",
                  Component: ActivateRoute,
                },
                {
                  path: "forgot-password",
                  Component: ForgotPasswordRoute,
                },
              ],
            },
          ],
        },

        // Public routes
        {
          Component: PublicLayout,
          children: [
            {
              path: "thanks-for-voting",
              Component: ThanksForVotingRoute,
            },
          ],
        },

        // Survey customer view routes
        {
          path: "survey",
          children: surveyRoutes,
        },
      ],
    },
  ]);
};

const RouterError = () => {
  const error = useRouteError();

  return <ErrorPage error={error} />;
};

const AuthenticatedRoute = () => {
  const { ready, session } = useAmplifyAuth();
  if (!ready) {
    return null;
  }
  return session ? <Outlet /> : <Navigate to="/login" replace />;
};

const UnauthenticatedRoute = () => {
  const { ready, session } = useAmplifyAuth();
  if (!ready) {
    return null;
  }
  return !session ? <Outlet /> : <Navigate to="/funnel" replace />;
};

export const AppRouter = () => {
  const flags = useFlags();
  const routes = useMemo(() => getRoutes(flags), [flags]);

  return <RouterProvider router={routes} />;
};
