/* v8 ignore start */
// This whole file is just consts, nothing to test
import { type QuestionType } from "@tracksuit/frontend/schemas";

export const DEFAULT_ERROR_MESSAGE =
  "We are currently having trouble accessing your latest data.";

export const AMPLIFY_AUTH_ERROR_CODE = "InternalErrorException";

export const ACCOUNT_SWITCH_EVENT = "accountBrandSwitch";

export const METRIC_COLORS: {
  [stage in QuestionType | "CATEGORY_PENETRATION"]?: string;
} = {
  UNPROMPTED_AWARENESS: "#EDAE49",
  PROMPTED_AWARENESS: "var(--color-purple-500)",
  CONSIDERATION: "var(--color-orange-500)",
  INVESTIGATION: "var(--color-blue-500)",
  USAGE: "var(--color-teal-500)",
  PREFERENCE: "var(--color-grape-500)",
  CATEGORY_PENETRATION: "var(--color-green-800)",
};

export const COMPETITOR_COLORS = [
  {
    primary: "var(--color-teal-500)",
    secondary: "var(--color-teal-100)",
  },
  {
    primary: "var(--color-orange-500)",
    secondary: "var(--color-orange-100)",
  },
  {
    primary: "var(--color-blue-500)",
    secondary: "var(--color-blue-100)",
  },
  {
    primary: "var(--color-yellow-500)",
    secondary: "var(--color-yellow-100)",
  },
  {
    primary: "var(--color-grape-500)",
    secondary: "var(--color-grape-100)",
  },
  {
    primary: "var(--color-coral-500)",
    secondary: "var(--color-coral-100)",
  },
  {
    primary: "var(--color-peacock-500)",
    secondary: "var(--color-peacock-100)",
  },
  {
    primary: "var(--color-fawn-500)",
    secondary: "var(--color-fawn-100)",
  },
  {
    primary: "var(--color-charcoal-500)",
    secondary: "var(--color-charcoal-100)",
  },
  {
    primary: "var(--color-sage-500)",
    secondary: "var(--color-sage-100)",
  },
  {
    primary: "var(--color-cherry-500)",
    secondary: "var(--color-cherry-100)",
  },
];

export const COMPETITOR_AVG_COLOR = "var(--color-purple-600)";
export const CATEGORY_PENETRATION_COLOR = "var(--color-green-800)";

export const COMPETITOR_AVERAGE_ID = 1;
export const CATEGORY_PENETRATION_ID = 2;

export const STAGE_EXPLANATIONS: {
  [key in QuestionType]?: {
    verb: string;
    question: string;
    explanation: string;
  };
} = {
  UNPROMPTED_AWARENESS: {
    verb: "are",
    question: "aware",
    explanation: "aware of the brand without being prompted",
  },
  PROMPTED_AWARENESS: {
    verb: "are",
    question: "aware",
    explanation: "aware of the brand when prompted",
  },
  CONSIDERATION: {
    verb: "would",
    question: "consider",
    explanation: "consider using the brand",
  },
  INVESTIGATION: {
    verb: "have",
    question: "investigated",
    explanation: "investigated the brand",
  },
  USAGE: {
    verb: "have",
    question: "used",
    explanation: "used the brand",
  },
  PREFERENCE: {
    verb: "would",
    question: "prefer",
    explanation: "prefer to use the brand most",
  },
  QUALIFYING: {
    verb: "are",
    question: "in the category",
    explanation: "in the category",
  },
};

export const AMPLIFY_MFA_INVALID_PARAM_ERROR_CODE = "InvalidParameterException";

export const AMPLIFY_MFA_ENABLE_ERROR_CODE = "EnableSoftwareTokenMFAException";

export const AMPLIFY_MFA_CODE_MISMATCH = "CodeMismatchException";
/* v8 ignore end */
