import { cx } from "$src/lib/utils";
import mixins from "$src/styles/mixins.module.css";
import type { ComponentProps } from "react";

import styles from "./card.module.css";

export type CardProps = {
  heading?: string;
} & ComponentProps<"div">;

export const Card = ({ heading, className, children, ...props }: CardProps) => {
  return (
    <div className={cx(styles.container, className)} {...props}>
      {heading && <h3 className={styles.heading}>{heading}</h3>}
      <div className={cx(styles.content, mixins.richtext)}>{children}</div>
    </div>
  );
};
