import { useAmplifyAuth } from "$src/hooks/useAmplifyAuth";
import { useAccount } from "$src/stores/useAccount";
import { LDFlagSet, useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Location, useLocation } from "react-router-dom";

const Head = ({ children }: any) => createPortal(children, document.head);

export const PlaygroundRoute = () => {
  const { session, ready } = useAmplifyAuth();
  const location: Location = useLocation();
  const [accessToken, setAccessToken] = useState("");
  const account = useAccount((s) => s.active);
  const allFlags: LDFlagSet = useFlags();

  // Extract flags that start with "playground" into a map
  const playgroundFlags = Object.keys(allFlags)
    .filter((key) => key.startsWith("insight"))
    .reduce((map: { [key: string]: any }, key: string) => {
      map[key] = allFlags[key];
      return map;
    }, {});

  useEffect(() => {
    if (ready && session) {
      const token = session.getIdToken().getJwtToken();
      setAccessToken(token);
      if (window.location.hostname === "localhost") {
        document.cookie = `sltoken=${token}; path=/;`;
      } else {
        document.cookie = `sltoken=${token}; path=/; secure;`;
      }
    }
  }, [ready, session, location]);

  if (!ready || !accessToken || !account) {
    return <div>Loading...</div>;
  }

  // Streamlit uses websockets, so the vite proxy does not work well
  const isLocalDevelopment =
    window.location.hostname === "localhost" && window.location.port === "3000";
  const basePath = isLocalDevelopment
    ? "http://localhost:8501"
    : `${window.location.protocol}//${window.location.hostname}`;
  const playgroundUrl = `${basePath}/streamlit-beta/?accountBrandId=${
    account.accountBrandId
  }${Object.keys(playgroundFlags)
    .map((flag) => `&${flag}=${playgroundFlags[flag]}`)
    .join("")}`;

  return (
    <>
      <Head>
        <link rel="preload" href={playgroundUrl} as="document" />
      </Head>
      <iframe
        src={playgroundUrl}
        style={{ width: "100%", height: "100vh", border: "none" }}
        title="Tracksuit Training Ground"
      ></iframe>
    </>
  );
};
