import { Button } from "$src/components/button/button";
import { Layout } from "$src/components/layout/layout";
import { api } from "$src/lib/api";
import { cx } from "$src/lib/utils";
import { useAccount } from "$src/stores/useAccount";
import mixins from "$src/styles/mixins.module.css";
import { Download } from "$src/views/download/download";
import { SurveyStructure } from "$src/views/surveydetails/survey-structure/survey-structure";
import { TrackingDetails } from "$src/views/surveydetails/tracking-details/tracking-details";
import { Download as DownloadIcon } from "lucide-react";
import { useState } from "react";

import styles from "./surveydetails.module.css";

export const SurveyDetailsRoute = () => {
  const accountBrand = useAccount((s) => s.active);
  const [downloadOpen, setDownloadOpen] = useState(false);

  const getExportUrl = async (type: any) => {
    const res = await api!.exports.surveyDetails.query({
      accountBrandId: accountBrand!.accountBrandId,
      subType: type,
      format: "CSV",
    });

    return res?.exportUrl;
  };

  return (
    <Layout>
      <div className={styles.nav}>
        <h1 className={styles.title}>Survey Details</h1>
        <Button
          icon={DownloadIcon}
          label="Download raw responses"
          theme="ghost"
          onClick={() => setDownloadOpen(true)}
        />
      </div>
      <div className={cx(styles.container, mixins["grid-content"])}>
        <TrackingDetails className={styles.tracking} />
        <div className={styles.details}>
          <SurveyStructure />
        </div>
        <Download
          open={downloadOpen}
          onClose={() => setDownloadOpen(false)}
          heading="Downloading your raw responses"
          label="Raw responses for"
          fetcher={getExportUrl}
          format="CSV"
          stonly="exporting-your-raw-responses-from-survey-details-VpjPVdfqsZ"
          types={[
            "FUNNEL",
            "IMAGERY",
            "STATEMENTS",
            "UNPROMPTED",
            "CUSTOM_QUESTIONS",
          ]}
        >
          <p>This is currently only available in CSV format.</p>
        </Download>
      </div>
    </Layout>
  );
};
