import { DatePeriod } from "$src/components/date-period/date-period";
import {
  ControlledDropdown,
  DropdownItem,
  type DropdownProps,
} from "$src/components/dropdown/dropdown";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useValidDatePeriod } from "$src/hooks/useValidDatePeriod";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { useFilters } from "$src/stores/useFilters";
import { SampleIndicator } from "$src/views/sample-indicator/sample-indicator";
import { differenceInCalendarMonths, format } from "date-fns";
import { useEffect, useMemo, useState } from "react";

import type { SampleQuality } from "@tracksuit/frontend/schemas";
import {
  TimePeriod,
  formatDateRange,
  getDateRangeLabel,
  toDate,
} from "@tracksuit/frontend/utils";

import styles from "./date.module.css";
import { CustomRange } from "./lib/custom-range/custom-range";
import { DisabledExplainer } from "./lib/disabled-explainer/disabled-explainer";

export type DateFilterType = "period" | "range" | "comparison";

export type FilterDatesProps = {
  quality?: SampleQuality;
  type: DateFilterType;
  disabled?: boolean;
  theme?: string;
  labelFormat?: "period" | "dates";
} & Partial<DropdownProps>;

export const FilterDates = ({
  type = "period",
  quality,
  theme = "select",
  labelFormat = "dates",
  disabled,
  className,
  children,
}: FilterDatesProps) => {
  const [open, setOpen] = useState(false);
  const [customRangeOpen, setCustomRangeOpen] = useState(false);
  const { availableFilters, loading } = useAvailableFilters();

  const filterKey = useMemo(
    () => (type === "range" ? "dateRange" : "datePeriod"),
    [type],
  );
  const [filters, filtersReady, setFilters] = useFilters((s) => [
    s.filters,
    s.ready,
    s.set,
  ]);
  const dateFilter = useMemo(() => filters[filterKey], [filters, filterKey]);
  const validperiod = useValidDatePeriod();
  const analytics = useAnalytics();
  const availableDates = useMemo(
    () =>
      availableFilters.dates
        ? availableFilters.dates.filter((_date, i) =>
            type === "comparison"
              ? i < Math.floor(availableFilters.dates!.length / 2)
              : true,
          )
        : [],
    [availableFilters.dates, type],
  );
  const periodMonths = useMemo(
    () => ({
      [TimePeriod.NoMonths]: 1,
      [TimePeriod.ThreeMonths]: 3,
      [TimePeriod.SixMonths]: 6,
      [TimePeriod.TwelveMonths]: 12,
      [TimePeriod.AllTime]: availableDates.length,
      [TimePeriod.Custom]: 0,
    }),
    [availableDates],
  );
  const activePeriod = useMemo(() => {
    if (!dateFilter.start || !dateFilter.end) {
      return TimePeriod.NoMonths;
    }
    const periodLength =
      differenceInCalendarMonths(
        toDate(dateFilter.end),
        toDate(dateFilter.start),
      ) + 1;
    const period = (Object.keys(TimePeriod).find(
      (key) =>
        periodMonths[TimePeriod[key as keyof typeof TimePeriod]] ===
        periodLength,
    ) ?? "Custom") as keyof typeof TimePeriod;

    return dateFilter.end === availableDates[0]
      ? TimePeriod[period]
      : TimePeriod.Custom;
  }, [dateFilter.start, dateFilter.end, availableDates]);

  const filterLabel = useMemo(() => {
    if (!availableDates.length || !dateFilter.start || !dateFilter.end) {
      return undefined;
    }

    if (activePeriod === TimePeriod.NoMonths && filtersReady) {
      return `Since ${format(
        toDate(availableDates[availableDates.length - 1] as string),
        "MMMM",
      )}`;
    }

    return labelFormat === "dates"
      ? formatDateRange(dateFilter.start, dateFilter.end)
      : getDateRangeLabel(dateFilter.start, dateFilter.end);
  }, [dateFilter, availableFilters.dates, activePeriod]);

  const setRangeFromPeriod = (period: TimePeriod) => {
    if (!availableDates) {
      return;
    }

    const range = {
      start:
        period === TimePeriod.NoMonths
          ? availableDates[availableDates.length - 1]
          : availableDates[periodMonths[period] - 1],
      end: availableDates[0],
    };
    setFilters({
      [filterKey]: { ...dateFilter, ...range },
    });
  };

  useEffect(() => {
    if (type === "comparison" && !validperiod.comparison) {
      setFilters({
        datePeriod: {
          start: availableDates[availableDates.length - 1],
          end: availableDates[0],
          comparisonPeriod: "preceding",
        },
      });
    }
  }, [type, validperiod.comparison, availableDates]);

  /* v8 ignore next */
  // Analytics tracking ignored from coverage
  useEffect(() => {
    analytics?.track(AnalyticsEvents.FilterDate, {
      period: activePeriod,
    });
  }, [activePeriod, analytics]);

  return (
    <>
      <ControlledDropdown
        open={open}
        onChange={setOpen as any}
        theme={theme}
        disabled={loading || disabled}
        className={className}
        label="Date range"
        selected={filterLabel}
      >
        <SampleIndicator
          className={styles.sample}
          quality={quality}
          context="date"
        />
        {Object.values(TimePeriod)
          .filter((period) => {
            switch (period) {
              case TimePeriod.NoMonths:
              case TimePeriod.Custom:
                return false;
              case TimePeriod.AllTime:
                return type === "range";
            }
            return true;
          })
          .map((period) => {
            const available = !!availableDates?.[periodMonths[period] - 1];
            return (
              <DropdownItem
                id="date-filter"
                key={period}
                locked={!available}
                onClick={() => {
                  setRangeFromPeriod(period);
                  setTimeout(() => setOpen(false), 0);
                }}
              >
                <DatePeriod
                  disabled={!available}
                  active={activePeriod === period}
                  label={period}
                  start={availableDates?.[periodMonths[period] - 1]}
                  end={availableDates?.[0]}
                />
              </DropdownItem>
            );
          })}
        {availableDates && availableDates.length >= 3 && (
          <DropdownItem
            truncate={false}
            id="date-filter"
            active={
              activePeriod === TimePeriod.Custom ||
              (type !== "range" && activePeriod === TimePeriod.AllTime)
            }
            onClick={() => {
              setCustomRangeOpen(true);
              setOpen(false);
            }}
          >
            <span>Custom date range</span>
          </DropdownItem>
        )}
        {children}
        {!!availableDates && availableDates.length < 11 && (
          <DisabledExplainer />
        )}
      </ControlledDropdown>
      <CustomRange
        type={type}
        open={customRangeOpen}
        onClose={() => setCustomRangeOpen(false)}
      />
    </>
  );
};
