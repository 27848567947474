// v8 ignore start: HACK madi to come back to fix
import { Collapsible } from "$src/components/collapsible/collapsible";
import {
  EMPTY_STATE_CONTENT,
  EmptyState,
} from "$src/components/empty-state/empty-state";
import { Tag } from "$src/components/tag/tag";
import { useActiveFiltersLabel } from "$src/hooks/useActiveFiltersLabel";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { COMPETITOR_COLORS, STAGE_EXPLANATIONS } from "$src/lib/consts";
import { useRegionalize } from "$src/lib/regionalization";
import { cx } from "$src/lib/utils";
import { useStatementsMetrics } from "$src/queries/statements";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { useDeepCompareEffect } from "@react-hookz/web";
import { groupBy, mapValues } from "lodash-es";
import {
  type ComponentProps,
  createContext,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";

import type { QuestionType } from "@tracksuit/frontend/schemas";

import { Axis } from "./lib/axis/axis";
import { getMaxValue } from "./lib/getMaxValue";
import { Statement } from "./lib/statement/statement";
import styles from "./statements.module.css";

export type StatementsProps = ComponentProps<"div">;

export const ActiveBrandContext = createContext<{
  active?: number[];
  setActive?: (ids: number[]) => void;
}>({});

export const useMetricLabel = (metric?: QuestionType) => {
  const regionTerminology = useRegionalize(
    STAGE_EXPLANATIONS[metric ?? "UNPROMPTED_AWARENESS"]?.explanation ?? "",
  );
  return metric
    ? `Those who ${STAGE_EXPLANATIONS[metric]?.verb} ${regionTerminology} believe it...`
    : "";
};

export const getCompetitorColor = (brand: any, i: number) => {
  const color =
    COMPETITOR_COLORS[i] ?? COMPETITOR_COLORS[i - COMPETITOR_COLORS.length + 1];

  if (brand.isAccountBrand) {
    return {
      default: "var(--color-purple-100)",
      active: "var(--color-purple-500)",
    };
  }

  if (brand.isCompetitorAverage) {
    return {
      default: "var(--color-purple-200)",
      active: "var(--color-purple-700)",
    };
  }

  return {
    default: color?.secondary,
    active: color?.primary,
  };
};

/**
 * @component
 *
 */
export const Statements = forwardRef(function Statements(
  { className, ...props }: StatementsProps,
  ref,
) {
  const account = useAccount((s) => s.active);
  const { question, brandIdList } = useFilters((s) => s.filters);
  const [activeBrands, setActiveBrands] = useState<number[]>();
  const [hydrated, setHydrated] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const filterLabel = useActiveFiltersLabel();
  const { data, loading } = useStatementsMetrics();
  const statements = useMemo(
    () =>
      data?.statements.map(({ statement, metrics }) => ({
        statement,
        metrics: metrics?.filter((metric) =>
          brandIdList?.includes(metric.brandId ?? 0),
        ),
      })),
    [data, brandIdList],
  );
  const [colorMap, setColorMap] = useState<{
    [brand: number]: { default: string; active: string };
  }>([]);
  const state = useMemo(() => {
    if (!brandIdList.length) {
      return "brands";
    }
    if (!data?.quality === "INSUFFICIENT") {
      return "sample";
    }
    return "data";
  }, [data, brandIdList]);
  const analytics = useAnalytics();
  const maxValue = useMemo(() => getMaxValue(statements), [statements]);
  const metricLabel = useMetricLabel(question);

  useEffect(() => {
    setHydrated(false);
  }, [account]);

  useEffect(() => {
    if (hydrated || !account) {
      return;
    }

    setActiveBrands([account.brandId]);
    setHydrated(true);
  }, [hydrated, account]);

  useDeepCompareEffect(() => {
    if (statements) {
      const colors = mapValues(
        groupBy(
          statements?.[0]?.metrics?.map((metric, i) => ({
            brandId: metric.brandId,
            color: getCompetitorColor(metric, i),
          })),
          "brandId",
        ),
        (v) => v[0]?.color,
      );
      setColorMap(colors as any);
    }
  }, [statements]);

  useEffect(() => {
    expanded &&
      analytics?.track(AnalyticsEvents.ExpandAccordion, {
        view: "Statements",
        metadata: {
          accordion: "Question",
        },
      });
  }, [analytics, expanded]);

  return (
    <div
      ref={ref as any}
      className={cx(className, styles.container)}
      {...props}
    >
      {state === "brands" && <EmptyState {...EMPTY_STATE_CONTENT.brands} />}
      {state === "sample" && <EmptyState {...EMPTY_STATE_CONTENT.sample} />}
      {state === "data" && (
        <>
          <div
            className={cx(styles["title-container"], expanded && styles.open)}
          >
            <Collapsible
              triggerAlignment="center"
              trigger={<h2 className={cx(styles.title)}>{metricLabel}</h2>}
              onChange={setExpanded}
            >
              <div className={cx(styles["question"])}>
                <h3 className={styles["question-title"]}>
                  How is this asked in the survey?
                </h3>
                <p>Do you feel {account?.brandName}:</p>
                <ul className={styles["question-list"]}>
                  {data?.statements.map(({ statement }) => (
                    <li key={statement}>{statement}</li>
                  ))}
                </ul>
                <p className={styles["question-info"]}>
                  <em>
                    Repeated for all competitors that the respondent is aware
                    of.
                  </em>
                </p>
              </div>
            </Collapsible>
          </div>
          <div className={styles["content"]}>
            <div className={styles["filters-label"]}>
              Filtered by: {filterLabel}
            </div>
            <ActiveBrandContext.Provider
              value={{
                active: activeBrands,
                setActive: setActiveBrands,
              }}
            >
              <div className={styles.statements}>
                <div className={styles["axis-wrap"]}>
                  {!loading && (
                    <Axis className={styles.axis} maxValue={maxValue} />
                  )}
                </div>
                <div className={styles["statements-list"]}>
                  {loading
                    ? Array.from({ length: 5 }).map((_, i) => (
                        <Statement loading key={i} />
                      ))
                    : statements?.map(({ statement, metrics }) => (
                        <Statement
                          statement={statement}
                          data={metrics?.map((metric) => ({
                            ...metric,
                            color: colorMap[metric.brandId ?? 0]!,
                          }))}
                          maxValue={maxValue}
                          key={statement}
                        />
                      ))}
                </div>
              </div>

              <div className={styles.legend}>
                {statements?.[0]?.metrics?.map(({ brandId, brandName }) => (
                  <Tag
                    className={styles["legend-tag"]}
                    color={colorMap[brandId ?? 0]?.active}
                    active={activeBrands?.includes(brandId ?? 0)}
                    onClick={() => {
                      setActiveBrands(() => [brandId ?? 0]);
                      analytics?.track(AnalyticsEvents.SwappedCompetitor, {
                        competitor: brandName,
                        view: "Statements",
                        metadata: {
                          method: "Legend",
                        },
                      });
                    }}
                    label={brandName}
                    key={brandId}
                  />
                ))}
              </div>
            </ActiveBrandContext.Provider>
          </div>
        </>
      )}
    </div>
  );
});
/* v8 ignore end */
