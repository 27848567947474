import { useFocus } from "$src/hooks/useFocus";
import { cx } from "$src/lib/utils";
import { type ComponentProps, useEffect, useRef, useState } from "react";

import styles from "./textarea.module.css";

export type TextareaProps = {
  label?: string;
  disabled?: boolean;
  invalid?: boolean;
  info?: string;
  errorMessage?: string;
} & ComponentProps<"textarea">;

/**
 * @component
 * Textarea form input
 */
export const Textarea = ({
  label,
  value,
  disabled,
  invalid,
  info,
  errorMessage,
  className,
  onChange,
  ...props
}: TextareaProps) => {
  const textarea = useRef<HTMLTextAreaElement>(null);
  const [inputValue, setInputValue] = useState<any>(value);
  const isFocused = useFocus(textarea);
  const [active, setActive] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    setActive(!!inputValue || isFocused);
  }, [inputValue, isFocused]);

  useEffect(() => {
    setIsInvalid(invalid ?? false);
    if (value && String(value).length > 0) {
      setIsInvalid(false);
    }
  }, [invalid, value]);

  return (
    <div className={className}>
      <label
        className={cx(
          styles.textarea,
          disabled && styles.disabled,
          (isInvalid ?? errorMessage) && styles.error,
          active && styles.active,
        )}
      >
        <span className={styles.label}>{label}</span>
        <textarea
          ref={textarea}
          className={styles.input}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            setInputValue(e.target.value);
            onChange?.(e);
          }}
          {...props}
        />
      </label>
      {info && <div className={styles.info}>{info}</div>}
      {errorMessage && isInvalid && (
        <div className={styles["error-message"]}>{errorMessage}</div>
      )}
    </div>
  );
};
