import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import type { BFFOutput } from "@tracksuit/bff/trpc";

type AccountStore = {
  active?: NonNullable<BFFOutput["metadata"]["list"]>[0];
  accounts: NonNullable<BFFOutput["metadata"]["list"]>;
  reset(): void;
  populate(accounts: BFFOutput["metadata"]["list"]): void;
  activate(accountBrandId: number): void;
};

export const useAccount = createWithEqualityFn<AccountStore>()(
  (set, get) => ({
    active: undefined,
    accounts: [],
    reset: () => set({ accounts: [] }),
    populate: (accounts) => set({ accounts }),
    activate: (accountBrandId) => {
      const accountBrand = get().accounts.find(
        (account) => account.accountBrandId === Number(accountBrandId),
      );

      if (!get().accounts.length) {
        throw new Error("No accounts available");
      }

      if (!accountBrand) {
        throw new Error("Account not found");
      }
      set({ active: accountBrand });
    },
  }),
  shallow,
);
