import styles from "./milestone-header.module.css";

export function MilestoneHeader({
  heading,
  text,
}: {
  heading: string;
  text: string;
}) {
  return (
    <div className={styles["header"]}>
      <h3 className={styles["heading"]}>{heading}</h3>
      <span className={styles["text"]}>{text}</span>
    </div>
  );
}
