import { type RouteObject } from "react-router-dom";

import { StatementsLayout } from "./layout";
import { StatementsComparisonRoute } from "./routes/comparison/comparison";
import { StatementsOverviewRoute } from "./routes/overview";

export const statementsRoutes: RouteObject["children"] = [
  {
    Component: StatementsLayout,
    children: [
      {
        index: true,
        Component: StatementsOverviewRoute,
      },
      {
        path: "comparison/:statementId?",
        Component: StatementsComparisonRoute,
      },
    ],
  },
];
