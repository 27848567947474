import { DesktopOnly } from "$src/components/desktop-only/desktop-only";
import { Toggle } from "$src/components/toggle/toggle";
import { media } from "$src/styles";
import { useMediaQuery } from "@react-hookz/web";
import { useFlags } from "launchdarkly-react-client-sdk";
import { type ComponentProps } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./sublayout.module.css";

export function SettingsSublayout({
  children,
  view,
}: { view: "users" | "tokens" } & ComponentProps<"div">) {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(media.laptop, {
    initializeWithValue: true,
  });
  const { apiTokens } = useFlags();

  return isDesktop ? (
    <>
      <div className={styles.head}>
        <h1 className={styles.title}>Account Settings</h1>
      </div>
      <div className={styles.container}>
        {apiTokens && (
          <div className={styles.toggles}>
            <Toggle
              animated={false}
              className={styles.toggle}
              selected={view}
              options={[
                { value: "users", label: "Users" },
                { value: "tokens", label: "Tokens" },
              ]}
              onChange={(val) => {
                if (val === "users") {
                  navigate("/settings");
                } else {
                  navigate(`/settings/${val}`);
                }
              }}
            />
          </div>
        )}
        <div>{children}</div>
      </div>
    </>
  ) : (
    <DesktopOnly />
  );
}
