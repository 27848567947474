import { cx } from "$src/lib/utils";
import type { ComponentProps } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./nav-bar.module.css";

type NavBarItem = {
  label: string;
  pathname: string;
  matchSubpaths?: boolean;
  alsoMatch?: string;
};

export type NavBarProps = {
  /** Nav items */
  items: NavBarItem[];
} & ComponentProps<"div">;

/**
 * @component
 * Tabbed navigation bar for views
 */
export const NavBar = ({ items, className, ...props }: NavBarProps) => {
  const { pathname } = useLocation();

  /* v8 ignore next: can't test pathname */
  const itemActive = (item: NavBarItem) => {
    if (pathname === item.pathname) {
      return true;
    }

    if (item.alsoMatch && pathname === item.alsoMatch) {
      return true;
    }

    return item.matchSubpaths ? pathname.startsWith(item.pathname) : false;
  };

  return (
    <nav className={cx(styles.navbar, className)} {...props}>
      <div className={styles.items}>
        {items.map((item) => (
          <Link
            className={cx(styles.item, itemActive(item) && styles.active)}
            to={item.pathname}
            key={item.pathname}
          >
            {item.label}
          </Link>
        ))}
      </div>
    </nav>
  );
};
