import { cx } from "$src/lib/utils";
import type { ComponentProps } from "react";

import { formatDateRange } from "@tracksuit/frontend/utils";

import styles from "./date-period.module.css";

export type DatePeriodProps = {
  /** Label of the period */
  label?: string;
  /** Starting date of the period */
  start?: string;
  /** End date of the period */
  end?: string;
  /** Whether the period is disabled */
  disabled?: boolean;
  /** Whether the period is active */
  active?: boolean;
} & ComponentProps<"div">;

/**
 * @component
 * Date period with label and disabled states
 */
export const DatePeriod = ({
  label,
  start,
  end,
  disabled,
  active,
  className,
  ...props
}: DatePeriodProps) => {
  return (
    <div
      className={cx(
        styles.wrapper,
        disabled && styles.disabled,
        active && styles.active,
        className,
      )}
      {...props}
    >
      {label && <span>{label}</span>}
      {!disabled && (
        <div className={styles.period} data-testid="date-period-dates">
          {formatDateRange(start, end)}
        </div>
      )}
    </div>
  );
};
