import { BarChart } from "$src/components/bar-chart/bar-chart";
import { FilterBrands } from "$src/components/filters/brand/brand";
import { Tag } from "$src/components/tag/tag";
import { cx, percentageFormatter, roundNumber } from "$src/lib/utils";
import { useFunnel } from "$src/queries/funnel";
import { useFilters } from "$src/stores/useFilters";
import { useHiddenItems } from "$src/stores/useHiddenItems";
import { media } from "$src/styles";
import { useMediaQuery } from "@react-hookz/web";
import { useFlags } from "launchdarkly-react-client-sdk";
import { type ComponentProps, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import type {
  FunnelQuestionType,
  QuestionType,
} from "@tracksuit/frontend/schemas";
import { sortBrands } from "@tracksuit/frontend/utils";

import styles from "./brand-comparison.module.css";

export type BrandComparisonProps = {
  stage: QuestionType;
  single?: boolean;
} & ComponentProps<"div">;

export const BrandComparison = ({
  stage,
  single,
  className,
  ...props
}: BrandComparisonProps) => {
  const [{ dataRepresentation, brandId, hasNoPopulationData }, setFilters] =
    useFilters((s) => [s.filters, s.set]);
  const { data, loading } = useFunnel();
  const [hiddenCompetitors, hideCompetitor] = useHiddenItems((s) => [
    s.hidden.brands,
    s.add,
  ]);
  const { singleBrandFunnel } = useFlags();
  const stageMetrics = data?.stages?.[
    stage as FunnelQuestionType
  ]?.metrics.sort((a, b) => {
    return single ? sortBrands(a, b) : b.percentage - a.percentage;
  });
  const brandMetrics = useMemo(
    () => stageMetrics?.find(({ isAccountBrand }) => isAccountBrand),
    [stageMetrics],
  );
  const maybeCompetiorMetrics = useMemo(
    () =>
      singleBrandFunnel
        ? undefined
        : [stageMetrics?.find((metric) => metric.brandId === brandId)],
    [stageMetrics, singleBrandFunnel, brandId],
  );
  const isDesktop = useMediaQuery(media.mobile);

  const handleBarClick = () => {
    if (hasNoPopulationData) {
      return;
    }

    setFilters({
      dataRepresentation:
        dataRepresentation === "population" ? "percentage" : "population",
    });
  };

  const getBarColor = (
    metric?: NonNullable<typeof stageMetrics>[0] & {
      isCompetitorAverage?: boolean;
    },
  ) => {
    if (metric?.isAccountBrand) {
      return "var(--color-purple-500)";
    }

    if (metric?.isCompetitorAverage) {
      return "var(--color-purple-400)";
    }

    return "var(--color-purple-700)";
  };

  return (
    <div
      className={cx(
        styles.wrapper,
        singleBrandFunnel && single && styles["single-funnel"],
        className,
      )}
      {...props}
    >
      <div>
        {single && !singleBrandFunnel && (
          <div className={styles.label}>
            <span className={styles["account-label"]}>
              {loading ? <LoadingLabel /> : brandMetrics?.brandName}
            </span>
          </div>
        )}
        {single
          ? isDesktop &&
            !singleBrandFunnel && (
              <FilterBrands
                className={styles["brand-filter"]}
                theme="pill"
                includeAccountBrand={false}
                includeCompetitorAverage={true}
              />
            )
          : stageMetrics
              ?.filter(
                (metric) =>
                  !hiddenCompetitors.some(
                    ({ id }) => Number(id) === metric?.brandId,
                  ),
              )
              .map((metric) => (
                <div
                  className={cx(styles.label, styles.multi)}
                  key={metric.brandId}
                >
                  {loading ? (
                    <LoadingLabel />
                  ) : (
                    <Tag
                      className={cx(styles["competitor-label"], styles.multi)}
                      hideAction
                      label={metric.brandName ?? ""}
                      action="Hide"
                      onAction={() => {
                        hideCompetitor("brands", {
                          id: String(metric.brandId),
                          label: metric.brandName ?? "",
                        });
                      }}
                    />
                  )}
                </div>
              ))}
      </div>
      <BarChart
        loading={loading}
        data={[
          ...(single
            ? [
                {
                  value: brandMetrics?.percentage ?? 0,
                  label:
                    dataRepresentation === "population"
                      ? roundNumber(brandMetrics?.total ?? 0, true)
                      : undefined,
                  onClick: handleBarClick,
                },
              ]
            : []),
          ...((single
            ? maybeCompetiorMetrics
            : stageMetrics?.sort(
                (a, b) => (b?.percentage ?? 0) - (a?.percentage ?? 0),
              )
          )
            ?.filter((metric) =>
              single
                ? true
                : !hiddenCompetitors.some(
                    ({ id }) => Number(id) === metric?.brandId,
                  ),
            )
            .map((metric) => ({
              value: metric?.percentage ?? 0,
              colors: {
                bar: getBarColor(metric),
                label: "var(--color-off-white)",
              },
              label:
                dataRepresentation === "population"
                  ? roundNumber(metric?.total ?? 0, true)
                  : percentageFormatter.format(metric?.percentage ?? 0),
              onClick: handleBarClick,
            })) ?? []),
        ]}
      />
      {!singleBrandFunnel && single && !isDesktop && (
        <FilterBrands
          className={styles["brand-filter"]}
          theme="pill"
          includeAccountBrand={false}
          includeCompetitorAverage={true}
        />
      )}
    </div>
  );
};

const LoadingLabel = () => (
  <Skeleton width="10ch" baseColor="var(--color-purple-200)" />
);
