import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";

import type { StatementsQuestionType } from "@tracksuit/frontend/schemas";

export const useStatementsMetrics = () => {
  const [{ datePeriod, demographics, question }, filtersReady] = useFilters(
    (s) => [s.filters, s.ready],
  );
  const accountBrand = useAccount((s) => s.active);
  const { data, isLoading: loading } =
    apiQueryClient.statements.getMetrics.useQuery(
      {
        accountBrandId: accountBrand!.accountBrandId,
        startDate: datePeriod.start!,
        endDate: datePeriod.end!,
        filters: demographics?.map(({ filter }) => filter) ?? [],
        filterIds: demographics?.map(({ id }) => id) ?? [],
        question: question as StatementsQuestionType,
      },
      {
        enabled:
          filtersReady &&
          accountBrand?.featuresConfig.statementsEnabled === true,
      },
    );

  return {
    data,
    loading,
  };
};
