import { type RouteObject } from "react-router-dom";

export const surveyRoutes: RouteObject["children"] = [
  {
    lazy: () => import("./layout"),
    children: [
      {
        path: ":token/view",
        lazy: () => import("./routes/view/view"),
      },
    ],
  },
];
