import { apiQueryClient } from "$src/lib/api";

export const useMetadataList = (clientId?: string[] | null) => {
  const { data, isLoading: loading } = apiQueryClient.metadata.list.useQuery(
    {
      clientIds: clientId?.map((id) => Number(id)),
    },
    {
      enabled: typeof clientId !== "undefined",
    },
  );

  return { data, loading };
};

export const useMetadata = (clientId?: string | null, geographyId?: number) => {
  const { data, isLoading: loading } = apiQueryClient.metadata.get.useQuery(
    {
      clientId: Number(clientId),
      geographyId: geographyId!,
    },
    { enabled: geographyId !== undefined && typeof clientId === "string" },
  );

  return { data, loading };
};
