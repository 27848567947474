import { z } from "zod";

import { date } from "./date";
import { naturalNumber, percentage, signedPercentage } from "./numbers";

export const timelineBaseEntity = z.object({
  metrics: z
    .object({
      waveDate: date,
      population: naturalNumber.optional(),
      percentage,
    })
    .array(),
  difference: z.object({
    percentage: signedPercentage.optional(),
    isSignificant: z.boolean(),
  }),
});
