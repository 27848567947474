import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { COMPETITOR_AVERAGE_ID } from "$src/lib/consts";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { useEffect, useMemo } from "react";

import { MultiSelectable } from "../mutli-selectable/multi-selectable";

export type BrandFilterProps = {
  /** Whether the user can multi-select brand IDs */
  multiSelect?: boolean;
  /** Whether to include the account brand */
  includeAccountBrand?: boolean;
  /** Whether to include competitor average */
  includeCompetitorAverage?: boolean;
  className?: string;
};

export const FilterBrands = ({
  multiSelect,
  includeAccountBrand = true,
  includeCompetitorAverage = false,
  className,
}: BrandFilterProps) => {
  const account = useAccount((s) => s.active);
  const [{ brandId, brandIdList }, setFilters] = useFilters((s) => [
    s.filters,
    s.set,
    s.ready,
  ]);
  const { availableFilters } = useAvailableFilters();
  const analytics = useAnalytics();
  const brands = useMemo(
    () =>
      availableFilters.brands.filter((brand) => brand.id !== account?.brandId),
    [availableFilters, includeAccountBrand, includeCompetitorAverage],
  );
  const allBrands = useMemo(
    () =>
      [
        ...brands,
        includeAccountBrand && {
          id: account?.brandId,
          name: account?.brandName,
        },
        includeCompetitorAverage && {
          id: COMPETITOR_AVERAGE_ID,
          name: "Competitor average",
        },
      ].filter(Boolean) as { id: number; name: string }[],
    [account, includeAccountBrand, includeCompetitorAverage],
  );
  const activeLabel = useMemo(() => {
    const brandsInclusive = [
      { id: account?.brandId, name: account?.brandName },
      { id: COMPETITOR_AVERAGE_ID, name: "Competitor average" },
      ...brands,
    ];

    if (multiSelect && brandIdList) {
      return brandIdList.length > 1
        ? `Multiple brands (${brandIdList.length})`
        : brandsInclusive.find((brand) => brand.id === brandIdList[0])?.name;
    }

    if (!multiSelect && brandId) {
      return brandsInclusive.find((brand) => brand.id === brandId)?.name;
    }

    return undefined;
  }, [brands, brandId]);

  const isActive = (id?: number) => {
    if (multiSelect) {
      return !!brandIdList?.includes(Number(id));
    }

    return id === brandId;
  };

  const updateBrand = (id?: number) => {
    if (multiSelect === true && id !== undefined) {
      setFilters({
        brandIdList: isActive(id)
          ? brandIdList?.filter((bId) => bId !== id)
          : [...brandIdList, id],
      });
    } else {
      setFilters({
        brandId: id,
      });
    }
  };

  useEffect(() => {
    analytics?.track(AnalyticsEvents.FilterBrand, {
      brand: brandId,
    });
  }, [analytics, brandId]);

  return (
    <MultiSelectable
      label="Brand"
      selected={activeLabel}
      multiselect={!!multiSelect}
      selectAll={{
        isActive: allBrands.length === brandIdList.length,
        onChange: (state) =>
          setFilters({
            brandIdList: state ? allBrands.map(({ id }) => id) : [],
          }),
      }}
      items={allBrands.map(({ id, name }) => ({
        label: name,
        isActive: isActive(id),
        onClick: () => updateBrand(id),
      }))}
      className={className}
    />
  );
};
