import { EmptyState } from "$src/components/empty-state/empty-state";

import styles from "./not-found.module.css";

export const NotFound = () => {
  return (
    <div className={styles.page}>
      <EmptyState
        emoji="🕵️‍♀️"
        heading="We looked everywhere but you couldn't find what you're looking for"
        text="Try heading back to your dashboard, or get in touch with the team"
      />
    </div>
  );
};
