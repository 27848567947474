import { cx } from "$src/lib/utils";
import { Group } from "@visx/group";
import { Pack, hierarchy } from "@visx/hierarchy";
import { ParentSize } from "@visx/responsive";
import { Text } from "@visx/text";
import { useTooltip } from "@visx/tooltip";
import { sentenceCase } from "change-case";
import { AnimatePresence, motion } from "framer-motion";
import { type ComponentProps, useCallback, useRef } from "react";

import { Tip } from "../tooltip/tooltip";
import styles from "./pack-chart.module.css";

export type Point = {
  label: string;
  value: number;
  highlighted: boolean;
  tooltipLabel?: string;
  onClick?: () => void;
};

export type PackChartProps = {
  /** Data points for the chart */
  data: Point[];
  /** Colors for the chart */
  colors: {
    primary: {
      background: string;
      text: string;
    };
    secondary: {
      background: string;
      text: string;
    };
  };
} & ComponentProps<"div">;

const GUTTER = 6;

/**
 * @component
 * Packed heirerchy chart
 */
export function PackChart({
  data,
  colors,
  className,
  ...props
}: PackChartProps) {
  const root = hierarchy({
    label: "root",
    children: data,
    value: 0,
    highlighted: false,
    onClick: () => {},
  })
    .sum((d) => d.value)
    .sort((a, b) => a.value! - b.value!);
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<Point & { label: string }>();

  const tooltipTimeout = useRef(0);

  const handleMouseEnter = useCallback(
    (point: any) => {
      const { x, y, r, data } = point;
      if (tooltipTimeout.current) {
        clearTimeout(tooltipTimeout.current);
      }

      showTooltip({
        tooltipLeft: x,
        tooltipTop: y - r,
        tooltipData: {
          ...data,
          label: data.tooltipLabel,
        },
      });
    },
    [showTooltip],
  );

  const handleMouseOut = useCallback(() => {
    tooltipTimeout.current = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [hideTooltip]);

  const handleOnClick = useCallback((point: any) => {
    const { data } = point;
    data.onClick && data.onClick(data.label);
  }, []);

  return (
    <div className={cx(styles.wrapper, className)} {...props}>
      <ParentSize debounceTime={10}>
        {({ width, height }) =>
          width &&
          height && (
            <>
              <AnimatePresence>
                {tooltipOpen && (
                  <Tip
                    className={styles.tooltip}
                    style={{
                      top: `${tooltipTop ?? 0}px`,
                      left: `${tooltipLeft ?? 0}px`,
                    }}
                    withArrow
                    data-testid="line-chart-tooltip"
                    tip={tooltipData?.label}
                  />
                )}
              </AnimatePresence>

              <svg width={width} height={height}>
                <Pack root={root} size={[width, height]} padding={GUTTER}>
                  {(packData) => (
                    <Group top={0} left={0} key={packData.data.value}>
                      {packData
                        .descendants()
                        .slice(1)
                        .map((circle, i) => (
                          <motion.g
                            key={circle.data.label}
                            whileHover={{
                              scale: 1.05,
                              translateY: -circle.r * 0.05,
                              transition: {
                                type: "spring",
                                stiffness: 400,
                                damping: 17,
                                duration: 0.5,
                              },
                            }}
                            whileTap={{
                              scale: 0.98,
                              translateY: circle.r * 0.02,
                            }}
                            onClick={() => handleOnClick(circle)}
                            className={styles["outline-none"]}
                          >
                            <motion.circle
                              initial={{ r: 0 }}
                              animate={{ r: circle.r }}
                              transition={{
                                duration: Math.max(
                                  0.5,
                                  Number(String(circle.data.value)[0]) / 10,
                                ),
                              }}
                              r={circle.r}
                              cx={circle.x}
                              cy={circle.y}
                              fill={
                                circle.data.highlighted
                                  ? colors.primary.background
                                  : colors.secondary.background
                              }
                              className={styles.bubble}
                              data-testid={`pack-chart-bubble-${i}`}
                              onMouseEnter={() => handleMouseEnter(circle)}
                              onMouseOut={handleMouseOut}
                            />
                            <motion.g
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{
                                delay: Math.max(
                                  0.5,
                                  Number(String(circle.data.value)[0]) / 10,
                                ),
                              }}
                            >
                              <Text
                                width={circle.r}
                                y={circle.y}
                                x={circle.x}
                                fontSize={18}
                                fontWeight={500}
                                textAnchor="middle"
                                verticalAnchor="middle"
                                scaleToFit="shrink-only"
                                fill={
                                  circle.data.highlighted
                                    ? colors.primary.text
                                    : colors.secondary.text
                                }
                                style={{ pointerEvents: "none" }}
                              >
                                {sentenceCase(circle.data.label)}
                              </Text>
                            </motion.g>
                          </motion.g>
                        ))}
                    </Group>
                  )}
                </Pack>
              </svg>
            </>
          )
        }
      </ParentSize>
    </div>
  );
}
