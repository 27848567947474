import { cx } from "$src/lib/utils";
import type { ComponentProps } from "react";

import styles from "./bar-chart.module.css";
import { Bar, type BarData } from "./lib/bar";

export type BarChartProps = {
  /** Data for the chart */
  data: BarData[];
  /** Compact display mode */
  compact?: boolean;
  /** Whether chart data is loading */
  loading?: boolean;
  /** Whether bar chart is reversed orientation */
  reverse?: boolean;
} & ComponentProps<"div">;

/**
 * @component
 * Bar chart with adaptable labels
 */
export function BarChart({
  data,
  compact = false,
  loading,
  reverse,
  className,
  ...props
}: BarChartProps) {
  return (
    <div
      className={cx(styles.wrapper, compact && styles.compact, className)}
      {...props}
    >
      {data.map((bar, i) => (
        <div className={styles.runner} key={i} data-testid={`bar-chart-${i}`}>
          <Bar
            data={bar}
            compact={compact}
            loading={loading}
            reverse={reverse}
          />
        </div>
      ))}
    </div>
  );
}
