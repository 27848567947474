import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useValidatedParam = <T>({
  param,
  deseralize = (val) => val,
  validate,
  fallback,
  enabled = true,
}: {
  param: string;
  deseralize?(val: string): string;
  validate(val: string): boolean;
  fallback: string;
  enabled?: boolean;
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState<T>();

  useEffect(() => {
    if (!enabled) {
      setValue(undefined);
      return;
    }

    const formatted = params[param] ? deseralize(params[param]) : "";
    const valid = validate(formatted);
    if (!valid) {
      navigate(fallback);
      return;
    }

    setValue(formatted as T);
  }, [enabled, params, param, fallback, validate, deseralize]);

  return value;
};
