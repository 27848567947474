import { Button } from "$src/components/button/button";
import { Modal } from "$src/components/modal/modal";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useCopyImage } from "$src/hooks/useCopyImage";
import { STAGE_EXPLANATIONS } from "$src/lib/consts";
import { useFilters } from "$src/stores/useFilters";
import { ProfileBreakdown } from "$src/views/profile/profile-breakdown/profile-breakdown";
import { CopyIcon } from "lucide-react";
import { useEffect, useRef } from "react";

import type { Profile } from "../profile";
import styles from "./profile-details.module.css";

export type ProfileDetailsProps = {
  /** Whether the details are open */
  open: boolean;
  /** Callback on modal close */
  onClose(): void;
  /** Data for the profile */
  data: Profile[];
};

/**
 * @component
 * Details modal for a profile card
 */
export const ProfileDetails = ({
  open,
  onClose,
  data,
}: ProfileDetailsProps) => {
  const { question } = useFilters((s) => s.filters);
  const imageRef = useRef(null);
  const analytics = useAnalytics();
  const copyImage = useCopyImage(imageRef);

  useEffect(() => {
    open &&
      analytics?.track(AnalyticsEvents.OpenProfile, {
        profile: data[0]?.filterDisplayType,
      });
  }, [analytics, open]);

  return question ? (
    <Modal
      open={open}
      onClose={onClose}
      heading={`${data[0]?.filterDisplayType} breakdown of those who ${STAGE_EXPLANATIONS[question]?.verb} ${STAGE_EXPLANATIONS[question]?.question}`}
      large
      footer={
        <div className={styles.actions}>
          <Button theme="ghost" label="Close" onClick={onClose} />
          {copyImage && (
            <Button
              icon={CopyIcon}
              label="Copy as image"
              onClick={() => {
                copyImage();
                onClose();
              }}
            />
          )}
        </div>
      }
      ref={imageRef}
    >
      <ProfileBreakdown className={styles.chart} comparators={data} />
    </Modal>
  ) : null;
};
