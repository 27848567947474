import {
  ControlledDropdown,
  DropdownItem,
  type DropdownProps,
} from "$src/components/dropdown/dropdown";
import { cx } from "$src/lib/utils";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { useFilters } from "$src/stores/useFilters";
import { useMemo, useState } from "react";

import type { DateComparisonPeriod } from "@tracksuit/frontend/schemas";
import {
  getComparisonPeriodLabel,
  getDatePeriod,
} from "@tracksuit/frontend/utils";

export type ComparisonPeriodFilterProps = Partial<DropdownProps>;

/**
 * @component
 * Dropdown for selecting comparison periods
 */
export const ComparisonPeriodFilter = ({
  className,
  ...props
}: ComparisonPeriodFilterProps) => {
  const { availableFilters } = useAvailableFilters();
  const [{ datePeriod }, setFilters] = useFilters((s) => [s.filters, s.set]);
  const periods = useMemo(
    () => ({
      preceding: getDatePeriod(datePeriod.start, datePeriod.end, "preceding"),
      "last-year": getDatePeriod(datePeriod.start, datePeriod.end, "last-year"),
    }),
    [datePeriod],
  );
  const [open, setOpen] = useState(false);

  const updatePeriod = (period: DateComparisonPeriod) => {
    setFilters({
      datePeriod: { ...datePeriod, comparisonPeriod: period },
    });
    setOpen(false);
  };

  return (
    <ControlledDropdown
      open={open}
      onChange={setOpen}
      selected={getComparisonPeriodLabel(datePeriod.comparisonPeriod)}
      className={cx(className)}
      theme="inline"
      {...props}
    >
      <DropdownItem
        id="period"
        onClick={() => updatePeriod("preceding")}
        locked={!availableFilters?.dates?.includes(periods.preceding.start)}
      >
        {getComparisonPeriodLabel("preceding")}
      </DropdownItem>
      <DropdownItem
        id="period"
        onClick={() => updatePeriod("last-year")}
        locked={!availableFilters?.dates?.includes(periods["last-year"].start)}
      >
        {getComparisonPeriodLabel("last-year")}
      </DropdownItem>
    </ControlledDropdown>
  );
};
