import {
  EMPTY_STATE_CONTENT,
  EmptyState,
} from "$src/components/empty-state/empty-state";
import { api } from "$src/lib/api";
import { cx } from "$src/lib/utils";
import { useFunnel } from "$src/queries/funnel";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import mixins from "$src/styles/mixins.module.css";
import { Download } from "$src/views/download/download";
import { FilterBar } from "$src/views/filter-bar/filter-bar";
import { CategoryPenetration } from "$src/views/funnel/category-penetration/category-penetration";
import { FunnelOverview } from "$src/views/funnel/overview/overview";
import { useRef, useState } from "react";

import styles from "./overview.module.css";

export const FunnelOverviewRoute = () => {
  const { demographics, datePeriod } = useFilters((s) => s.filters);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const account = useAccount((s) => s.active);
  const { data } = useFunnel();
  const imageAreaElement = useRef<HTMLDivElement>(null);

  const getExportUrl = async () => {
    if (!account || !datePeriod.start || !datePeriod.end) {
      throw new Error("Account or date range not set");
    }

    const res = await api.exports.funnel.query({
      accountBrandId: account.accountBrandId,
      waveStartDate: datePeriod.start,
      waveEndDate: datePeriod.end,
      demographicFilter: demographics.map(({ filter }) => filter),
      demographicFilterIds: demographics.map(({ id }) => id),
      format: "XLSX",
    });

    return res?.exportUrl;
  };

  return (
    <>
      <FilterBar
        quality={data?.quality}
        filters={{
          demographic: true,
          date: {
            type: "period",
          },
        }}
        actions={{
          copyAsImage: imageAreaElement,
          download: () => setDownloadOpen(true),
        }}
        className={styles.nav}
      />
      {data?.quality !== "INSUFFICIENT" ? (
        <div
          ref={imageAreaElement}
          className={cx(styles["image-area"], mixins["grid-content"])}
        >
          <section className={styles.main}>
            <FunnelOverview className={styles.snapshot} />
          </section>

          <CategoryPenetration className={styles.category} />
        </div>
      ) : (
        <div className={styles.empty}>
          <EmptyState
            {...EMPTY_STATE_CONTENT.sample}
            className={styles["empty-inner"]}
          />
        </div>
      )}
      <Download
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
        fetcher={getExportUrl}
        heading="Downloading the Funnel Snapshot"
        label="Summary"
        format="XLSX"
        stonly="exporting-your-funnel-results-tqEdhx9ASj"
      >
        <p>
          This will reflect the filters you have selected, it&apos;s currently
          only available in Excel format.
        </p>
      </Download>
    </>
  );
};
