import { DropdownItem } from "$src/components/dropdown/dropdown";
import type { DropdownItemProps } from "$src/components/dropdown/lib/dropdown-item/dropdown-item";
import { cx } from "$src/lib/utils";
import type { ReactNode } from "react";
import { useLocation } from "react-router-dom";

import styles from "../settings-dropdown.module.css";

type SettingsMenuItemProps = {
  icon: any;
  label: ReactNode;
} & Omit<DropdownItemProps, "id">;

export function SettingsMenuItem({
  icon: Icon,
  label,
  className,
  ...props
}: SettingsMenuItemProps) {
  const { pathname } = useLocation();

  return (
    <DropdownItem id="settings" className={className} {...props}>
      <div
        className={cx(styles.item, pathname === props.href && styles.active)}
      >
        <Icon className={styles.icon} />
        <span className={styles.label}>{label}</span>
      </div>
    </DropdownItem>
  );
}
