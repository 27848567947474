import { cx } from "$src/lib/utils";
import { type ComponentProps } from "react";

import styles from "./axis.module.css";

export type AxisProps = {
  /** Data used in statements */
  maxValue: number;
} & ComponentProps<"div">;

/**
 * @component
 * Axis for statements views
 */
export function Axis({ maxValue = 1, className, ...props }: AxisProps) {
  return (
    <div className={cx(className, styles.axis)} {...props}>
      {Array.from({ length: maxValue * 10 + 1 }).map((_, i) => (
        <span key={i}>{i * 10}%</span>
      ))}
    </div>
  );
}
