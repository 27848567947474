import { Card } from "$src/components/card/card";
import { HoverContext, useHover } from "$src/hooks/useHover";
import { cx } from "$src/lib/utils";
import { motion } from "framer-motion";
import { ChevronRight } from "lucide-react";
import {
  type ComponentProps,
  type ComponentPropsWithoutRef,
  type ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import styles from "./collapsible.module.css";

export type CollapsibleProps = {
  /** Trigger section for the collapsible */
  trigger?: ReactNode;
  /** Alignment of trigger icon */
  triggerAlignment?: "top" | "center";
  /** On open/close callback */
  onChange?(open: boolean): void;
  /** Whether component is disabled */
  disabled?: boolean;
} & Omit<ComponentProps<"div">, "onChange">;

/**
 * @component
 * Base collapsible element with open control
 */
export const ControlledCollapsible = ({
  open,
  staggered,
  fast,
  onChange,
  onTrigger,
  triggerAlignment = "top",
  disabled,
  children,
  trigger,
  className,
  ...props
}: {
  open: boolean;
  onTrigger?(): void;
  staggered?: boolean;
  fast?: boolean;
} & CollapsibleProps) => {
  const duration = fast ? 0.15 : 0.3;
  const [hovering, setHovering] = useState(false);

  return (
    <div
      className={cx(
        styles.collapsible,
        !!trigger && styles["with-trigger"],
        className,
      )}
      {...props}
    >
      {trigger && (
        <HoverContext.Provider value={{ hovering, setHovering }}>
          <TriggerElement
            className={cx(
              styles["trigger-icon"],
              triggerAlignment === "center" && styles.centered,
            )}
            onClick={() => !disabled && onTrigger?.()}
          >
            <ChevronRight
              className={cx(open && styles.open)}
              data-testid="collapsible-chevron"
              data-x-hidden-from-screenshot
            />
          </TriggerElement>
          <TriggerElement
            onClick={() => !disabled && onTrigger?.()}
            className={cx(styles.trigger)}
          >
            {trigger}
          </TriggerElement>
        </HoverContext.Provider>
      )}
      <motion.div
        initial={false}
        animate={{
          height: open ? "auto" : "0px",
          opacity: open ? 1 : 0,
        }}
        style={{ overflow: "hidden" }}
        transition={{
          height: { duration },
          opacity: { duration, delay: staggered ? duration - 0.05 : 0 },
        }}
        onAnimationStart={() => onChange?.(true)}
        onAnimationComplete={() => onChange?.(open)}
        className={cx(styles.content)}
        data-testid="collapsible"
      >
        {children}
      </motion.div>
    </div>
  );
};

const TriggerElement = ({
  children,
  className,
  ...props
}: ComponentProps<"div">) => {
  const { hovering, setHovering } = useContext(HoverContext);
  const ref = useRef(null);
  const isHovering = useHover(ref);

  useEffect(() => setHovering(isHovering), [isHovering]);

  return (
    <div
      ref={ref}
      className={cx(className, hovering && styles.hover)}
      {...props}
    >
      {children}
    </div>
  );
};

/**
 * @component
 * Collapsible section with trigger
 */
export const Collapsible = ({ children, ...props }: CollapsibleProps) => {
  const [open, setOpen] = useState(false);

  return (
    <ControlledCollapsible
      onTrigger={() => setOpen((o) => !o)}
      open={open}
      {...props}
    >
      {children}
    </ControlledCollapsible>
  );
};

/**
 * @component
 * Blockquote element used inside Collapsibles
 */
const CardInCollapsible = ({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof Card>) => {
  return (
    <Card {...props} className={cx(className, styles["card-in-collapsible"])} />
  );
};

ControlledCollapsible.Card = CardInCollapsible;
Collapsible.Card = CardInCollapsible;
