import { cx } from "$src/lib/utils";
import type { ComponentProps } from "react";

import styles from "./dropdown-section.module.css";

/**
 * @component
 * Menu section in Dropdown
 */
export function DropdownSection({
  label,
  disabled,
  divider = true,
  className,
  children,
  ...props
}: {
  divider?: boolean;
  label?: string;
  disabled?: boolean;
} & ComponentProps<"div">) {
  return (
    <div
      className={cx(styles.section, divider && styles.divider, className)}
      {...props}
    >
      {label && <span className={styles["section-label"]}>{label}</span>}
      <div
        className={cx(styles["section-content"], disabled && styles.disabled)}
      >
        {children}
      </div>
    </div>
  );
}
