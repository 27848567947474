import { BarChart } from "$src/components/bar-chart/bar-chart";
import {
  ControlledDropdown,
  DropdownItem,
} from "$src/components/dropdown/dropdown";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { cx } from "$src/lib/utils";
import type { Profile } from "$src/views/profile/profile";
import { useDeepCompareEffect } from "@react-hookz/web";
import { groupBy, sortBy } from "lodash-es";
import {
  type CSSProperties,
  type ComponentProps,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";

import { sortBrands } from "@tracksuit/frontend/utils";

import styles from "./profile-breakdown.module.css";

type DataPoint = {
  label?: string;
  primaryValue: number;
  secondaryValue: number;
};

export type BarBreakdownProps = {
  /** Data for the breakdown */
  comparators: Profile[];
} & ComponentProps<"div">;

// TODO: Remove this hack once https://linear.app/tracksuit/issue/DASH-3859/add-sort-order-to-profile-response done
const INCOME_SORT_ORDER = [
  "Less than $25,000",
  "$25,000 - $49,999",
  "Less than $50,000",
  "$50,000 - $74,999",
  "$75,000 - $99,999",
  "$75,000 – $99,999",
  "$100,000 - $149,999",
  "$100,000 – $149,999",
  "$150,000 and above",
  "$150,000 - $199,999",
  "$200,000 and above",
  "Rather not say",
];

/**
 * @component
 * Breakdown bar chart of two datasets
 */
export const ProfileBreakdown = ({
  className,
  comparators,
  ...props
}: BarBreakdownProps) => {
  const analytics = useAnalytics();

  const sortedComparators = useMemo(
    () => sortComparators(comparators),
    [comparators],
  );

  const [comparatorOpen, setComparatorOpen] = useState(false);
  const [comparator1, setComparator1] = useState<Profile[]>(
    // TODO: Don't ! this
    sortedComparators[0]!,
  );

  const [comparator2Open, setComparator2Open] = useState(false);
  const [comparator2, setComparator2] = useState<Profile[]>(
    // TODO: Don't ! this
    sortedComparators[1]!,
  );

  const [barchartData, setBarchartData] = useState<DataPoint[]>(
    profilesToDataPoint({ comparator1, comparator2 }),
  );

  useEffect(() => {
    setBarchartData(profilesToDataPoint({ comparator1, comparator2 }));
  }, [comparator1, comparator2]);

  useDeepCompareEffect(() => {
    analytics?.track(AnalyticsEvents.FilterBrand, {
      brand: comparator1[0]?.brandName,
    });
  }, [comparator1]);

  useDeepCompareEffect(() => {
    analytics?.track(AnalyticsEvents.FilterBrand, {
      brand: comparator2[0]?.brandName,
    });
  }, [comparator2]);

  return (
    <div className={cx(className, styles.container)} {...props}>
      <div className={cx(styles.dropdown_1)}>
        <ControlledDropdown
          className={styles.dropdown}
          open={comparatorOpen}
          constrained
          onChange={setComparatorOpen}
          label={comparator1[0]?.brandName}
          theme="pill"
        >
          {Object.values(sortedComparators)
            .filter((c) => c[0]?.brandId !== comparator2[0]?.brandId)
            .map((comparator) => (
              <DropdownItem
                active={comparator[0]?.brandId === comparator1[0]?.brandId}
                data-testid={`${comparator[0]?.brandName}-comparator-item`}
                id="comparator"
                onClick={() => {
                  setComparator1(comparator);
                  setComparatorOpen(false);
                }}
                key={comparator[0]?.brandId}
              >
                {comparator[0]?.brandName}
              </DropdownItem>
            ))}
        </ControlledDropdown>
      </div>
      <span />
      <div>
        <ControlledDropdown
          className={styles.dropdown}
          open={comparator2Open}
          constrained
          onChange={setComparator2Open}
          label={comparator2[0]?.brandName}
          theme="pill"
        >
          {Object.values(sortedComparators)
            .filter((c) => c[0]?.brandId !== comparator1[0]?.brandId)
            .map((comparator) => (
              <DropdownItem
                active={comparator[0]?.brandId === comparator2[0]?.brandId}
                data-testid={`${comparator[0]?.brandName}-comparator2-item`}
                id="comparator"
                onClick={() => {
                  setComparator2(comparator);
                  setComparator2Open(false);
                }}
                key={comparator[0]?.brandId}
              >
                {comparator[0]?.brandName}
              </DropdownItem>
            ))}
        </ControlledDropdown>
      </div>
      {barchartData.map(({ label, primaryValue, secondaryValue }) => (
        <Fragment key={label}>
          <BarChart
            style={
              {
                "--bar-chart-radius":
                  "var(--radius-xl) var(--radius-xs) var(--radius-xs) var(--radius-xl)",
              } as CSSProperties
            }
            data={[
              {
                colors: {
                  bar: "var(--color-purple-500)",
                  label: "var(--color-bg)",
                  labelAlt: "var(--color-black)",
                },
                value: primaryValue,
              },
            ]}
            reverse
          />
          <span className={styles.label}>{label}</span>
          <BarChart
            style={
              {
                "--bar-chart-radius":
                  "var(--radius-xs) var(--radius-xl) var(--radius-xl) var(--radius-xs)",
              } as CSSProperties
            }
            data={[
              {
                colors: {
                  bar: "var(--color-purple-700)",
                  label: "var(--color-bg)",
                  labelAlt: "var(--color-black)",
                },
                value: secondaryValue,
              },
            ]}
          />
        </Fragment>
      ))}
    </div>
  );
};

const sortComparators = (comparators: Profile[]) => {
  const grouped = groupBy(comparators.sort(sortBrands), (c) => c.brandId);
  // TODO: Don't ! this
  return Object.values(grouped).sort((a, b) => sortBrands(a[0]!, b[0]!));
};

const filterComparators =
  (comparator1?: Profile[], comparator2?: Profile[]) => (data: Profile) => {
    return (
      Number(
        comparator1?.find(({ filter }) => filter === data.filter)?.percentage ??
          0,
      ) > 0.005 ||
      Number(
        comparator2?.find(({ filter }) => filter === data.filter)?.percentage ??
          0,
      ) > 0.005
    );
  };

const profilesToDataPoint = ({
  comparator1,
  comparator2,
}: {
  comparator1?: Profile[];
  comparator2?: Profile[];
}) => {
  const filterFn = filterComparators(comparator1, comparator2);
  const filtered1 = comparator1?.filter(filterFn).sort(sortDataPoints);
  const filtered2 = comparator2?.filter(filterFn).sort(sortDataPoints);

  if (!filtered1?.length && !filtered2?.length) {
    return [];
  }
  const profileTypes = groupBy([...filtered1!, ...filtered2!], (d) => d.filter);

  return Object.values(profileTypes).map((profiles) => ({
    label: profiles[0]?.filter,
    primaryValue: profiles[0]?.percentage ?? 0,
    secondaryValue: profiles[1]?.percentage ?? 0,
  }));
};

const sortDataPoints = (a: Profile, b: Profile) => {
  return a.filterDisplayType === "Income" ||
    a.filterDisplayType === "Household income"
    ? INCOME_SORT_ORDER.indexOf(a.filter) - INCOME_SORT_ORDER.indexOf(b.filter)
    : a.filter.localeCompare(b.filter);
};
