import { Layout } from "$src/components/layout/layout";
import { NavBar } from "$src/components/nav-bar/nav-bar";
import { cx } from "$src/lib/utils";
import mixins from "$src/styles/mixins.module.css";
import { Outlet } from "react-router-dom";

import styles from "./layout.module.css";

export const FunnelLayout = () => {
  return (
    <>
      <NavBar
        items={[
          { label: "Overview", pathname: "/funnel" },
          {
            label: "Timeline",
            pathname: "/funnel/timeline/metric",
            alsoMatch: "/funnel/timeline",
            matchSubpaths: true,
          },
          {
            label: "Comparison",
            pathname: "/funnel/comparison",
            matchSubpaths: true,
          },
        ]}
      />
      <Layout>
        <div className={cx(styles.container, mixins["grid-content"])}>
          <Outlet />
        </div>
      </Layout>
    </>
  );
};
