import { cx } from "$src/lib/utils";
import { type ComponentProps } from "react";

import styles from "./badge.module.css";

export const Badge = ({
  children,
  className,
  ...props
}: ComponentProps<"div">) => {
  return (
    <div className={cx(styles.badge, className)} {...props}>
      {children}
    </div>
  );
};
