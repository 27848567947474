import { cx } from "$src/lib/utils";
import mixins from "$src/styles/mixins.module.css";
import { useKeyboardEvent } from "@react-hookz/web";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { X } from "lucide-react";
import {
  type CSSProperties,
  type ComponentPropsWithoutRef,
  type ReactNode,
  forwardRef,
  useEffect,
} from "react";
import { createPortal } from "react-dom";

import styles from "./modal.module.css";

export type ModalProps = {
  /** Whether modal is open */
  open: boolean;
  /** Optional heading for the modal */
  heading?: string;
  /** Whether the modal should be large */
  large?: boolean;
  /** Color config */
  colors?: {
    header?: string;
    headerHover?: string;
    heading: string;
  };
  /** Optional additional header content area (sticky on scroll) */
  header?: ReactNode;
  /** Optional footer content areas (sticky on scroll) */
  footer?: ReactNode;
  /** Callback when modal closes */
  onClose(): void;
  children?: ReactNode;
};

const ANIMATIONS = {
  overlay: {
    closed: { opacity: 0 },
    open: {
      opacity: 1,
      transition: {
        delayChildren: 0.1,
      },
    },
  },
  modal: {
    closed: { opacity: 0, y: 20 },
    open: { opacity: 1, y: 0 },
  },
};

/**
 * @component
 * Modal that opens in a portal
 */
export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    { heading, colors, open, onClose, large, footer, children, ...props },
    ref,
  ) => {
    useEffect(() => {
      document.documentElement.style.overflow = open ? "hidden" : "";
    }, [open]);

    useKeyboardEvent("Escape", onClose);

    return createPortal(
      <AnimatePresence>
        {open && (
          <LayoutGroup>
            <motion.div
              className={cx(styles.overlay, mixins.scrollable)}
              variants={ANIMATIONS.overlay}
              initial="closed"
              animate="open"
              exit="closed"
              onClick={onClose}
              {...props}
            >
              <motion.div
                ref={ref}
                className={cx(
                  styles.modal,
                  colors?.header && styles["with-header"],
                  large && styles.large,
                )}
                variants={ANIMATIONS.modal}
                onClick={(e) => {
                  /* v8 ignore next */
                  e.stopPropagation();
                }}
                data-testid="overlay"
                style={
                  {
                    "--modal-heading-color": colors?.heading,
                    "--modal-hover-color": colors?.headerHover ?? "",
                  } as CSSProperties
                }
              >
                {heading && (
                  <div
                    data-testid="header"
                    className={styles.header}
                    style={{
                      background: colors?.header,
                    }}
                  >
                    <h2 className={styles.heading}>{heading}</h2>
                    <div
                      className={cx(styles.close)}
                      data-testid="close"
                      onClick={onClose}
                      data-x-hidden-from-screenshot
                    >
                      <X className={styles["close-icon"]} />
                    </div>
                  </div>
                )}
                <div
                  className={cx(
                    styles.body,
                    !!heading && styles["with-heading"],
                  )}
                >
                  <div className={cx(styles.content)}>{children}</div>
                  {footer && (
                    <div
                      data-testid="footer"
                      className={styles.footer}
                      data-x-hidden-from-screenshot
                    >
                      {footer}
                    </div>
                  )}
                </div>
              </motion.div>
            </motion.div>
          </LayoutGroup>
        )}
      </AnimatePresence>,
      document.body,
    );
  },
);

Modal.displayName = "Modal";
