import { useToasts } from "$src/stores/useToast";
import { toBlob } from "html-to-image";
import type { Options } from "html-to-image/lib/types";
import type { RefObject } from "react";

import { AnalyticsEvents, useAnalytics } from "./useAnalytics";

export const defaultOpts: Omit<Options, "type"> = {
  filter: (domNode: Node) =>
    !(domNode instanceof Element) ||
    !domNode.hasAttribute("data-x-hidden-from-screenshot"),
};

export function useCopyImage(
  ref: RefObject<HTMLElement>,
  opts: Omit<Options, "type"> = defaultOpts,
) {
  const addToast = useToasts((s) => s.add);
  const analytics = useAnalytics();

  async function copyImage() {
    if (ref.current === null) {
      throw new Error("Ref not found");
    }

    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          // Unlike what TypeScript thinks (https://github.com/microsoft/TypeScript/blob/7a38980a7e04bee0e273163f26c91d5c99f28298/src/lib/dom.generated.d.ts#L5748),
          // `ClipboardItemData` needs to be `Record<string, Promise<string | Blob>>` according to the specification: https://www.w3.org/TR/clipboard-apis/#typedefdef-clipboarditemdata
          // Otherwise, this would fail on Safari.
          "image/png": toBlob(ref.current, {
            type: "image/png",
            ...opts,
          }) as Promise<Blob>,
        }),
      ]);

      addToast("success", {
        title: "Image copied to clipboard",
        id: `toastie${Math.random()}`,
      });

      analytics?.track(AnalyticsEvents.CopyImage);
    } catch (error) {
      addToast("error", {
        title: "Well, this is embarrassing!",
        message:
          "We're really sorry but the copy image feature isn't working right now. Please try again later or if it continues contact our support team",
        id: `toastie${Math.random()}`,
      });

      throw error;
    }
  }

  async function notifyUnsupported() {
    addToast("error", {
      title: "Unsupported browser",
      message:
        "We're really sorry but the copy image feature is not supported in this browser. Please try again in Chrome, Edge or Safari.",
      id: `toastie${Math.random()}`,
    });
  }

  return "clipboard" in navigator && "write" in navigator.clipboard
    ? copyImage
    : notifyUnsupported;
}
