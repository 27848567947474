import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/* v8 ignore next: too hard basket to mock search params well enough */
export const useShallowUpdateSearchParams = (
  queryKey: string,
  queryValue?: string | number | number[] | string[] | null,
) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const oldQuery = currentSearchParams.get(queryKey) ?? "";

    if (queryValue === oldQuery) {
      return;
    }

    if (!queryValue) {
      currentSearchParams.delete(queryKey);
    } else if (Array.isArray(queryValue)) {
      currentSearchParams.delete(queryKey);
      queryValue.forEach((val) =>
        currentSearchParams.append(queryKey, String(val)),
      );
    } else {
      currentSearchParams.set(queryKey, String(queryValue));
    }

    const newUrl = [pathname, currentSearchParams.toString()]
      .filter(Boolean)
      .join("?");
    window.history.replaceState(null, "", newUrl);
  }, [queryKey, queryValue, pathname]);
};
