import { cx } from "$src/lib/utils";
import { motion } from "framer-motion";
import type { ComponentProps } from "react";

import styles from "./text-loading.module.css";

export type TextLoadingProps = {
  /** Lines of text to animate */
  lines: number;
  /** Overall duration of the animation, defaults to lines * 3s */
  duration?: number;
} & ComponentProps<"div">;

/**
 * @component
 * Loading state for lines of text
 */
export function TextLoading({
  lines,
  duration,
  className,
  ...props
}: TextLoadingProps) {
  const lineDuration = duration ? duration / 1000 / lines : 3;

  return (
    <div className={cx(styles.lines, className)} {...props}>
      {Array.from(Array(lines)).map((_, i) => (
        <motion.div
          className={styles.line}
          initial={{ width: 0 }}
          animate={{ width: "100%" }}
          transition={{
            duration: lineDuration,
            delay: lineDuration * i,
            ease: "linear",
          }}
          data-testid="text-loading-line"
          key={i}
        />
      ))}
    </div>
  );
}
