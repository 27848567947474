import { useToasts } from "$src/stores/useToast";
import { AnimatePresence, motion } from "framer-motion";
import { X } from "lucide-react";

import styles from "./toast.module.css";

/**
 * @component
 * toast notification component
 */
export function Toast() {
  const [toasts, dismiss] = useToasts((s) => [s.toasts, s.dismiss]);

  return (
    <div className={styles.position}>
      <AnimatePresence>
        {toasts.map((toast) => (
          <motion.div
            className={styles.container}
            key={toast.id}
            data-testid="motion-div"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.2, ease: "easeOut" }}
          >
            <div className={styles.heading}>
              <h1 className={styles.title}>
                {toast.type === "success" ? "✅" : "😳"}{" "}
                <span>{toast.title}</span>
              </h1>
              <div
                className={styles.close}
                onClick={() => dismiss(toast.id)}
                data-testid="close"
              >
                <X className={styles["close-icon"]} />
              </div>
            </div>
            {toast.message && <p className={styles.message}>{toast.message}</p>}
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}
