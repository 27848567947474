import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";

import type { BFFInput, BFFOutput } from "@tracksuit/bff/trpc";
import { sortBrands } from "@tracksuit/frontend/utils";

export const useImagery = () => {
  const accountBrand = useAccount((s) => s.active);
  const { data, isLoading } = apiQueryClient.imagery.words.useQuery(
    {
      accountBrandId: accountBrand!.accountBrandId,
    },
    { enabled: !!accountBrand },
  );

  return {
    data: data
      ?.map((brand) => ({
        ...brand,
        isAccountBrand:
          brand.brandCategoryGeographyId ===
          accountBrand?.brandCategoryGeographyId,
      }))
      .sort(sortBrands),
    loading: isLoading,
  };
};

export const useImageryBrandSummary = ({
  brandCategoryGeographyId,
  enabled,
}: {
  brandCategoryGeographyId?: number;
  enabled: boolean;
}) => {
  const accountBrand = useAccount((s) => s.active);
  const { data, isLoading, error } =
    apiQueryClient.imagery.brandSummary.useQuery(
      {
        accountBrandId: accountBrand!.accountBrandId,
        brandCategoryGeographyId: brandCategoryGeographyId!,
      },
      {
        enabled:
          !!accountBrand && brandCategoryGeographyId !== undefined && enabled,
      },
    );

  return {
    data,
    loading: isLoading,
    error,
  };
};

type ImageryBrand = NonNullable<BFFOutput["imagery"]["words"]>[0];

export const useImageryComparisonSummary = ({
  leftBrand,
  rightBrand,
  enabled,
}: {
  leftBrand?: ImageryBrand;
  rightBrand?: ImageryBrand;
  enabled: boolean;
}) => {
  const accountBrand = useAccount((s) => s.active);
  const { data, isLoading, error } =
    apiQueryClient.imagery.comparisonSummary.useQuery(
      {
        accountBrandId: accountBrand!.accountBrandId,
        accountBCGId: leftBrand?.brandCategoryGeographyId as number,
        competitorBCGId: rightBrand?.brandCategoryGeographyId as number,
      },
      {
        enabled:
          accountBrand !== undefined &&
          leftBrand !== undefined &&
          rightBrand !== undefined &&
          enabled,
      },
    );

  return {
    data,
    loading: isLoading,
    error,
  };
};

export const useImageryVerbatim = ({
  brandCategoryGeographyId,
  word,
}: Partial<BFFInput["imagery"]["verbatims"]>) => {
  const accountBrand = useAccount((s) => s.active);
  const { data, isLoading } = apiQueryClient.imagery.verbatims.useQuery(
    {
      accountBrandId: accountBrand!.accountBrandId,
      brandCategoryGeographyId: brandCategoryGeographyId!,
      word: word!,
    },
    {
      enabled:
        !!accountBrand &&
        word !== undefined &&
        brandCategoryGeographyId !== undefined,
    },
  );

  return {
    data,
    loading: isLoading,
  };
};
