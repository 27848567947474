import { cx } from "$src/lib/utils";
import type { ComponentProps, ReactNode } from "react";

import { ControlledCollapsible } from "../collapsible/collapsible";
import styles from "./floating-label.module.css";

export type FloatingLabelProps = {
  /** Label to float */
  label: string | ReactNode;
  /** Value to show */
  value?: string;
} & ComponentProps<"div">;

/**
 * @component
 * Generic floating label
 */
export function FloatingLabel({
  label,
  value,
  className,
  ...props
}: FloatingLabelProps) {
  return (
    <div
      data-testid={"floating-label"}
      className={cx(className, styles.container)}
      {...props}
    >
      <span className={cx(styles.label, !!value && styles.active)}>
        {label}
      </span>
      <ControlledCollapsible open={!!value} staggered fast>
        <span data-testid="floating-label-value" className={styles.value}>
          {value}
        </span>
      </ControlledCollapsible>
    </div>
  );
}
