import { BarChart } from "$src/components/bar-chart/bar-chart";
import { Tooltip } from "$src/components/tooltip/tooltip";
import { cx, percentageFormatter } from "$src/lib/utils";
import { ChevronDown } from "lucide-react";
import { type ComponentProps, useContext } from "react";

import type { BFFOutput } from "@tracksuit/bff/trpc";
import type {
  ConversionQuestionType,
  QuestionType,
} from "@tracksuit/frontend/schemas";

import { ConversionContext } from "../../conversion";
import { tip } from "../tooltips/tooltips";
import styles from "./brand-funnel.module.css";

type BrandFunnelProps = {
  data: NonNullable<BFFOutput["funnel"]["getMetrics"]["brands"]>[0];
} & ComponentProps<"div">;

const COLORS: { [key in QuestionType]?: string } = {
  UNPROMPTED_AWARENESS: "var(--color-unprompted-awareness)",
  PROMPTED_AWARENESS: "var(--color-prompted-awareness)",
  CONSIDERATION: "var(--color-purple-500)",
  INVESTIGATION: "var(--color-investigation)",
  USAGE: "var(--color-purple-700)",
  PREFERENCE: "var(--color-preference)",
};

/**
 * @component
 * Funnel showing conversion between stages
 */
export const BrandConversionFunnel = ({
  data,
  className,
  ...props
}: BrandFunnelProps) => {
  const { conversionTargets } = useContext(ConversionContext);
  const getMetricAttributes = (metric: any) => {
    const isConversionSource = conversionTargets?.sourceMetrics.has(
      metric.questionType as ConversionQuestionType,
    );
    const isConversionTarget = conversionTargets?.targetMetrics.has(
      metric.questionType as ConversionQuestionType,
    );
    const isConversionIntermediate = conversionTargets?.intermediateMetrics.has(
      metric.questionType as ConversionQuestionType,
    );

    const targetIndex = Array.from(
      conversionTargets?.targetMetrics ?? [],
    ).indexOf(metric.questionType as ConversionQuestionType);

    const sourceMetricType = Array.from(conversionTargets?.sourceMetrics ?? [])[
      targetIndex
    ];
    const sourceMetric = data.metrics.find(
      (m) => m.questionType === sourceMetricType,
    );

    return {
      isConversionSource,
      isConversionTarget,
      isConversionIntermediate,
      sourceMetric,
    };
  };

  return (
    <div
      className={cx(
        styles.container,
        data.isAccountBrand && styles.highlighted,
        className,
      )}
      data-testid="conversion-brand-funnel"
      key={data.brandId}
      {...props}
    >
      <div className={styles.conversions}>
        {data.metrics.map((metric, i) => {
          const {
            isConversionSource,
            isConversionTarget,
            isConversionIntermediate,
            sourceMetric,
          } = getMetricAttributes(metric);

          return (
            <div
              className={cx(
                styles["conversion-wrapper"],
                isConversionSource && styles.source,
                isConversionTarget && styles.target,
                isConversionIntermediate && styles.intermediate,
              )}
              key={i}
            >
              {isConversionSource && (
                <span className={styles["conversion-dot"]} />
              )}
              {isConversionTarget && sourceMetric && (
                <Tooltip
                  className={styles["conversion-label"]}
                  {...tip({
                    brandName: data.brandName ?? "",
                    sourceMetric:
                      sourceMetric.questionType as ConversionQuestionType,
                    targetMetric: metric.questionType as ConversionQuestionType,
                    conversion: metric.percentage / sourceMetric.percentage,
                  })}
                >
                  {percentageFormatter.format(
                    metric.percentage / sourceMetric.percentage,
                  )}
                </Tooltip>
              )}
              {isConversionTarget && i !== 0 && (
                <ChevronDown className={styles["conversion-arrow"]} />
              )}
            </div>
          );
        })}
      </div>
      {data.isAccountBrand ? (
        <span className={styles["brand-name"]}>{data.brandName}</span>
      ) : (
        <div
          className={cx(styles["brand-name"], styles.competitor)}
          data-testid="conversion-competitor"
        >
          {data.brandName}
        </div>
      )}
      {data.metrics.map(({ questionType, percentage }, i) => (
        <>
          <span className={styles.value} key={`metric-value-${i}`}>
            {percentageFormatter.format(percentage)}
          </span>
          <BarChart
            className={styles.bar}
            compact
            data={[
              {
                value: percentage,
                colors: {
                  bar: COLORS[questionType],
                },
              },
            ]}
            key={`metric-chart-${i}`}
          />
        </>
      ))}
    </div>
  );
};
