import { raygun } from "$src/lib/raygun";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

const CONFIG_FILE = "/config.json";

export type Config = Record<string, string | boolean>;
type ConfigStore = {
  config?: Config;
  ready: boolean;
  init(): void;
};

export const loadConfig = async () => {
  try {
    const config = await fetch(CONFIG_FILE).then((r) => r.json());
    return config as Config;
  } catch (err) {
    raygun?.("send", err);
  }
};

export const useConfig = createWithEqualityFn<ConfigStore>()((set) => {
  return {
    config: undefined,
    ready: false,
    init: async () => {
      const config = await loadConfig();
      set({ config, ready: true });
    },
  };
}, shallow);
