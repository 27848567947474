import { cx, percentageFormatter } from "$src/lib/utils";
import { useMeasure } from "@react-hookz/web";
import { motion } from "framer-motion";
import { type CSSProperties, type ComponentProps, useState } from "react";

import styles from "../bar-chart.module.css";

export type BarData = {
  /** Percentage value, 0 - 1 */
  value: number;
  /** Colors  */
  colors?: {
    bar?: string;
    label?: string;
    labelAlt?: string;
  };
  /** Optional custom label for the bar */
  label?: string;
  /** Optional bar click handler */
  onClick?(): void;
} & ComponentProps<"div">;

export type BarProps = {
  data: BarData;
  compact: boolean;
  loading?: boolean;
  /** Optionally reverse orientation of the bar */
  reverse?: boolean;
} & ComponentProps<"div">;

const LABEL_CUTOFF = 85;

/**
 * @component
 * Bar of bar chart
 */
export function Bar({
  data,
  compact,
  loading,
  reverse,
  className,
  style,
  ...props
}: BarProps) {
  const [animated, setAnimated] = useState(false);
  const [measurements, ref] = useMeasure<HTMLDivElement>();

  return (
    <div
      data-testid="bar-chart"
      className={cx(styles.bar, reverse && styles.reverse, className)}
      ref={ref}
      style={
        {
          "--bar-color": data.colors?.bar ?? "var(--color-purple-500)",
          "--label-color": data.colors?.label ?? "var(--color-off-white)",
          "--label-color-alt":
            data.colors?.labelAlt ??
            data.colors?.bar ??
            "var(--color-purple-500)",
          ...style,
        } as CSSProperties
      }
      {...props}
    >
      <motion.div
        className={cx(
          styles["bar-inner"],
          data.value < 0.005 && !loading && styles.empty,
        )}
        animate={{ width: `${data.value * 100}%` }}
        onAnimationComplete={() => setAnimated(true)}
        transition={{ duration: data.value }}
        data-testid="bar"
        onClick={data.onClick}
        color={data.color}
      />
      {animated && !compact && !loading && (
        <span
          data-testid="bar-chart-label"
          className={cx(
            styles.label,
            data.value > LABEL_CUTOFF / (measurements?.width ?? 0) &&
              styles.fixed,
          )}
          onClick={data.onClick}
        >
          {data.label ?? percentageFormatter.format(data.value)}
        </span>
      )}
    </div>
  );
}
