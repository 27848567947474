import { Button } from "$src/components/button/button";
import { Tag } from "$src/components/tag/tag";
import { Tooltip } from "$src/components/tooltip/tooltip";
import { useHover } from "$src/hooks/useHover";
import { cx, percentageFormatter } from "$src/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import {
  type CSSProperties,
  type ComponentProps,
  useMemo,
  useRef,
  useState,
} from "react";

import type { BFFOutput } from "@tracksuit/bff/trpc";

import { ProfileDetails } from "./profile-details/profile-details";
import { ProfileGraphic } from "./profile-graphic/profile-graphic";
import styles from "./profile.module.css";

export type Profile = BFFOutput["profile"]["get"]["profiles"]["account"][0];

export type ProfileProps = {
  /** Title of the profile item */
  title: string;
  /** Data for the item */
  data: Profile[];
} & ComponentProps<"div">;

export const COLORS = [
  "var(--color-purple-700)",
  "var(--color-purple-500)",
  "var(--color-purple-300)",
];

/**
 * @component
 * Individual profile item for profile route
 */
export const Profile = ({ title, data, className, ...props }: ProfileProps) => {
  const accountData = useMemo(
    () =>
      data
        .filter((item) => item.isAccountBrand)
        .sort((a, b) => b.percentage - a.percentage)
        .slice(0, 3),
    [data],
  );
  const [detailsOpen, setDetailsOpen] = useState(false);
  const summaryLabel = useMemo(() => {
    switch (data[0]?.filterDisplayType) {
      case "Region":
      case "Region - New Zealand":
      case "Region - Australia":
      case "Region - United States":
      case "Census division":
      case "Region - United Kingdom":
      case "Region - Canada":
      case "Region - Auckland":
        return "are from";
      case "Income":
      case "Household income":
        return "earn";
      default:
        return "are";
    }
  }, [data]);
  const filterLabel = useMemo(() => {
    switch (data[0]?.filterDisplayType) {
      case "Region":
      case "Region - New Zealand":
      case "Region - Australia":
      case "Region - United States":
      case "Census division":
      case "Region - United Kingdom":
      case "Region - Canada":
      case "Region - Auckland":
      case "Ethnicity":
        return accountData[0]?.filter;
      case "Income":
      default:
        return accountData[0]?.filter.toLocaleLowerCase();
    }
  }, [data, accountData]);
  const item = useRef<HTMLDivElement>(null);
  const isHovering = useHover(item);

  return (
    <>
      <div ref={item} className={cx(className, styles.card)} {...props}>
        <motion.div
          animate={{ opacity: isHovering ? 0 : 1 }}
          transition={{ delay: isHovering ? 0 : 0.1 }}
          className={styles["card-inner"]}
          data-testid="profile-card"
        >
          <div>
            <h2 className={styles.title}>{title}</h2>
            <span className={styles.summary}>
              {`${percentageFormatter.format(
                accountData[0]?.percentage ?? 0,
              )} ${summaryLabel} ${filterLabel}`}
            </span>
          </div>
          <div className={styles.graphic}>
            <ProfileGraphic
              type={data[0]?.filterDisplayType ?? ""}
              data={accountData}
            />
          </div>
          <div className={styles.legend}>
            {accountData.map((item, i) => (
              <Tooltip
                tip={
                  <>
                    <span>{item.filter}</span>
                    <br />
                    <strong>
                      {percentageFormatter.format(item.percentage)}
                    </strong>
                  </>
                }
                key={item.filter}
              >
                <Tag
                  color={COLORS[i]}
                  label={
                    <div
                      className={styles["legend-item"]}
                      data-testid="legend-item"
                    >
                      <span className={styles["legend-percentage"]}>
                        {percentageFormatter.format(item.percentage)}:
                      </span>
                      <span className={styles["legend-filter"]}>
                        {item.filter}
                      </span>
                    </div>
                  }
                  style={{ "--label-bg-hover": "transparent" } as CSSProperties}
                />
              </Tooltip>
            ))}
          </div>
        </motion.div>
        <AnimatePresence>
          {isHovering && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={styles.button}
            >
              <Button
                theme="primary"
                label="Show more"
                onClick={() => setDetailsOpen(true)}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <ProfileDetails
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        data={data}
      />
    </>
  );
};
