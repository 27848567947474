import { RouteSelector } from "$src/components/route-selector/route-selector";
import { StatSigExplainer } from "$src/components/stat-sig-explainer/stat-sig-explainer";
import { useValidatedParam } from "$src/hooks/useValidatedParam";
import {
  useStatementsComparison,
  useStatementsList,
} from "$src/queries/statements";
import { useFilters } from "$src/stores/useFilters";
import { Comparison } from "$src/views/comparison/comparison";
import { FilterBar } from "$src/views/filter-bar/filter-bar";
import { useCallback, useMemo, useRef } from "react";

import {
  formatDateRange,
  getComparisonPeriodLabel,
  getDatePeriod,
  getDateRangeLabel,
} from "@tracksuit/frontend/utils";

import styles from "./comparison.module.css";

export const StatementsComparisonRoute = () => {
  const { dataRepresentation, datePeriod, brandIdList } = useFilters(
    (s) => s.filters,
  );
  const { data: statementsList } = useStatementsList();
  const validStatementId = useCallback(
    (id: string) =>
      !!statementsList?.statements.some((s) => String(s.id) === id),
    [statementsList],
  );
  const defaultStatementRoute = useMemo(
    () =>
      statementsList
        ? `/statements/comparison/${statementsList?.statements[0]?.id}`
        : "",
    [statementsList],
  );
  const statementId = useValidatedParam<string>({
    param: "statementId",
    validate: validStatementId,
    fallback: defaultStatementRoute,
  });
  const comparisonRef = useRef(null);
  const { data, sufficient, loading } = useStatementsComparison();
  const activeStatement = useMemo(
    () =>
      statementsList?.statements.find(({ id }) => String(id) === statementId),
    [statementId, statementsList],
  );
  const comparisonRange = useMemo(
    () =>
      getDatePeriod(
        datePeriod.start,
        datePeriod.end,
        datePeriod.comparisonPeriod,
      ),
    [datePeriod.start, datePeriod.end, datePeriod.comparisonPeriod],
  );

  const chartData = useMemo(() => {
    const statement =
      activeStatement && data?.statements?.[activeStatement.statement];

    if (!statement) {
      return [];
    }

    const sortedData = statement.sort(
      (a, b) => Number(b.isAccountBrand) - Number(a.isAccountBrand),
    );

    const mappedData = sortedData.map((metric) => ({
      label: metric.brandName ?? "",
      differences: [metric.difference],
      metrics: [
        metric.sourceMetrics[dataRepresentation],
        metric.targetMetrics[dataRepresentation],
      ],
    }));

    return mappedData;
  }, [data, brandIdList, dataRepresentation, activeStatement]);

  return !activeStatement ? null : (
    <>
      <RouteSelector
        title="Show comparisons for:"
        pathname="/statements/comparison"
        options={statementsList!.statements.map(({ id, statement }) => ({
          id: String(id),
          label: statement,
        }))}
        selected={
          activeStatement
            ? {
                id: String(activeStatement.id),
                label: activeStatement.statement,
              }
            : undefined
        }
      />
      <FilterBar
        filters={{
          demographic: true,
          question: {
            config: {
              unprompted: false,
              category: true,
            },
          },
          brand: {
            includeCompetitorAverage: true,
            multiSelect: true,
          },
        }}
        actions={{
          copyAsImage: sufficient ? comparisonRef : undefined,
        }}
        quality={!sufficient ? undefined : data?.quality}
      />

      <Comparison
        ref={comparisonRef}
        titlePrefix={
          <span className={styles.title}>
            Comparing ‘{activeStatement?.statement}’
          </span>
        }
        data={chartData}
        showQuestion={true}
        quality={data?.quality}
        sufficient={sufficient}
        configs={[
          {
            label:
              formatDateRange(comparisonRange.start, comparisonRange.end) ?? "",
            subLabel: getComparisonPeriodLabel(datePeriod.comparisonPeriod),
            color: "var(--color-purple-200)",
            valueColor: "var(--color-purple-800)",
          },
          {
            label: formatDateRange(datePeriod.start, datePeriod.end) ?? "",
            subLabel: getDateRangeLabel(datePeriod.start, datePeriod.end, true),
            color: "var(--color-purple-500)",
          },
        ]}
        loading={loading}
      />

      {!loading && data?.quality !== "INSUFFICIENT" && (
        <StatSigExplainer className={styles["stat-sig-explainer"]} />
      )}
    </>
  );
};
