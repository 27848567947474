import { useMemo } from "react";

import { getDatePeriod } from "@tracksuit/frontend/utils";

import { useAvailableFilters } from "../queries/useAvailableFilters";
import { useFilters } from "../stores/useFilters";

export const useValidDatePeriod = () => {
  const { datePeriod } = useFilters((s) => s.filters);
  const { availableFilters } = useAvailableFilters();
  const comparisonPeriod = useMemo(
    () =>
      getDatePeriod(
        datePeriod.start,
        datePeriod.end,
        datePeriod.comparisonPeriod,
      ),
    [datePeriod],
  );
  const validDates = useMemo(
    () =>
      !!(
        datePeriod.start &&
        datePeriod.end &&
        availableFilters.dates?.includes(datePeriod.start) &&
        availableFilters.dates.includes(datePeriod.end)
      ),
    [availableFilters, datePeriod],
  );
  const validComparison = useMemo(
    () =>
      !!(
        comparisonPeriod.start &&
        comparisonPeriod.end &&
        availableFilters.dates?.includes(comparisonPeriod.start) &&
        availableFilters.dates.includes(comparisonPeriod.end)
      ),
    [availableFilters, comparisonPeriod],
  );

  return {
    dates: validDates,
    comparison: validComparison,
  };
};
