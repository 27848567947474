/* istanbul ignore file -- @preserve */
export const demographicFilterTypes = [
  "Age",
  "Gender",
  "Region",
  "Region - New Zealand",
  "Region - Australia",
  "Region - United States",
  "Census division",
  "Region - United Kingdom",
  "Region - Canda",
  "Region - Auckland",
  "Income",
  "Household Status",
  "Household status",
  "Household income",
  "Ethnicity",
  "Residence",
] as const;
export type DemographicFilterType = (typeof demographicFilterTypes)[number];
