import { useImagery } from "$src/queries/useImagery";
import { useTheme } from "$src/routes/app/routes/imagery/use-theme/useTheme";
import { useAccount } from "$src/stores/useAccount";
import { useEffect, useMemo, useState } from "react";

import type { BFFOutput } from "@tracksuit/bff/trpc";

type ImageryBrand = NonNullable<BFFOutput["imagery"]["words"]>[0] & {
  isAccountBrand: boolean;
};

export const useWords = () => {
  const { data, loading } = useImagery();
  const account = useAccount((s) => s.active);
  const brandBCGs = useMemo(
    () =>
      account
        ? [
            account?.brandCategoryGeographyId,
            ...account.competitors.map(
              ({ brandCategoryGeographyId }) => brandCategoryGeographyId,
            ),
          ].filter(Boolean)
        : [],
    [account],
  );
  const brands = useMemo<ImageryBrand[]>(() => {
    const isAccountBrand = (bcg: number) =>
      bcg === account?.brandCategoryGeographyId;

    if (!brandBCGs.length) {
      return [];
    }

    return brandBCGs.map((bcg) => ({
      isAccountBrand: isAccountBrand(bcg),
      brandName:
        (isAccountBrand(bcg)
          ? account?.brandName
          : account?.competitors.find(
              ({ brandCategoryGeographyId }) =>
                brandCategoryGeographyId === bcg,
            )?.name) ?? "",
      brandCategoryGeographyId: bcg,
      imageryWords:
        data?.find(
          ({ brandCategoryGeographyId }) => bcg === brandCategoryGeographyId,
        )?.imageryWords ?? [],
    }));
  }, [data, account, brandBCGs]);
  const [leftBrand, setLeftBrand] = useState<ImageryBrand>();
  const [rightBrand, setRightBrand] = useState<ImageryBrand>();

  const [brand, ...competitors] = useMemo(() => {
    if (!data || !brands.length || loading) {
      return [];
    }

    const idx = brands.findIndex(
      ({ brandCategoryGeographyId }) =>
        brandCategoryGeographyId === account?.brandCategoryGeographyId,
    );
    const selectedBrand = brands[idx];
    const remainingBrands = brands.slice(0, idx).concat(brands.slice(idx + 1));

    return [selectedBrand, ...remainingBrands];
  }, [brands, account]);

  useEffect(() => {
    if (leftBrand) {
      setLeftBrand(undefined);
    }
    if (rightBrand) {
      setRightBrand(undefined);
    }
  }, [account]);

  useEffect(() => {
    if (!leftBrand) {
      setLeftBrand(brand);
    }
    if (!rightBrand) {
      setRightBrand(competitors[0]);
    }
  }, [brand, competitors]);

  const [theme, toggleTheme, setTheme] = useTheme();

  const [leftWords, rightWords] = useMemo(
    () =>
      [leftBrand?.imageryWords, rightBrand?.imageryWords].map((words) =>
        words
          ?.map(({ word, ...rest }) => ({
            word,
            ...rest,
            common: [leftBrand, rightBrand].every((brand) =>
              brand?.imageryWords.some(
                ({ word: otherWord }) => word === otherWord,
              ),
            ),
          }))
          .filter(
            ({ common }) =>
              theme === "All themes" ||
              (theme === "Common themes" && common) ||
              (theme === "Unique themes" && !common),
          ),
      ),
    [leftBrand, rightBrand, theme],
  );

  return {
    brands,
    leftBrand,
    setLeftBrand,
    leftWords,
    setRightBrand,
    rightBrand,
    rightWords,
    theme,
    toggleTheme,
    setTheme,
    loading,
  };
};
