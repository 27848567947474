import { useAmplifyAuth } from "$src/hooks/useAmplifyAuth";
import { UsersView } from "$src/views/settings/lib/users-view/users-view";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { SettingsSublayout } from "./sublayout/sublayout";

export function SettingsRoute() {
  const { user } = useAmplifyAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user?.attributes["custom:isadmin"] === "N" &&
      user?.attributes["custom:client_admin"] === "N"
    ) {
      navigate("/");
    }
  }, [navigate, user?.attributes]);

  return (
    <SettingsSublayout view="users">
      <UsersView />
    </SettingsSublayout>
  );
}
