import type { ReactNode } from "react";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

export type Toast = {
  id: string;
  type: "success" | "error";
  title: string;
  message?: ReactNode;
};

type ToastStore = {
  toasts: Toast[];
  add(type: Toast["type"], opts: Omit<Toast, "type">): void;
  dismiss(id: string): void;
};

export const useToasts = createWithEqualityFn<ToastStore>()((set) => {
  const removeToast = (id: string) =>
    set((s) => ({ toasts: s.toasts.filter((toast) => toast.id !== id) }));
  return {
    toasts: [] as Toast[],
    add: (type, { id, title, message }) => {
      set(({ toasts }) => ({
        toasts: [...toasts, { id, type, title, message }],
      }));
      setTimeout(() => removeToast(id), 5000);
    },
    dismiss: removeToast,
  };
}, shallow);
