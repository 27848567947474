import { Button } from "$src/components/button/button";
import { ControlledCollapsible } from "$src/components/collapsible/collapsible";
import { Input } from "$src/components/input/input";
import { AMPLIFY_AUTH_ERROR_CODE } from "$src/lib/consts";
import { raygun } from "$src/lib/raygun";
import { Auth } from "aws-amplify";
import { type FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./routes.module.css";

const INFO = {
  submitted: "You should have received an email from us...",
  unsubmitted:
    "Enter your email address and we'll send you a code to reset your password.",
};

const ERRORS = {
  400: (
    <span>
      🕵️‍♀️ Uh oh! We couldn’t find you.
      <br />
      Check your email and password and try again
    </span>
  ),
  500: (
    <span>
      Something went wrong. Try again or reach out to our{" "}
      <a href="https://help.gotracksuit.com/">Customer Team</a>.
    </span>
  ),
};

export function ForgotPasswordRoute() {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<keyof typeof ERRORS | null>(null);
  const navigate = useNavigate();

  async function submit(e: FormEvent) {
    e.preventDefault();
    try {
      if (submitted) {
        await Auth.forgotPasswordSubmit(email, code, newPassword);
        navigate("/login");
      } else {
        await Auth.forgotPassword(email);
        setSubmitted(true);
      }
    } catch (err) {
      if ((err as any).code === AMPLIFY_AUTH_ERROR_CODE) {
        setError(500);
        console.error(err);
        raygun?.("send", err);
      } else {
        setError(400);
      }
    }
  }

  return (
    <form onSubmit={submit}>
      <ControlledCollapsible open={!!error} fast>
        <div className={styles.error}>{error && ERRORS[error]}</div>
      </ControlledCollapsible>

      <div className={styles.info}>
        {INFO[submitted ? "submitted" : "unsubmitted"]}
      </div>

      <Input
        className={styles.input}
        type={submitted ? "text" : "email"}
        label={submitted ? "Confirmation code" : "Email"}
        value={submitted ? code : email}
        required
        onChange={({ target }) => {
          !!error && setError(null);
          if (submitted) {
            setCode(target.value);
          } else {
            setEmail(target.value);
          }
        }}
      />

      {submitted && (
        <Input
          className={styles.input}
          type="password"
          label="New password"
          value={newPassword}
          minLength={8}
          required
          onChange={({ target }) => {
            !!error && setError(null);
            setNewPassword(target.value);
          }}
        />
      )}

      <div className={styles.actions}>
        <Button
          className={styles.action}
          theme="ghost"
          href="/login"
          label="Sign in"
        />
        <Button
          className={styles.action}
          type="submit"
          label={submitted ? "Set new password" : "Send reset code"}
        />
      </div>
    </form>
  );
}
