import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import { AnalyticsEvents, getAnalytics } from "../hooks/useAnalytics";
import { useAccount } from "./useAccount";

export type HiddenItemType = "brands" | "metrics" | "statements";
type HiddenItem = {
  id: string;
  label: string;
};

type HiddenItemsStore = {
  hidden: {
    [type in HiddenItemType]: HiddenItem[];
  };
  add(type: HiddenItemType, item: HiddenItem): void;
  remove(type: HiddenItemType, item: HiddenItem): void;
  reset(): void;
};

export const useHiddenItems = createWithEqualityFn<HiddenItemsStore>()(
  (set) => {
    return {
      hidden: {
        brands: [],
        metrics: [],
        statements: [],
      },
      reset: () =>
        set({
          hidden: {
            brands: [],
            metrics: [],
            statements: [],
          },
        }),
      add: (type, item) => {
        set((s) => ({
          hidden: {
            ...s.hidden,
            [type]: [...s.hidden[type], item],
          },
        }));
        getAnalytics(useAccount.getState().active)?.track(
          AnalyticsEvents.HideItem,
          {
            type,
            item: item.label,
          },
        );
      },
      remove: (type, item) => {
        set((s) => {
          return {
            hidden: {
              ...s.hidden,
              [type]: s.hidden[type].filter(({ id }) => id !== item.id),
            },
          };
        });
        getAnalytics(useAccount.getState().active)?.track(
          AnalyticsEvents.ShowItem,
          {
            type,
            item: item.label,
          },
        );
      },
    };
  },
  shallow,
);
