import { CopyButton } from "$src/components/copy-button/copy-button";
import {
  type BrandFilterProps,
  FilterBrands,
} from "$src/components/filters/brand/brand";
import {
  FilterDates,
  type FilterDatesProps,
} from "$src/components/filters/date/date";
import { FilterDemographics } from "$src/components/filters/demographics/demographics";
import {
  type FilterQuestionProps,
  FilterQuestions,
} from "$src/components/filters/question/question";
import { IconButton } from "$src/components/icon-button/icon-button";
import { cx } from "$src/lib/utils";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { DownloadIcon, Sparkles } from "lucide-react";
import { type ComponentProps, type ReactNode, type RefObject } from "react";

import type { SampleQuality } from "@tracksuit/frontend/schemas";

import { SampleIndicatorTip } from "../sample-indicator/sample-indicator";
import styles from "./filter-bar.module.css";

export type FilterBarProps = {
  /** Sample quality */
  quality?: SampleQuality;
  /** Which filters should be displayed */
  filters?: {
    demographic?:
      | boolean
      | {
          useIds?: boolean;
          hideEmptyPopFilters?: boolean;
        };
    date?: boolean | Pick<FilterDatesProps, "type">;
    question?: boolean | Pick<FilterQuestionProps, "config" | "multiselect">;
    brand?: boolean | BrandFilterProps;
  };
  /** Which actions should be displayed */
  actions?: {
    summarise?: () => void;
    copyAsImage?: RefObject<HTMLDivElement>;
    download?: () => void;
  };
  /** Render custom items in filter area */
  customFilters?: ReactNode;
  /** Render custom items in action area */
  customActions?: ReactNode;
  /** Whether to disable all actions */
  actionsDisabled?: boolean;
} & ComponentProps<"div">;

/**
 * @component
 * Configurable UI filter bar used on most pages
 */
export const FilterBar = ({
  quality,
  filters = {},
  actions = {},
  customFilters,
  customActions,
  actionsDisabled = false,
  className,
  ...props
}: FilterBarProps) => {
  const { loading } = useAvailableFilters();

  return (
    <div className={cx(styles.bar, className)} {...props}>
      <div className={styles.filters}>
        {filters.date && (
          <FilterDates
            loading={loading}
            className={styles.filter}
            quality={quality}
            type={
              typeof filters.date === "object" ? filters.date.type : "period"
            }
          />
        )}
        {filters.demographic && (
          <FilterDemographics
            loading={loading}
            className={styles.filter}
            quality={quality}
            hideEmptyPopFilters={
              typeof filters.demographic === "object"
                ? filters.demographic.hideEmptyPopFilters
                : false
            }
          />
        )}
        {filters.question && (
          <FilterQuestions
            loading={loading}
            className={styles.filter}
            quality={quality}
            {...(typeof filters.question === "object" ? filters.question : {})}
          />
        )}
        {filters.brand && (
          <FilterBrands
            {...(typeof filters.brand === "object" ? filters.brand : {})}
            loading={loading}
            className={styles.filter}
          />
        )}
        {customFilters}
        {quality && <SampleIndicatorTip quality={quality} />}
      </div>

      <div className={styles.actions}>
        {actions.copyAsImage && (
          <CopyButton
            imageAreaElement={actions.copyAsImage}
            disabled={loading || actionsDisabled || quality === "INSUFFICIENT"}
          />
        )}
        {actions.summarise && (
          <IconButton
            icon={Sparkles}
            tip="Summarize"
            onClick={actions.summarise}
            disabled={loading || actionsDisabled || quality === "INSUFFICIENT"}
          />
        )}

        {actions.download && (
          <IconButton
            icon={DownloadIcon}
            tip="Download"
            onClick={actions.download}
            disabled={loading || actionsDisabled || quality === "INSUFFICIENT"}
          />
        )}
        {customActions}
      </div>
    </div>
  );
};
