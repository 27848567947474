import { ControlledCollapsible } from "$src/components/collapsible/collapsible";
import { Tooltip } from "$src/components/tooltip/tooltip";
import { cx } from "$src/lib/utils";
import { sentenceCase } from "change-case";

import type { SampleQuality } from "@tracksuit/frontend/schemas";

import styles from "./sample-indicator.module.css";

type SampleIndicatorTipProps = {
  quality?: SampleQuality;
  className?: string;
};

type SampleIndicatorProps = SampleIndicatorTipProps & {
  context: "demographics" | "date" | "question";
};

const WARNINGS: {
  [key in SampleIndicatorProps["context"]]: {
    [key in SampleQuality]: string;
  };
} = {
  demographics: {
    INSUFFICIENT: `The filters you have selected are too granular. Try selecting additional demographics or extending the date range.`,
    INDICATIVE: `These results are indications. You can select additional demographics or extend the date range to make this an even stronger sample`,
    STRONG: ``,
  },
  date: {
    INSUFFICIENT: `The filters you have selected are too granular. Try extending the date range or selecting additional demographics.`,
    INDICATIVE: `These results are indications. You can extend the date range or select additional demographics to make this an even stronger sample`,
    STRONG: ``,
  },
  question: {
    INSUFFICIENT: `The filters you have selected are too granular. You can make this sample larger by selecting additional demographics or extending the date range.`,
    INDICATIVE: `These results are indications. You can select additional demographics, extend the date range, or select a different metric to make this an even stronger sample`,
    STRONG: ``,
  },
};

export function SampleIndicator({
  quality,
  context,
  className,
}: SampleIndicatorProps) {
  return (
    <ControlledCollapsible open={!!quality && quality !== "STRONG"}>
      <div
        className={cx(
          styles.sample,
          context !== "demographics" && styles["with-border"],
          styles[quality?.toLowerCase() ?? ""],
          className,
        )}
      >
        <h2 className={styles["sample-heading"]}>
          {quality ? `${sentenceCase(quality)} Sample Size` : ""}
        </h2>
        <p className={styles["sample-text"]}>
          {quality && WARNINGS[context][quality]}
        </p>
      </div>
    </ControlledCollapsible>
  );
}

export function SampleIndicatorTip({
  quality,
  className,
}: SampleIndicatorTipProps) {
  return !!quality && quality !== "STRONG" ? (
    <Tooltip
      tip={
        quality && (
          <span className={styles["tip-text"]}>
            {WARNINGS.demographics[quality]}
          </span>
        )
      }
      placement="right"
      className={cx(className)}
    >
      <span className={cx(styles.tip, styles[quality?.toLowerCase()])}>
        {sentenceCase(quality)}
      </span>
    </Tooltip>
  ) : null;
}
