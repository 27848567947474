import { RadialBarChart } from "$src/components/radial-bar-chart/radial-bar-chart";
import { Tooltip } from "$src/components/tooltip/tooltip";
import { cx, percentageFormatter } from "$src/lib/utils";
import { useFunnel } from "$src/queries/funnel";
import { type ComponentProps, type ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

import styles from "./penetration-chart.module.css";

export type PenetrationChartProps = {
  /** Label of the chart */
  label: string;
  /** Toooltip title */
  title?: string;
  /** Tooltip on the label */
  tip?: ReactNode;
} & ComponentProps<"div">;

/**
 * @component
 * Chart for category penetration
 */
export const PenetrationChart = ({
  className,
  label,
  tip,
  title,
  ...props
}: PenetrationChartProps) => {
  const { data, loading } = useFunnel();
  const { percentage } = data?.category ?? {};

  return (
    <div className={cx(className, styles.chart)} {...props}>
      <RadialBarChart size={230} barWidth={34} value={percentage ?? 0} />
      <Tooltip
        disabled={loading}
        className={styles.bubble}
        title={title}
        tip={tip}
      >
        <span className={styles.percentage}>
          {loading ? (
            <Skeleton width="3ch" baseColor="var(--color-green-200)" />
          ) : (
            percentageFormatter.format(percentage ?? 0)
          )}
        </span>
        <span className={styles.label}>{label}</span>
      </Tooltip>
    </div>
  );
};
