import { ControlledCollapsible } from "$src/components/collapsible/collapsible";
import { cx } from "$src/lib/utils";
import { useAccount } from "$src/stores/useAccount";
import { type ComponentPropsWithoutRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import styles from "./brand-perception-explanation.module.css";

export function BrandPerceptionExplanation({
  brandName,
  loading,
}: {
  brandName: string;
  loading: boolean;
}) {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const accountBrand = useAccount((s) => s.active);

  return (
    <ControlledCollapsible
      open={detailsOpen}
      triggerAlignment="center"
      onTrigger={() => setDetailsOpen((s) => !s)}
      className={styles.details}
      trigger={<h3 className={styles.subheader}>How the brand is perceived</h3>}
    >
      <p className={cx(styles.intro, styles.lineWidthLimit)}>
        The Imagery question gives you a qualitative assessment of how your
        brand is perceived. This section of the survey is only shown to
        respondents that are aware of a brand.
      </p>
      <ControlledCollapsible.Card
        heading="How is this asked in the survey?"
        className={styles.card}
      >
        <p className={styles.lineWidthLimit}>
          Thinking about{" "}
          {loading ? (
            <LoadingLabel inline={true} />
          ) : (
            accountBrand?.category.name
          )}
          , what comes to mind when you think about{" "}
          {loading ? <LoadingLabel inline={true} /> : brandName}?
        </p>
        <p className={styles.emphasised}>Open text field</p>
      </ControlledCollapsible.Card>
      <p
        className={cx(
          styles.intro,
          styles["color-light-text"],
          styles.lineWidthLimit,
        )}
      >
        Select a bubble to view a range of raw responses
      </p>
    </ControlledCollapsible>
  );
}

const LoadingLabel = (props: ComponentPropsWithoutRef<typeof Skeleton>) => (
  <Skeleton width="10ch" {...props} />
);
