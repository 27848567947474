import { Button } from "$src/components/button/button";
import { ControlledCollapsible } from "$src/components/collapsible/collapsible";
import { DatePicker } from "$src/components/date-picker/date-picker";
import {
  ControlledDropdown,
  DropdownItem,
} from "$src/components/dropdown/dropdown";
import { Input } from "$src/components/input/input";
import { Modal } from "$src/components/modal/modal";
import { Textarea } from "$src/components/textarea/textarea";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { useMutateMilestones } from "$src/queries/useMilestones";
import { useAccount } from "$src/stores/useAccount";
import { useDeepCompareEffect } from "@react-hookz/web";
import { format, isAfter } from "date-fns";
import { type ComponentProps, useEffect, useRef, useState } from "react";
import type { z } from "zod";

import type { date } from "@tracksuit/frontend/schemas";
import { toDate } from "@tracksuit/frontend/utils";

import type { Milestone } from "../../milestones";
import styles from "./milestone-editor.module.css";

export type MilestoneEditorProps = {
  /** Whether editor is open */
  open: boolean;
  /** Callback on close */
  onClose(): void;
  /** Data of the milestone if editing existing */
  milestone?: Milestone;
} & ComponentProps<"div">;

/**
 * @component
 * Editor UI for milestones
 */
export const MilestoneEditor = ({
  open,
  onClose,
  milestone,
}: MilestoneEditorProps) => {
  const [type, setType] = useState<NonNullable<Milestone["type"]>>("CAMPAIGN");
  const account = useAccount((s) => s.active);
  const { updateMilestone, createMilestone, deleteMilestone } =
    useMutateMilestones();
  const [typeOpen, setTypeOpen] = useState(false);
  const [brand, setBrand] = useState<{ id: number; name: string } | null>();
  const [brandOpen, setBrandOpen] = useState(false);
  const [startDate, setStartDate] = useState<z.infer<typeof date>>();
  const [endDate, setEndDate] = useState<z.infer<typeof date>>();
  const [description, setDescription] = useState<string>();
  const [channel, setChannel] = useState<string>();
  const [cost, setCost] = useState<number>();
  const form = useRef<HTMLFormElement>(null);
  const [busy, setBusy] = useState(false);
  const [valid, setValid] = useState(false);
  const analytics = useAnalytics();
  const { availableFilters } = useAvailableFilters();

  const onCancel = () => {
    form.current?.reset();
    setStartDate(undefined);
    setEndDate(undefined);
    setBrand(null);
    onClose();
  };

  useEffect(() => {
    if (open) {
      setType(milestone?.type ?? "CAMPAIGN");
      setBrand(
        milestone?.brand
          ? {
              id: Number(milestone.brand.brandId),
              name: milestone.brand.brandName ?? "",
            }
          : undefined,
      );
      setStartDate(milestone ? milestone.startDate : undefined);
      setEndDate(milestone?.endDate ? milestone.endDate : undefined);
      setDescription(milestone?.description ?? "");
      setChannel(milestone?.channel ?? undefined);
      setCost(milestone?.cost ?? undefined);
    }
  }, [open, milestone]);

  // v8 ignore next
  const onSave = () => {
    if (!valid) {
      return;
    }

    const newMilestone = {
      milestoneId: Number(milestone?.id),
      accountBrandId: Number(account?.accountBrandId),
      type,
      brandId: Number(brand?.id),
      categoryEvent: !brand,
      startDate: startDate!,
      endDate,
      description: description!,
      channel,
      cost,
      visibility: true,
    };

    setBusy(true);
    if (milestone) {
      updateMilestone.mutate(newMilestone);
    } else {
      createMilestone.mutate(newMilestone);
    }

    analytics?.track(
      milestone ? AnalyticsEvents.EditMilestone : AnalyticsEvents.AddMilestone,
      newMilestone,
    );
    setBusy(false);
    form.current?.reset();
    onClose();
  };

  // v8 ignore next
  const onDelete = async () => {
    setBusy(true);
    await deleteMilestone.mutate({
      accountBrandId: account!.accountBrandId,
      milestoneId: milestone!.id,
    });

    analytics?.track(AnalyticsEvents.DeleteMilestone, { milestone });
    setBusy(false);
    onClose();
  };

  useEffect(() => {
    setValid(
      typeof brand !== "undefined" && !!description && !!type && !!startDate,
    );
  }, [brand, description, startDate, type]);

  useDeepCompareEffect(() => {
    if (type === "CAMPAIGN" && brand === null) {
      setBrand(undefined);
    }
  }, [type, brand]);

  return (
    <Modal
      heading={`${milestone ? "Edit" : "Add a"} milestone`}
      open={open}
      onClose={onCancel}
    >
      <p className={styles.info}>
        Record the details of your milestone and the brand it relates to by
        providing the following information:
      </p>
      <form ref={form} className={styles.form}>
        <fieldset className={styles.fieldset}>
          <ControlledDropdown
            theme="select"
            label="Milestone Type"
            selected={type === "CAMPAIGN" ? "Brand activity" : "Macro event"}
            open={typeOpen}
            onChange={setTypeOpen as any}
          >
            <DropdownItem
              id="milestone-type"
              active={type === "CAMPAIGN"}
              onClick={() => {
                // v8 ignore next
                setTypeOpen(false);
                // v8 ignore next
                setType("CAMPAIGN");
              }}
            >
              Brand activity
            </DropdownItem>
            <DropdownItem
              id="milestone-type"
              active={type === "MACRO"}
              onClick={() => {
                // v8 ignore next
                setTypeOpen(false);
                setType("MACRO");
              }}
            >
              Macro event
            </DropdownItem>
          </ControlledDropdown>

          <ControlledDropdown
            theme="select"
            label="Brand"
            selected={brand === null ? "All Brands" : brand?.name}
            open={brandOpen}
            onChange={setBrandOpen as any}
            maxWidth="var(--size-60)"
          >
            {type === "MACRO" && (
              <DropdownItem
                id="milestone-brand"
                active={brand === null}
                onClick={() => {
                  // v8 ignore next
                  setBrandOpen(false);
                  // v8 ignore next
                  setBrand(null);
                }}
                key={"all-brands"}
              >
                All Brands
              </DropdownItem>
            )}
            {availableFilters.brands?.map((b) => (
              <DropdownItem
                id="milestone-brand"
                active={b?.id === brand?.id}
                onClick={() => {
                  // v8 ignore next
                  setBrandOpen(false);
                  // v8 ignore next
                  setBrand({ id: Number(b.id), name: String(b.name) });
                }}
                key={(b as any).brandCategoryGeographyId}
              >
                {b.name}
              </DropdownItem>
            ))}
          </ControlledDropdown>
        </fieldset>

        <Textarea
          label="Description"
          name="description"
          required
          value={description}
          onChange={({ target }) => setDescription(target.value)}
        />

        <DatePicker
          range={type === "CAMPAIGN"}
          startDate={startDate ? toDate(startDate) : undefined}
          endDate={endDate ? toDate(endDate) : undefined}
          onChange={({ start, end }) => {
            start && setStartDate(format(start, "yyyy-MM-dd"));
            end && setEndDate(format(end, "yyyy-MM-dd"));
          }}
        />
        {type === "CAMPAIGN" && (
          <fieldset className={styles.fieldset}>
            <Input
              label="Channel"
              name="channel"
              value={channel}
              onChange={({ target }) => setChannel(target.value)}
            />
            <Input
              label="Cost"
              name="cost"
              type="number"
              value={cost}
              onChange={({ target }) => setCost(Number(target.value))}
            />
          </fieldset>
        )}
      </form>
      <ControlledCollapsible
        open={
          !!startDate &&
          isAfter(
            toDate(startDate),
            toDate(availableFilters.dates?.[0] ?? new Date()),
          )
        }
      >
        <span className={styles.warning}>
          This start-date is in the future — you can still record this, but it
          won&apos;t display in the timeline until this date’s data becomes
          available.
        </span>
      </ControlledCollapsible>
      <div className={styles.actions}>
        <Button
          theme="ghost"
          onClick={onCancel}
          label="Cancel"
          disabled={busy}
        />
        {milestone && (
          <Button
            theme="secondary"
            onClick={onDelete}
            label="Delete"
            disabled={busy}
          />
        )}
        <Button
          onClick={onSave}
          label={milestone ? "Save" : "Add"}
          disabled={busy || !valid}
        />
      </div>
    </Modal>
  );
};
