import { apiQueryClient } from "../../lib/api";
import { useAccount } from "../../stores/useAccount";
import { useAvailableFilters } from "../useAvailableFilters";

export const useTldrStats = () => {
  const account = useAccount((s) => s.active);
  const { availableFilters } = useAvailableFilters();

  const { data, isLoading: loading } = apiQueryClient.tldr.getStats.useQuery(
    {
      categoryId: account!.category.id,
      geographyId: account!.geography.id,
      accountStartDate: availableFilters.dates?.[
        availableFilters.dates?.length - 1
      ] as string,
      waveStartDate: availableFilters.dates?.[5] as string,
      waveEndDate: availableFilters.dates?.[0] as string,
    },
    {
      enabled:
        !!account &&
        availableFilters.dates &&
        availableFilters.dates.length > 5,
    },
  );

  return {
    data,
    loading,
  };
};
