import { api } from "$src/lib/api";
import { useStatementsMetrics } from "$src/queries/statements";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { Download } from "$src/views/download/download";
import { FilterBar } from "$src/views/filter-bar/filter-bar";
import { Statements } from "$src/views/statements/statements";
import { useRef, useState } from "react";

import type { StatementsQuestionType } from "@tracksuit/frontend/schemas";

export const StatementsOverviewRoute = () => {
  const { demographics, datePeriod, question } = useFilters((s) => s.filters);
  const account = useAccount((s) => s.active);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const statementsRef = useRef(null);

  const { data } = useStatementsMetrics();

  const getExportUrl = async () => {
    if (!account || !datePeriod.start || !datePeriod.end) {
      throw new Error("Account or date range not set");
    }

    const res = await api.exports.statements.query({
      accountBrandId: account.accountBrandId,
      demographicFilter: demographics.map(({ filter }) => filter),
      demographicFilterIds: demographics.map(({ id }) => id),
      waveStartDate: datePeriod.start,
      waveEndDate: datePeriod.end,
      format: "XLSX",
      adjustedBy: question as StatementsQuestionType,
    });

    return res?.exportUrl;
  };

  return (
    <>
      <FilterBar
        quality={data?.quality}
        filters={{
          demographic: true,
          date: {
            type: "period",
          },
          brand: {
            multiSelect: true,
            includeCompetitorAverage: true,
          },
          question: {
            config: {
              category: true,
              investigation: true,
              unprompted: false,
            },
          },
        }}
        actions={{
          download: () => setDownloadOpen(true),
          copyAsImage: statementsRef,
        }}
      />
      <Statements ref={statementsRef} />
      <Download
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
        fetcher={getExportUrl}
        heading="Downloading statements"
        label="Statements"
        format="XLSX"
        stonly="exporting-your-statements-results-ah4LuSvYHi"
      >
        <p>This is currently only available in Excel format.</p>
      </Download>
    </>
  );
};
