import { cx } from "$src/lib/utils";
import mixins from "$src/styles/mixins.module.css";
import type { ComponentProps } from "react";

import styles from "./layout.module.css";

/**
 * @component
 * Base page layout
 */
export const Layout = ({
  className,
  children,
  ...props
}: ComponentProps<"div">) => {
  return (
    <div
      className={cx(styles.layout, mixins["grid-page"], className)}
      {...props}
    >
      {children}
    </div>
  );
};
