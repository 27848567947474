import { kebabCase } from "change-case";
import { ReactNode, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ControlledDropdown,
  DropdownItem,
  DropdownSection,
} from "../dropdown/dropdown";
import styles from "./route-selector.module.css";

type Option = { id: string; label: string };
export type RouteSelectorProps = {
  title: string;
  label?: string;
  sectionTitle?: string;
  pathname: string;
  selected?: Option;
  options: Option[];
  children?: ReactNode;
};

export const RouteSelector = ({
  title,
  label,
  sectionTitle,
  pathname,
  options,
  selected,
  children,
}: RouteSelectorProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const WrapperEl = useMemo(
    () => (sectionTitle ? DropdownSection : "div"),
    [sectionTitle],
  );

  return (
    <h1 className={styles.heading}>
      {title}
      <ControlledDropdown
        theme="inline"
        open={open}
        onChange={setOpen}
        selected={label ?? selected?.label}
        maxWidth="40ch"
      >
        {children}
        <WrapperEl label={sectionTitle}>
          {options.map(({ id, label }) => (
            <DropdownItem
              id="question-select"
              onClick={() => {
                navigate(`${pathname}/${kebabCase(id)}`);
                setOpen(false);
              }}
              active={selected?.id === id}
              key={id}
            >
              {label}
            </DropdownItem>
          ))}
        </WrapperEl>
      </ControlledDropdown>
    </h1>
  );
};
