import { useFocus } from "$src/hooks/useFocus";
import { cx } from "$src/lib/utils";
import { X } from "lucide-react";
import { type ComponentProps, useEffect, useRef, useState } from "react";

import { ControlledCollapsible } from "../collapsible/collapsible";
import styles from "./input.module.css";

export type InputProps = {
  /** Label for the input */
  label: string;
  /** Whether the input is invalid */
  invalid?: boolean;
  /** Optional error on the input */
  errorMessage?: string | null;
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Optional extra info text for the field */
  info?: string;
  /** Whether the input is clearable */
  clearable?: boolean;
  /** Icon */
  icon?: any;
  /** Callback on clear */
  onClear?(): void;
} & ComponentProps<"input">;

/**
 * @component
 * Input with floating label
 */
export function Input({
  label,
  invalid,
  errorMessage,
  disabled,
  info,
  clearable,
  onClear,
  value,
  onChange,
  className,
  icon: Icon,
  ...props
}: InputProps) {
  const input = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<any>(value);
  const isFocused = useFocus(input);
  const [active, setActive] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    setActive(!!inputValue || isFocused);
  }, [inputValue, isFocused, input]);

  useEffect(() => {
    setIsInvalid(invalid ?? false);
    if (value && String(value).length > 0) {
      setIsInvalid(false);
    }
  }, [invalid, value]);

  return (
    <div className={className}>
      <div
        data-testid="input-wrapper"
        className={cx(
          styles.input,
          disabled && styles.disabled,
          active && styles.active,
          (isInvalid ?? errorMessage) && styles.error,
        )}
        onClick={() => input.current?.focus()}
      >
        <label className={styles["input-inner"]}>
          <span className={styles.label}>{label}</span>
          <ControlledCollapsible open={active} fast staggered>
            <input
              ref={input}
              className={styles["input-field"]}
              value={value}
              disabled={disabled}
              onChange={(e) => {
                setInputValue(e.target.value);
                onChange?.(e);
              }}
              {...props}
            />
          </ControlledCollapsible>
        </label>
        {clearable && !isInvalid && !!inputValue ? (
          <X
            className={styles.icon}
            data-testid="input-clear"
            onClick={() => {
              setInputValue("");
              onClear?.();
            }}
          />
        ) : (
          Icon && <Icon className={styles.icon} data-testid="input-icon" />
        )}
      </div>
      {info && (
        <div className={cx(styles.info, disabled && styles["disabled-info"])}>
          {info}
        </div>
      )}
      {errorMessage && isInvalid && (
        <div className={styles["error-message"]}>{errorMessage}</div>
      )}
    </div>
  );
}
