import { Button } from "$src/components/button/button";
import { Input } from "$src/components/input/input";
import { useAmplifyAuth } from "$src/hooks/useAmplifyAuth";
import {
  AMPLIFY_MFA_ENABLE_ERROR_CODE,
  AMPLIFY_MFA_INVALID_PARAM_ERROR_CODE,
} from "$src/lib/consts";
import { raygun } from "$src/lib/raygun";
import { Auth } from "aws-amplify";
import { useFlags } from "launchdarkly-react-client-sdk";
import QRCode from "qrcode";
import { type FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./mfa.module.css";

export function MfaSettingsRoute() {
  const { user } = useAmplifyAuth();
  const navigate = useNavigate();

  const [totpSecretCode, setTotpSecretCode] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [code, setCode] = useState<string>(); // Changing it to string to allow code that starts with 0
  const [busy, setBusy] = useState(false);
  const [isMFAEnabled, setIsMFAEnabled] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function checkMFAStatus() {
      if (user) {
        const mfaStatus = await Auth.getPreferredMFA(user);
        setIsMFAEnabled(mfaStatus === "SOFTWARE_TOKEN_MFA");
      }
    }
    checkMFAStatus();
  }, [user]);

  useEffect(() => {
    async function setupMFA() {
      const totpSetupDetails = await Auth.setupTOTP(user);
      setSecretKey(totpSetupDetails);
      const qrCodeString = `otpauth://totp/Tracksuit: ${user?.attributes.email?.toLowerCase()}?secret=${totpSetupDetails}`;
      const qrCode = await QRCode.toDataURL(qrCodeString);
      setTotpSecretCode(qrCode);
    }

    if (user && !isMFAEnabled) {
      setupMFA();
    }
  }, [user]);

  async function verifyMFA(e: FormEvent) {
    e.preventDefault();
    if (user && code) {
      setBusy(true);
      try {
        await Auth.verifyTotpToken(user, code);
        await Auth.setPreferredMFA(user, "SOFTWARE_TOKEN_MFA");
        setBusy(false);
      } catch (err: any) {
        if (err.code === AMPLIFY_MFA_ENABLE_ERROR_CODE) {
          setError("The code you entered is not valid. Please try again.");
        } else if (err.code === AMPLIFY_MFA_INVALID_PARAM_ERROR_CODE) {
          setError("The code must be 6-digits long. Please try again.");
        } else {
          setError(
            "There were some problems verifying your code. Please try again.",
          );
          raygun?.("send", err);
        }
        setBusy(false);
        console.error(err);
      }
    }
  }

  const updateCode = (e: FormEvent<HTMLInputElement>) => {
    setError(null);
    setCode(e.currentTarget.value.replace(/\D/g, ""));
  };

  const { mfa } = useFlags();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!mfa && pathname === "/settings/mfa") {
      navigate("/settings");
    }
  }, [mfa, pathname, navigate]);

  return (
    <div>
      <div className={styles.container}>
        {!isMFAEnabled ? (
          <form onSubmit={verifyMFA}>
            <h2 className={styles.title}>Two-factor authentication</h2>
            <div className={styles.nav}>
              <h3 className={styles.subheading}>
                Two-factor authentication is an extra layer of security used to
                ensure the security of online accounts. Download the free
                “Google Authenticator” app on your mobile device from the Play
                store or App store.
              </h3>
              <h3 className={styles.subheading}>
                Open the Google Authenticator app and scan the QR code
              </h3>
              <div className={styles.qrcode}>
                <img
                  className="block w-64 h-64 object-contain"
                  src={totpSecretCode}
                  alt="qrcode url"
                />
              </div>
            </div>
            <div className={styles.nav}>
              <h3 className={styles.subheading}>
                Or manually enter the key below into your authenticator app
              </h3>
              <div className={styles.text}>{secretKey}</div>
            </div>

            <div className={styles.nav}>
              <h3 className={styles.subheading}>Enter verification code</h3>
              <div className={styles.text}>
                <Input
                  label=""
                  value={code}
                  minLength={6}
                  maxLength={6}
                  required={true}
                  invalid={!!error}
                  onChange={updateCode}
                  errorMessage={error}
                />
              </div>
            </div>
            <Button type="submit" label="Complete setup" disabled={busy} />
          </form>
        ) : (
          <>
            <h2 className={styles.title}>Two-factor authentication</h2>
            <div className={styles.nav}>
              <h3 className={styles.subheading}>
                If you turn off two-factor authentication, your account will be
                protected only with your password.
              </h3>
            </div>
            <Button
              onClick={() => Auth.setPreferredMFA(user, "NOMFA")}
              label="Turn off"
              disabled={busy}
            />
          </>
        )}
      </div>
    </div>
  );
}
