import { z } from "zod";

import { date } from "./date";
import { signedPercentage } from "./numbers";

export const difference = z.object({
  percentage: signedPercentage,
  isSignificant: z.boolean(),
});

export const differenceMetric = z.object({
  percentage: signedPercentage,
  population: z.number(),
  waveStartDate: date,
  waveEndDate: date,
});
