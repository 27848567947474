import { UsersView } from "$src/views/settings/lib/users-view/users-view";

import { SettingsSublayout } from "../../sublayout/sublayout";

export function UsersSettingsRoute() {
  return (
    <SettingsSublayout view="users">
      <UsersView />
    </SettingsSublayout>
  );
}
