import { Button } from "$src/components/button/button";
import { ControlledCollapsible } from "$src/components/collapsible/collapsible";
import { Input } from "$src/components/input/input";
import {
  AMPLIFY_AUTH_ERROR_CODE,
  AMPLIFY_MFA_CODE_MISMATCH,
} from "$src/lib/consts";
import { raygun } from "$src/lib/raygun";
import { Auth } from "@aws-amplify/auth";
import { type FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./routes.module.css";

const ERRORS = {
  400: (
    <span>
      🕵️‍♀️ Uh oh! We couldn’t find you.
      <br />
      Check your email and password and try again
    </span>
  ),
  500: (
    <span>
      Something went wrong. Try again or reach out to our{" "}
      <a href="https://help.gotracksuit.com/">Customer Team</a>.
    </span>
  ),
  MFA_CODE_MISMATCH: <span>Invalid code</span>,
};

export function LoginRoute() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showMFA, setShowMFA] = useState(false);
  const [code, setCode] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState<keyof typeof ERRORS | null>(null);
  const navigate = useNavigate();

  async function login(e: FormEvent) {
    e.preventDefault();
    try {
      const user = await Auth.signIn(email, password);
      setCurrentUser(user);
      if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
        setShowMFA(true);
      } else {
        navigate("/funnel");
      }
    } catch (err) {
      if ((err as any).code === AMPLIFY_AUTH_ERROR_CODE) {
        setError(500);
        console.error(err);
        raygun?.("send", err);
      } else {
        setError(400);
      }
    }
  }

  async function verifyMfa(e: FormEvent) {
    e.preventDefault(); // Prevent form submission
    try {
      await Auth.confirmSignIn(currentUser, code, "SOFTWARE_TOKEN_MFA");
    } catch (err: any) {
      if (err.code === AMPLIFY_AUTH_ERROR_CODE) {
        setError(500);
        console.error(err);
        raygun?.("send", err);
      } else if (err.code === AMPLIFY_MFA_CODE_MISMATCH) {
        setError("MFA_CODE_MISMATCH");
      } else {
        setError(400);
      }
    }
  }

  return (
    <>
      {!showMFA && (
        <form onSubmit={login}>
          <ControlledCollapsible open={!!error} fast>
            <div className={styles.error}>{error && ERRORS[error]}</div>
          </ControlledCollapsible>
          <Input
            className={styles.input}
            type="email"
            label="Email"
            value={email}
            required
            invalid={!!error}
            onChange={({ target }) => {
              !!error && setError(null);
              setEmail(target.value);
            }}
          />
          <Input
            className={styles.input}
            type="password"
            label="Password"
            value={password}
            required
            minLength={8}
            invalid={!!error}
            onChange={({ target }) => {
              !!error && setError(null);
              setPassword(target.value);
            }}
          />
          <div className={styles.actions}>
            <Button
              className={styles.action}
              theme="ghost"
              href="/forgot-password"
              label="Forgot password?"
            />
            <Button className={styles.action} type="submit" label="Sign in" />
          </div>
        </form>
      )}
      {showMFA && (
        <form onSubmit={verifyMfa}>
          <h2 className={styles.title}>Two-factor authentication</h2>
          <div className={styles.nav}>
            <h3 className={styles.subheading}>
              To verify your identity, enter the 6-digit code generated in your
              Google Authenticator app{" "}
            </h3>
          </div>
          <ControlledCollapsible open={!!error} fast>
            <div className={styles.error}>{error && ERRORS[error]}</div>
          </ControlledCollapsible>
          <Input
            className={styles.input}
            type="text"
            label="Verification code"
            value={code}
            required
            invalid={!!error}
            onChange={({ target }) => {
              !!error && setError(null);
              setCode(target.value.replace(/\D/g, ""));
            }}
          />
          <div className={styles.actions}>
            <Button className={styles.action} type="submit" label="Verify" />
          </div>
        </form>
      )}
    </>
  );
}
