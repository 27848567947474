import type { LineData } from "../line-chart";

export function MarkerMid({
  data,
  position = "mid",
  visible,
}: {
  data: LineData;
  position?: "start" | "mid" | "end";
  visible: boolean;
}) {
  return (
    <marker
      viewBox="0 0 10 10"
      markerWidth={10}
      markerHeight={10}
      markerUnits="userSpaceOnUse"
      refX={5}
      refY={5}
      id={`${data.id}-${position}`}
      data-testid="line-chart-marker-mid"
    >
      <circle
        r={5}
        cx={5}
        cy={5}
        fill={data.color}
        visibility={visible ? "visible" : "hidden"}
      />
    </marker>
  );
}
