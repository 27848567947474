import { Button } from "$src/components/button/button";
import { Checkbox } from "$src/components/checkbox/checkbox";
import {
  ControlledDropdown,
  DropdownItem,
  type DropdownProps,
} from "$src/components/dropdown/dropdown";
import { DropdownGroup } from "$src/components/dropdown/lib/dropdown-group/dropdown-group";
import { RadioButton } from "$src/components/radio-button/radio-button";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { cx } from "$src/lib/utils";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { useFilters } from "$src/stores/useFilters";
import mixins from "$src/styles/mixins.module.css";
import { SampleIndicator } from "$src/views/sample-indicator/sample-indicator";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useState } from "react";

import type { BFFOutput } from "@tracksuit/bff/trpc";
import type { SampleQuality } from "@tracksuit/frontend/schemas";

import styles from "./demographics.module.css";

export type DemographicsFilterProps = {
  quality?: SampleQuality;
  disabled?: boolean;
  /** HACK for old compare mode */
  hideEmptyPopFilters?: boolean;
} & Partial<DropdownProps>;

export const FilterDemographics = ({
  disabled,
  quality,
  hideEmptyPopFilters,
  className,
}: DemographicsFilterProps) => {
  const [{ demographics }, setFilters] = useFilters((s) => [s.filters, s.set]);
  const { availableFilters, loading } =
    useAvailableFilters(hideEmptyPopFilters);
  const analytics = useAnalytics();
  const [selectedFilterType, setSelectedFilterType] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const getFiltersObject = useCallback(
    (filterId?: string) =>
      availableFilters.demographics?.find((f) =>
        f.filters.find((f) => f.id === filterId),
      ),
    [availableFilters],
  );
  const filterLabel = useMemo(() => {
    switch (demographics.length) {
      case 0:
        return "All demographics";
      case 1:
        return demographics[0]?.filter;
      default:
        return `Multiple ${pluralize(
          getFiltersObject(demographics[0]?.id)?.label ?? "",
        )}`;
    }
  }, [demographics, availableFilters]);

  const updateFilters = (
    state: boolean,
    filter: { id: string; filter: string },
    filterObject: NonNullable<BFFOutput["filters"]["get"]["demographics"]>[0],
  ) => {
    const newFilterType = !filterObject.filters.some((f) =>
      demographics?.some(({ id }) => id === f.id),
    );
    const populationState = {
      hasNoPopulationData:
        state || (!state && demographics.length > 1)
          ? !filterObject.supportsPopulation
          : false,
      ...(state && !filterObject.supportsPopulation
        ? { dataRepresentation: "percentage" as const }
        : {}),
    };

    if (filterObject.supportsMulti) {
      setFilters({
        demographics: state
          ? [...(newFilterType ? [] : demographics ?? []), filter]
          : demographics?.filter(({ id }) => id !== filter.id),
        ...populationState,
      });
    } else {
      setFilters({
        demographics: state ? [filter] : [],
        ...populationState,
      });
    }
  };

  /* v8 ignore next */
  // Analytics tracking ignored from coverage
  useEffect(() => {
    analytics?.track(AnalyticsEvents.FilterDemographics, {
      demographics,
    });
  }, [demographics, analytics]);

  useEffect(() => {
    if (!openDropdown && availableFilters.demographics) {
      const type = getFiltersObject(demographics[0]?.id)?.type;
      if (type !== undefined) {
        setSelectedFilterType(type);
      }
    }
  }, [openDropdown, demographics, availableFilters]);

  return (
    <ControlledDropdown
      theme="select"
      disabled={loading || disabled}
      className={className}
      data-testid={"filter-dropdown"}
      label="Demographics"
      selected={filterLabel}
      onChange={setOpenDropdown as any}
      open={openDropdown}
    >
      <div className={styles.info}>
        {!quality || quality === "STRONG" ? (
          <p className={styles["info-content"]}>
            Filters can only be applied within one demographic group at a time
            (i.e. you cannot add ‘Age’ and ‘Gender’ at the same time). Filtering
            within a different group will clear all other selections.
          </p>
        ) : (
          <SampleIndicator quality={quality} context="demographics" />
        )}
      </div>
      <div className={cx(styles.wrapper, mixins.scrollable)}>
        <div>
          {availableFilters.demographics.map((filter) => {
            return (
              <DropdownGroup
                id="filter-group"
                className={styles.group}
                key={filter.type}
                label={`${filter.label}
                ${
                  !!demographics.length &&
                  filter.supportsMulti &&
                  getFiltersObject(demographics[0]?.id)?.type === filter.type
                    ? ` (${demographics.length})`
                    : ""
                }`}
                onChange={() =>
                  setSelectedFilterType(
                    filter.type === selectedFilterType ? "" : filter.type ?? "",
                  )
                }
                open={selectedFilterType === filter.type}
              >
                {filter.filters.map((f) => {
                  const checked = !!demographics?.some(({ id }) => id === f.id);
                  return filter.supportsMulti ? (
                    <DropdownItem
                      id={`${filter.label}-item`}
                      className={styles.item}
                      key={f.id}
                      onClick={() => updateFilters(!checked, f, filter)}
                    >
                      <Checkbox
                        label={f.filter}
                        checked={checked}
                        style={
                          {
                            "--checkbox-border-size": "1px",
                            "--checkbox-color": checked
                              ? "var(--color-purple-500)"
                              : "var(--color-grey-500)",
                          } as any
                        }
                        className={cx(styles.control)}
                      />
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      id={`${filter.label}-item`}
                      className={styles.item}
                      key={f.id}
                      onClick={() => {
                        updateFilters(
                          !demographics?.some(({ id }) => id === f.id),
                          f,
                          filter,
                        );
                      }}
                    >
                      <RadioButton
                        label={f.filter}
                        name={filter.type ?? ""}
                        className={styles.control}
                        checked={!!demographics?.some(({ id }) => id === f.id)}
                      />
                    </DropdownItem>
                  );
                })}
              </DropdownGroup>
            );
          })}
        </div>
        <div className={styles.clearfilter}>
          <Button
            onClick={() =>
              setFilters({
                demographics: [],
                hasNoPopulationData: false,
              })
            }
            theme="ghost"
            label="Clear filters"
            className={styles.button}
            disabled={demographics.length === 0}
          />
        </div>
      </div>
    </ControlledDropdown>
  );
};
