import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";

import { FunnelQuestionType } from "@tracksuit/frontend/schemas";

export const useFunnelTimelineQuestion = ({
  question,
}: {
  question?: FunnelQuestionType;
}) => {
  const accountBrand = useAccount((s) => s.active);
  const [{ dateRange, demographics, brandIdList }, filtersReady] = useFilters(
    (s) => [s.filters, s.ready],
  );
  const { data, isLoading: loading } =
    apiQueryClient.funnel.getTimelineQuestion.useQuery(
      {
        accountBrandId: accountBrand!.accountBrandId,
        startDate: dateRange.start!,
        endDate: dateRange.end!,
        brandIds: brandIdList!,
        filters: demographics.map(({ id }) => id),
        questionType: question ?? "PROMPTED_AWARENESS",
      },
      {
        enabled: !!question && filtersReady && !!accountBrand,
      },
    );

  return {
    data,
    loading,
  };
};
