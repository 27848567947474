import { Tag } from "$src/components/tag/tag";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { cx } from "$src/lib/utils";
import {
  type HiddenItemType,
  useHiddenItems,
} from "$src/stores/useHiddenItems";
import { type ComponentProps } from "react";

import styles from "./hidden-items.module.css";

export type HiddenItemsProps = {
  type: HiddenItemType;
} & ComponentProps<"div">;

/* v8 ignore next */
// Not tested as it renders a well-tested component (Tag) from a Zustand store
export const HiddenItems = ({
  type,
  className,
  ...props
}: HiddenItemsProps) => {
  const [hiddenItems, showItem] = useHiddenItems((s) => [
    s.hidden[type],
    s.remove,
  ]);
  const analytics = useAnalytics();

  return hiddenItems?.length ? (
    <div
      className={cx(styles.items, className)}
      {...props}
      data-x-hidden-from-screenshot
    >
      {hiddenItems.map((item, i) => (
        <Tag
          className={styles.item}
          label={item.label}
          action="Show"
          active
          onAction={() => {
            showItem(type, item);
            analytics?.track(AnalyticsEvents.ShowItem, {
              type,
              item: item.label,
            });
          }}
          key={i}
        />
      ))}
    </div>
  ) : (
    <div />
  );
};
