import { cx, formatNumberRepresentation } from "$src/lib/utils";
import { useClickOutside, useDeepCompareMemo } from "@react-hookz/web";
import { Axis, Orientation } from "@visx/axis";
import { curveMonotoneX } from "@visx/curve";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { coerceNumber, scaleLinear, scaleTime } from "@visx/scale";
import { Line, LinePath } from "@visx/shape";
import { useTooltip } from "@visx/tooltip";
import { bisector, extent } from "d3-array";
import { format } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  type ComponentProps,
  type MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import type { DataRepresentation } from "@tracksuit/frontend/schemas";
import { toDate } from "@tracksuit/frontend/utils";

import { Tip } from "../tooltip/tooltip";
import styles from "./line-chart.module.css";
import { MarkerEnd } from "./markers/marker-end";
import { MarkerMid } from "./markers/marker-mid";
import { MarkerStart } from "./markers/marker-start";

type Point = {
  x: Date;
  y: number;
};

export type LineData = {
  id: number | string;
  labels: string[];
  color: string;
  points: Point[];
  dashed?: boolean;
  difference: {
    percentage: number;
    isSignificant: boolean;
  };
};

export type LineChartMarker = {
  id: number;
  date: Date;
  title: string;
  description: string[] | string;
};

export type LineChartProps = {
  /** Data for the chart */
  data: LineData[];
  /** Optional line markers */
  markers?: LineChartMarker[];
  /** Mark a line/dataset as active  */
  activeDatasets: LineData["id"][];
  /** Called when user clicks a new line/dataset */
  onActiveDatasetsChange?(ids: LineData["id"][]): void;
  /** Format of number points/labels */
  numberFormat?: DataRepresentation;
  /** Optional loading state */
  loading?: boolean;
  /** Whether to show projected data in the chart */
  projected?: number;
  /** Whether to hide x axis */
  xAxisHidden?: boolean;
  /** Whether to hide y axis */
  yAxisHidden?: boolean;
  /** Whether to show all data points */
  dataPointsVisible?: boolean;
} & ComponentProps<"div">;

const LABEL_CONFIG = {
  fill: "var(--color-text)",
  fontSize: 12,
  fontFamily: "var(--font-tracksuit)",
  letterSpacing: "-0.02em",
  lineHeight: 1.5,
};
const GUTTERS = {
  x: 100,
  y: 0,
};
const AXIS_SIZE = 32;
const PSEUDO_LINE_WIDTH = 16;

const x = (point: Point) => point.x;
const y = (point: Point) => point.y;
const minMax = (vals: (number | { valueOf(): number })[]) => [
  0,
  Math.max(0.3, Math.max(...vals.map(coerceNumber))),
];

/**
 * @component
 * Line chart graph
 */
export const LineChart = ({
  data,
  markers,
  activeDatasets = [],
  onActiveDatasetsChange,
  numberFormat = "percentage",
  loading = false,
  projected = 0,
  xAxisHidden,
  yAxisHidden,
  dataPointsVisible,
  className,
  ...props
}: LineChartProps) => {
  const dataPoints = useDeepCompareMemo(
    () => data.map((d) => d.points).reduce((rec, d) => rec.concat(d), []),
    [data],
  );
  const getTicks = (dp: number[] | Date[]) => {
    return dp.length <= 12
      ? dp
      : (dp as any).filter(
          (_: any, i: number) => i % Math.ceil(dp.length / 12) === 0,
        );
  };

  const getDashArray = (
    points: Point[],
    dashed: boolean,
    projected: number,
  ) => {
    const percentageProjected = projected
      ? (projected / (points.length - 1)) * 100
      : 0;
    const projectedDashArray = `${100 - percentageProjected},${Array(
      Math.ceil(percentageProjected),
    )
      .fill("1")
      .join(",")}`;

    return dashed ? "3,2" : projectedDashArray;
  };

  const getValueRange = (data: number[]) => {
    const [low, high] = minMax(data);
    const max =
      numberFormat === "percentage" && high ? Math.min(high, 0.9) : high;
    const vals = [];

    let step: number;

    if (numberFormat === "percentage" && max) {
      if (max > 0.2) {
        step = 0.1;
      } else if (max > 0.1) {
        step = 0.05;
      } else {
        step = 0.01;
      }
    } else {
      step = max ? max / 10 : 0;
    }

    for (
      let i = Number(low?.toFixed(1));
      i <= Number(max?.toFixed(1)) + step;
      i = i + step
    ) {
      vals.push(i);
    }

    return vals;
  };
  const xScale = scaleTime<number>({
    clamp: true,
    domain: extent(dataPoints, x) as [Date, Date],
  });
  const yScale = scaleLinear<number>({
    clamp: true,
    domain: [
      getValueRange(dataPoints.map(y))[0]!,
      getValueRange(dataPoints.map(y))[
        getValueRange(dataPoints.map(y)).length - 1
      ]!,
    ],
  });
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<Point & { labels: string[] }>();
  const {
    tooltipData: markerData,
    tooltipLeft: markerLeft,
    tooltipOpen: markerOpen,
    showTooltip: showMarker,
    hideTooltip: hideMarker,
  } = useTooltip<LineChartMarker>();
  const {
    tooltipData: endTipData,
    tooltipLeft: endTipLeft,
    tooltipTop: endTipTop,
    tooltipOpen: endTipOpen,
    showTooltip: showEndTip,
    hideTooltip: hideEndTip,
  } = useTooltip<Point & LineData["difference"]>();
  const [valueRange, setValueRange] = useState<number[]>([]);
  const [animated, setAnimated] = useState(false);
  const [hoveredLine, setHoveredLine] = useState<number | null>();
  const bisectDate = bisector((d: Point) => toDate(d.x.toString())).left;
  const [visibleMarkers, setVisibleMarkers] = useState<LineChartMarker[]>();
  const [openMarker, setOpenMarker] = useState<LineChartMarker | null>();

  const handleTooltip = useCallback(
    (event: MouseEvent<SVGPathElement>, data: LineData) => {
      const { x: xPoint } = localPoint(event) ?? { x: 0, y: 0 };
      const x0 = xScale.invert(xPoint - GUTTERS.x);
      const index = bisectDate(data.points, x0, 1);
      const d = data.points[index - 1];

      if (d && activeDatasets.includes(data.id)) {
        showTooltip({
          tooltipLeft: xScale(d.x) + GUTTERS.x,
          tooltipTop: yScale(d.y),
          tooltipData: { ...d, labels: data.labels },
        });
      }
    },
    [showTooltip, xScale, yScale, bisectDate, activeDatasets],
  );

  const handleEndTip = useCallback(
    (point: Point, difference: LineData["difference"]) => {
      showEndTip({
        tooltipLeft: xScale(point.x) + GUTTERS.x,
        tooltipTop: yScale(point.y),
        tooltipData: { ...point, ...difference },
      });
    },
    [showEndTip, xScale, yScale],
  );

  const handleMarkerTip = useCallback(
    (marker?: LineChartMarker) => {
      if (openMarker === marker || !marker) {
        hideMarker();
        setOpenMarker(null);
      } else {
        setOpenMarker(marker);
        showMarker({
          tooltipLeft: xScale(marker.date),
          tooltipTop: 0,
          tooltipData: marker,
        });
      }
    },
    [showMarker, hideMarker, openMarker, setOpenMarker, xScale],
  );

  const handleMouseEnter = useCallback(
    (e: MouseEvent<SVGPathElement>, data: LineData) => {
      setHoveredLine(data.id as any);
      handleTooltip(e, data);
    },
    [setHoveredLine, handleTooltip],
  );

  const handleMouseOut = useCallback(() => {
    setHoveredLine(null);
    hideTooltip();
    hideEndTip();
  }, [setHoveredLine, hideTooltip]);

  const sortDatasets = (a: LineData, b: LineData) => {
    if (activeDatasets.includes(a.id)) {
      return 1;
    }

    if (activeDatasets.includes(b.id)) {
      return -1;
    }

    return (a.points[0]?.y ?? 0) - (b.points[0]?.y ?? 0);
  };

  useEffect(() => {
    const [domainStart, domainEnd] = xScale.domain();
    const markersInDomain = markers?.filter((marker) =>
      domainStart && domainEnd
        ? domainStart <= marker.date && domainEnd >= marker.date
        : false,
    );
    if (JSON.stringify(visibleMarkers) !== JSON.stringify(markersInDomain)) {
      setVisibleMarkers(markersInDomain);
    }
  }, [markers, xScale, visibleMarkers]);

  useEffect(() => {
    if (!markers?.length) {
      hideMarker();
    }
  }, [markers, hideMarker]);

  useEffect(() => {
    setValueRange(getValueRange(dataPoints.map(y)));
  }, [dataPoints]);

  const marker = useRef(null);

  // These are excluded from coverage because the hooks themselves are well tested
  /* v8 ignore next */
  useClickOutside(marker, () => handleMarkerTip());

  return (
    <div
      className={cx(styles.container, loading && styles.loading, className)}
      {...props}
    >
      {!loading && (
        <ParentSize debounceTime={50}>
          {({ width, height }) => {
            xScale.range([0, width - GUTTERS.x * 2 - AXIS_SIZE]);
            yScale.range([height - GUTTERS.y * 2 - AXIS_SIZE, 0]);

            return (
              <>
                {tooltipOpen && (
                  <Tip
                    key={tooltipData?.labels.join(" ")}
                    className={styles.tooltip}
                    style={{
                      top: `${tooltipTop ?? 0}px`,
                      left: `${tooltipLeft ?? 0}px`,
                    }}
                    withArrow
                    title={
                      <>
                        {formatNumberRepresentation(
                          tooltipData?.y ?? 0,
                          numberFormat,
                        )}{" "}
                        {tooltipData?.labels.map((label) => (
                          <>
                            <span key={label}>{label}</span>
                            <br />
                          </>
                        ))}
                      </>
                    }
                    tip={
                      tooltipData?.x
                        ? format(toDate(tooltipData.x.toString()), "MMM yyyy")
                        : ""
                    }
                  />
                )}
                {endTipOpen && (
                  <Tip
                    key={endTipData?.x.toString()}
                    className={styles.tooltip}
                    style={{
                      top: `${(endTipTop ?? 0) - 8}px`,
                      left: `${(endTipLeft ?? 0) + 80}px`,
                    }}
                    withArrow
                    tip={
                      endTipData?.isSignificant
                        ? "This is a statistically significant change"
                        : "This isn't yet significant"
                    }
                  />
                )}
                {markerOpen && (
                  <div
                    className={styles.markertip}
                    style={{
                      top: `${markerOpen ?? 0}px`,
                      left: `${(markerLeft ?? 0) + GUTTERS.x}px`,
                    }}
                    onClick={() => {
                      handleMarkerTip();
                    }}
                    ref={marker}
                  >
                    <h2 className={styles["markertip-heading"]}>
                      {markerData?.title}
                    </h2>
                    {[markerData?.description].flat().map((text) => (
                      <span key={text} className={styles["markertip-text"]}>
                        {text}
                      </span>
                    ))}
                  </div>
                )}
                <motion.svg
                  initial={{
                    transform: "scaleY(0)",
                  }}
                  animate={{
                    transform: "scaleY(1)",
                  }}
                  style={{ transformOrigin: "bottom", overflow: "visible" }}
                  height={height}
                  width={width}
                  onAnimationComplete={() => setAnimated(true)}
                  data-testid="line-chart"
                >
                  <AnimatePresence>
                    {animated && (
                      <motion.g
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                      >
                        {!xAxisHidden && (
                          <>
                            <line
                              x1={AXIS_SIZE}
                              x2={width - AXIS_SIZE}
                              y1={height - AXIS_SIZE}
                              y2={height - AXIS_SIZE}
                              width="100%"
                              fill="transparent"
                              shapeRendering="crispEdges"
                              stroke="#222"
                              strokeWidth="1"
                              strokeLinecap="square"
                            />
                            <Axis
                              orientation={Orientation.bottom}
                              top={height - AXIS_SIZE}
                              left={GUTTERS.x}
                              tickValues={getTicks(
                                dataPoints
                                  .map(x)
                                  .filter(
                                    (date, i, self) =>
                                      self.findIndex(
                                        (d) => d.getTime() === date.getTime(),
                                      ) === i,
                                  ),
                              )}
                              scale={xScale}
                              tickFormat={(date) =>
                                date
                                  ? format(toDate(date.toString()), "MMM ‘yy")
                                  : ""
                              }
                              tickLabelProps={{
                                ...LABEL_CONFIG,
                                verticalAnchor: "start",
                              }}
                              labelProps={{
                                ...LABEL_CONFIG,
                                textAnchor: "middle",
                                verticalAnchor: "start",
                              }}
                              axisClassName="line-chart-x-axis"
                            />
                          </>
                        )}
                        {!yAxisHidden && (
                          <Axis
                            orientation={Orientation.left}
                            top={GUTTERS.y}
                            left={AXIS_SIZE}
                            tickValues={getTicks(valueRange)}
                            scale={yScale}
                            tickFormat={(num) =>
                              formatNumberRepresentation(
                                Number(num),
                                numberFormat,
                              )
                            }
                            tickLabelProps={{
                              ...LABEL_CONFIG,
                              textAnchor: "end",
                              verticalAnchor: "middle",
                              x: -AXIS_SIZE + 20,
                            }}
                            labelProps={{
                              ...LABEL_CONFIG,
                              textAnchor: "middle",
                              verticalAnchor: "end",
                              y: -AXIS_SIZE + 12,
                            }}
                            axisClassName="line-chart-y-axis"
                          />
                        )}
                      </motion.g>
                    )}
                  </AnimatePresence>

                  {visibleMarkers && (
                    <Group left={GUTTERS.x}>
                      {visibleMarkers.map((marker, i) => (
                        <Group key={"visible-markers-" + i}>
                          <circle
                            fill="var(--color-purple-200)"
                            cx={xScale(marker.date)}
                            cy={0}
                            r={5}
                            onClick={() => {
                              handleMarkerTip(marker);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                          <Line
                            data-testid="line-chart-line-marker"
                            from={{
                              x: xScale(marker.date),
                              y: 0,
                            }}
                            to={{
                              x: xScale(marker.date),
                              y: height - AXIS_SIZE,
                            }}
                            stroke="var(--color-purple-200)"
                            strokeWidth={2}
                            strokeDasharray="1,2,3,2"
                            pathLength="100"
                          />
                          {/* 
                          We create a second identical (but transparent) line path 
                          with a thicker stroke for a larger target for mouse interactions 
                       */}
                          <Line
                            from={{
                              x: xScale(marker.date),
                              y: 0,
                            }}
                            to={{
                              x: xScale(marker.date),
                              y: height - AXIS_SIZE,
                            }}
                            strokeWidth={PSEUDO_LINE_WIDTH}
                            stroke="transparent"
                            onClick={() => {
                              handleMarkerTip(marker);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </Group>
                      ))}
                    </Group>
                  )}

                  {data.sort(sortDatasets).map((line) => (
                    <Group key={line.id} top={GUTTERS.y} left={GUTTERS.x}>
                      <defs>
                        <MarkerStart
                          data={line}
                          numberFormat={numberFormat}
                          visible={activeDatasets.includes(line.id)}
                        />
                        <MarkerMid
                          data={line}
                          visible={activeDatasets.includes(line.id)}
                        />
                        <MarkerMid
                          data={line}
                          position="start"
                          visible={!!dataPointsVisible}
                        />
                        <MarkerMid data={line} visible={!!dataPointsVisible} />
                        <MarkerMid
                          data={line}
                          position="end"
                          visible={!!dataPointsVisible}
                        />
                      </defs>

                      {dataPointsVisible &&
                        line.points.map((point, i) =>
                          (activeDatasets.includes(line.id) &&
                            (i === 0 || i === line.points.length - 1)) ||
                          (tooltipOpen && tooltipData?.x === point.x) ? null : (
                            <text
                              className={styles.point}
                              y={yScale(point.y) - 15}
                              x={xScale(point.x)}
                              key={"tool-tip-" + i}
                            >
                              {formatNumberRepresentation(
                                point.y,
                                numberFormat,
                              )}
                            </text>
                          ),
                        )}
                      <LinePath
                        key={`${line.id}-${dataPointsVisible ? "points" : ""}`}
                        curve={curveMonotoneX}
                        data={line.points}
                        x={(d) => xScale(x(d)) ?? 0}
                        y={(d) => yScale(y(d)) ?? 0}
                        stroke={line.color}
                        strokeWidth={3}
                        pathLength="100"
                        strokeDasharray={getDashArray(
                          line.points,
                          !!line.dashed,
                          projected,
                        )}
                        shapeRendering="geometricPrecision"
                        markerStart={`url(#${line.id}-${
                          activeDatasets.includes(line.id) ? "active-" : ""
                        }start)`}
                        markerMid={`url(#${line.id}-mid)`}
                        style={{
                          position: "relative",
                          // pointerEvents: "none",
                          transition: "opacity 250ms var(--easing-standard)",
                          opacity:
                            !activeDatasets.includes(line.id) &&
                            hoveredLine === line.id
                              ? 0.35
                              : 1,
                        }}
                      />
                      {/* End marker, cannot be actual marker for mouse events */}
                      {activeDatasets.includes(line.id) && (
                        <MarkerEnd
                          data={line}
                          numberFormat={numberFormat}
                          x={xScale(line.points[line.points.length - 1]!.x)}
                          y={yScale(line.points[line.points.length - 1]!.y)}
                          onMouseEnter={() =>
                            handleEndTip(
                              line.points[line.points.length - 1]!,
                              line.difference,
                            )
                          }
                          onMouseMove={() =>
                            handleEndTip(
                              line.points[line.points.length - 1]!,
                              line.difference,
                            )
                          }
                          onMouseOut={handleMouseOut}
                        />
                      )}
                      {/* 
                          We create a second identical (but transparent) line path 
                          with a thicker stroke for a larger target for mouse interactions 
                       */}
                      <LinePath
                        curve={curveMonotoneX}
                        data={line.points}
                        x={(d) => xScale(x(d)) ?? 0}
                        y={(d) => yScale(y(d)) ?? 0}
                        stroke="transparent"
                        strokeWidth={PSEUDO_LINE_WIDTH}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          onActiveDatasetsChange?.(
                            activeDatasets.includes(line.id)
                              ? activeDatasets.filter((id) => id !== line.id)
                              : [...activeDatasets, line.id],
                          )
                        }
                        onMouseEnter={(e) => handleMouseEnter(e, line)}
                        onMouseMove={(e) => handleTooltip(e, line)}
                        onMouseOut={handleMouseOut}
                        className={`line-chart-line-${line.id}`}
                      />
                    </Group>
                  ))}
                </motion.svg>
              </>
            );
          }}
        </ParentSize>
      )}
    </div>
  );
};
