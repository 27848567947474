import { cx, formatNumberRepresentation } from "$src/lib/utils";
import { useFilters } from "$src/stores/useFilters";
import { motion } from "framer-motion";
import { type ComponentProps, useEffect, useState } from "react";

import { DifferenceLabel } from "../difference-label/difference-label";
import styles from "./column-chart.module.css";

type DataPoint = {
  /** Value */
  value: number;
  /** Color of the column */
  color: string;
  /** Color of the column's label, defaults to white */
  labelColor?: string;
};

export type ColumnChartProps = {
  /** Data for the chart */
  data: DataPoint[];
  /** Optional differences between columns */
  differences?: null | { percentage: number; isSignificant: boolean }[];

  /** Theme of the chart */
  theme: "square" | "rounded";
  /** Whether the chart is compact */
  compact?: boolean;
  /** Whether to show percentage labels in the columns */
  labels?: boolean;
} & ComponentProps<"div">;

const MotionDifferenceLabel = motion(DifferenceLabel);

/**
 * @component
 * Vertical bar/column chart
 */
export const ColumnChart = ({
  data,
  theme,
  differences = null,
  compact,
  labels,
  className,
  ...props
}: ColumnChartProps) => {
  const { dataRepresentation } = useFilters((s) => s.filters);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    setMaxValue(Math.max(...data.map((d) => d.value)));
  }, [data, dataRepresentation]);

  return (
    <div
      className={cx(
        styles.chart,
        styles[theme],
        compact === true && styles.compact,
        className,
      )}
      data-testid="column-chart"
      {...props}
    >
      {data.map(({ value, color, labelColor }, i) => (
        <motion.div
          className={styles.column}
          initial={{ transform: "scaleY(0)" }}
          animate={{ transform: "scaleY(1)" }}
          transition={{
            duration: 0.3,
            delay: i * 0.1,
          }}
          style={{
            height: `${Math.min((value / maxValue) * 100, 100)}%`,
            backgroundColor: color,
          }}
          key={i}
          data-testid={`column-chart-column-${i}`}
        >
          {labels === true && (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 + i * 0.1 }}
              style={{ color: labelColor ?? "white" }}
              className={styles.label}
            >
              {formatNumberRepresentation(value, dataRepresentation)}
            </motion.span>
          )}
        </motion.div>
      ))}
      {differences &&
        differences.map(({ percentage, isSignificant }, i) => (
          <MotionDifferenceLabel
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 + i * 0.1 }}
            className={styles.difference}
            style={{ left: `${(i + 1) * (100 / data.length + 1)}%` }}
            value={percentage}
            significant={isSignificant}
            key={i}
          />
        ))}
    </div>
  );
};
