import { useToasts } from "$src/stores/useToast";

import { AnalyticsEvents, useAnalytics } from "./useAnalytics";

export const useCopyText = (
  text: string,
  type: string | ({ type: string } & Record<string, string>),
  onCopy?: () => void,
) => {
  const analytics = useAnalytics();
  const addToast = useToasts((s) => s.add);

  return function copyText() {
    try {
      navigator.clipboard.writeText(text);
      onCopy?.();

      analytics?.track(AnalyticsEvents.Copy, {
        ...(typeof type === "string" ? { type } : { ...type }),
      });

      addToast("success", {
        title: "Text copied to clipboard",
        id: `toastie${Math.random()}`,
      });
    } catch (error) {
      addToast("error", {
        title: "Well, this is embarrassing!",
        message:
          "We're really sorry but the copy text feature isn't working right now. Please try again later or if it continues contact our support team",
        id: `toastie${Math.random()}`,
      });
    }
  };
};
