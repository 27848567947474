import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useCopyText } from "$src/hooks/useCopyText";
import type { TRPCClientErrorLike } from "@trpc/client";
import { Copy, Sparkles, X } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import Markdown from "react-markdown";
import { useLocation } from "react-router-dom";
import remarkGfm from "remark-gfm";

import type { AppRouter } from "@tracksuit/bff/trpc";

import { Button } from "../button/button";
import { ControlledDropdown, DropdownItem } from "../dropdown/dropdown";
import { IconButton } from "../icon-button/icon-button";
import { Slideout } from "../slideout/slideout";
import { TextLoading } from "../text-loading/text-loading";
import styles from "./summary.module.css";

type SummaryData = {
  /** Label of the summary, used if multiple summaries */
  label?: string;
  /** Summary content */
  content?: string;
  /** Whether summary is loading */
  loading?: boolean;
  /** Optional summary error */
  error?: TRPCClientErrorLike<AppRouter> | null;
  /** Optional type of summary  */
  type?: string;
  /** Optional additional metadata to pass to tracking events */
  eventMetadata?: { [key: string]: any };
};

export type SummaryProps = {
  /** Whether summary is open */
  open: boolean;
  /** Callback on summary close */
  onClose(): void;
  /** Summaries to show */
  summaries: SummaryData[];
};

/**
 * @component
 * Summary sliedout
 */
export function Summary({ open, onClose, summaries }: SummaryProps) {
  const analytics = useAnalytics();
  const [active, setActive] = useState<number>(0);
  const [selectorOpen, setSelectorOpen] = useState(false);
  const summary = useMemo(() => summaries[active], [summaries, active]);
  const { pathname } = useLocation();
  const copyText = useCopyText(
    summary?.content ?? "",
    `${pathname} ${summary?.type ?? ""} Summary`,
    () => {
      setCopyButton("Text copied");
      setTimeout(() => {
        setCopyButton("Copy text");
      }, 4000);
    },
  );
  const [copyButton, setCopyButton] = useState("Copy text");

  useEffect(() => {
    if (!summary) {
      return;
    }

    if (open) {
      analytics?.track(AnalyticsEvents.SummaryOpened, {
        type: summary.type,
        metadata: summary.eventMetadata,
      });
    } else if (summary.loading) {
      analytics?.track(AnalyticsEvents.SummaryCancelled, {
        type: summary.type,
        metadata: summary.eventMetadata,
      });
    }
  }, [summary, open, analytics]);

  useEffect(() => {
    if (summary?.content) {
      analytics?.track(AnalyticsEvents.SummaryGenerated, {
        type: summary?.type,
        metadata: summary?.eventMetadata,
      });
    }
  }, [summary?.content, analytics]);

  useEffect(() => {
    if (summary?.error) {
      analytics?.track(AnalyticsEvents.SummaryError, {
        type: summary?.type,
        metadata: summary?.eventMetadata,
      });
    }
  }, [summary?.error, analytics]);

  return (
    <Slideout
      open={open}
      onChange={(o) => !o && onClose()}
      position="right"
      header={
        <div className={styles.header}>
          <h1 className={styles["header-heading"]}>
            <Sparkles className={styles["header-icon"]} /> Summary
          </h1>
          <IconButton icon={X} onClick={onClose} data-testid="close-button" />
        </div>
      }
    >
      <p className={styles.info}>
        This AI summary has been created based on the filters you have selected
      </p>
      {summaries.length > 1 && (
        <ControlledDropdown
          open={selectorOpen}
          onChange={setSelectorOpen}
          theme="select"
          label="Summarize"
          selected={summary?.label}
          className={styles.selector}
        >
          {summaries.map(({ label }, i) => (
            <DropdownItem
              id="summary-selector"
              onClick={() => {
                setActive(i);
                setSelectorOpen(false);
              }}
              key={i}
              data-testid={`summary-filter-${i}`}
            >
              {label}
            </DropdownItem>
          ))}
        </ControlledDropdown>
      )}
      <div className={styles.card}>
        {summary?.error ? (
          <div className={styles.error}>
            <span className={styles["error-emoji"]}>☔️</span>
            <h1 className={styles["error-heading"]}>
              Oh no, OpenAI seems to be under the weather
            </h1>
            <p>
              OpenAI sometimes refuses to process the data we provide it. This
              is usually fixed by waiting and trying again later.
            </p>
          </div>
        ) : (
          <>
            <h1 className={styles["summary-heading"]}>
              <span className={styles["summary-heading-icon"]}>🤖</span> What we
              know
            </h1>
            <div className={styles["summary-content"]}>
              {summary?.loading ? (
                <p>
                  <TextLoading lines={12} />
                </p>
              ) : (
                <Markdown remarkPlugins={[remarkGfm]}>
                  {summary?.content}
                </Markdown>
              )}
            </div>
          </>
        )}
      </div>
      {!summary?.loading && (
        <div className={styles.copy}>
          <Button
            icon={Copy}
            label={copyButton}
            onClick={copyText}
            data-testid="copy-button"
          />
        </div>
      )}
    </Slideout>
  );
}
