import {
  ControlledDropdown,
  DropdownItem,
} from "$src/components/dropdown/dropdown";
import { useTldrStats } from "$src/queries/tldr";
import { useAccount } from "$src/stores/useAccount";
import { useState } from "react";

import { Stat } from "./lib/stat";
import styles from "./stats.module.css";

const VIEWS = {
  period: "Last 6 months",
  total: "Since tracking began",
};

export const Stats = () => {
  const account = useAccount((s) => s.active);
  const [view, setView] = useState<keyof typeof VIEWS>("period");
  const [selectorOpen, setSelectorOpen] = useState(false);
  const { data, loading } = useTldrStats();

  return (
    <div className={styles.wrapper}>
      <ControlledDropdown
        theme="inline"
        className={styles.selector}
        open={selectorOpen}
        onChange={setSelectorOpen}
        selected={VIEWS[view]}
      >
        {Object.keys(VIEWS).map((viewOption) => (
          <DropdownItem
            id="selector-item"
            key={viewOption}
            active={view === viewOption}
            onClick={() => {
              setView(viewOption as keyof typeof VIEWS);
              setSelectorOpen(false);
            }}
          >
            {VIEWS[viewOption as keyof typeof VIEWS]}
          </DropdownItem>
        ))}
      </ControlledDropdown>

      <div className={styles.stats}>
        <Stat
          value={data?.approached[view]}
          label="People approached"
          description="The number of people we put the survey in front of"
          loading={loading}
        />
        <Stat
          value={data?.qualified[view]}
          label="People in your category"
          description={`The number of people who met the criteria for ${account?.category.name} and completed the survey`}
          loading={loading}
        />
      </div>
    </div>
  );
};
