/* v8 ignore start */
// This hook just initialises Segment, nothing to test
import { useAccount } from "$src/stores/useAccount";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { BFFOutput } from "@tracksuit/bff/trpc";

export enum AnalyticsEvents {
  PageView = "Viewed Page",
  SessionLength = "Session Length",
  ExpandAccordion = "Expand Accordion",
  SwappedCompetitor = "Swapped Competitor",
  ChangeDataType = "Change Data Type",
  FilterDate = "Filter Dates",
  FilterBrand = "Filter Brand",
  FilterDemographics = "Filter Demographics",
  ChangeMetric = "Change Metric",
  Download = "Download",
  Comparison = "Compare Date Ranges",
  FilterUniqueWords = "Filter Unique Words",
  SummaryOpened = "Summary Opened",
  SummaryGenerated = "Summary Generated",
  SummaryError = "Summary Errored",
  SummaryCancelled = "Summary Cancelled",
  Copy = "Copy",
  SelectBrand = "Selected Active Brand",
  SelectMetric = "Selected Active Metric",
  CopyImage = "Copy Image",
  Verbatim = "Viewed Verbatim",
  OpenStatement = "Opened Statement",
  OpenSettings = "Opened Settings Menu",
  ChangeAccount = "Changed Account",
  OpenLink = "Opened Link",
  OpenProfile = "Opened Profile",
  AddMilestone = "Added Milestone",
  EditMilestone = "Edited Milestone",
  DeleteMilestone = "Deleted Milestone",
  ChangeMilestoneVisibility = "Changed Milestone Visibility",
  EasterEgg = "Easter Egg Triggered",
  ChangedConversionOptions = "Changed Conversion Options",
  ChangedTimelineOptions = "Changed Timeline Options",
  HideItem = "Hide Item",
  ShowItem = "Show Item",
  OpenInsightOverview = "Opened Insight Overview",
  OpenDemographicDriver = "Opened Demographic Driver",
}

export const getAnalytics = (
  account?: NonNullable<BFFOutput["metadata"]["list"]>[0],
  pathname?: string,
): AnalyticsBrowser | undefined => {
  if (import.meta.env.MODE !== "production") {
    return undefined;
  }

  const {
    track: _track,
    page: _page,
    ...rest
  } = AnalyticsBrowser.load({
    writeKey: "CFWwEZBkHAlPzNh58bWU5U9g2LNO08pp",
  });
  const accountProps = {
    accountBrandId: account?.accountBrandId,
    brand: account?.brandName,
    geography: account?.geography.name,
    category: account?.category.name,
  };

  return {
    track(eventName, properties, ...args) {
      return _track(
        eventName,
        {
          ...accountProps,
          route: pathname,
          ...properties,
        },
        ...args,
      );
    },
    page(category, name, properties, ...args) {
      return _page(
        category,
        name,
        {
          ...accountProps,
          ...properties,
        },
        ...args,
      );
    },
    ...rest,
  } as AnalyticsBrowser;
};

export const useAnalytics = () => {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser>();
  const { pathname } = useLocation();
  const account = useAccount((s) => s.active);

  useEffect(() => {
    if (!analytics && account) {
      setAnalytics(getAnalytics(account, pathname));
    }
  }, [account]);

  return analytics;
};
/* v8 ignore end */
