import { z } from "zod";

import { naturalNumber } from "./numbers";

export const brand = z.object({
  brandId: naturalNumber,
  brandName: z.string(),
  isAccountBrand: z.boolean().optional(),
});

export type Brand = z.infer<typeof brand>;
