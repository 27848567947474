import { Button } from "$src/components/button/button";
import { Collapsible } from "$src/components/collapsible/collapsible";
import {
  ControlledDropdown,
  DropdownItem,
  DropdownProps,
} from "$src/components/dropdown/dropdown";
import { Modal, ModalProps } from "$src/components/modal/modal";
import { RadioButton } from "$src/components/radio-button/radio-button";
import { cx } from "$src/lib/utils";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { differenceInCalendarMonths, format } from "date-fns";
import { useMemo, useState } from "react";

import { type DateComparisonPeriod } from "@tracksuit/frontend/schemas";
import { getDatePeriod, toDate } from "@tracksuit/frontend/utils";

import { DateFilterType } from "../../date";
import styles from "./custom-range.module.css";

export type CustomRangeProps = {
  type: DateFilterType;
} & Omit<ModalProps, "heading">;

const DATE_FORMAT = "MMMM yyyy";

/**
 * @component
 * Custom date range modal
 */
export const CustomRange = ({ type, onClose, ...props }: CustomRangeProps) => {
  const [dateRange, setDateRange] = useState<{
    start: string | null;
    end: string | null;
  }>({
    start: null,
    end: null,
  });
  const filterKey = useMemo(
    () => (type === "range" ? "dateRange" : "datePeriod"),
    [type],
  );
  const [{ datePeriod }, setFilters] = useFilters((s) => [s.filters, s.set]);
  const account = useAccount((s) => s.active);
  const { availableFilters } = useAvailableFilters();
  const validStartDates = useMemo(
    () =>
      availableFilters.dates && availableFilters.dates.length > 2
        ? availableFilters.dates.slice(2, availableFilters.dates.length)
        : [],
    [availableFilters.dates],
  );
  const validEndDates = useMemo(
    () =>
      availableFilters.dates?.slice(
        0,
        availableFilters.dates.indexOf(dateRange.start!) - 1,
      ) ?? [],
    [dateRange.start, availableFilters.dates],
  );
  const dateRangeLength = useMemo(
    () =>
      dateRange.start && dateRange.end
        ? differenceInCalendarMonths(
            toDate(dateRange.end),
            toDate(dateRange.start),
          ) + 1
        : 0,
    [dateRange],
  );
  const [selectedPeriod, setSelectedPeriod] = useState<
    DateComparisonPeriod | undefined
  >(datePeriod.comparisonPeriod);
  const periods = useMemo(
    () => ({
      preceding: getDatePeriod(
        dateRange.start ?? undefined,
        dateRange.end ?? undefined,
        "preceding",
      ),
      lastYear: getDatePeriod(
        dateRange.start ?? undefined,
        dateRange.end ?? undefined,
        "last-year",
      ),
    }),
    [dateRange],
  );
  const pending = useMemo(
    () => dateRange.start === null || dateRange.end === null,
    [dateRange.start, dateRange.end, selectedPeriod],
  );
  const invalidComparisonRange = useMemo(
    () =>
      !pending &&
      !availableFilters.dates?.includes(periods.preceding.start) &&
      !availableFilters.dates?.includes(periods.lastYear.start),
    [availableFilters.dates, periods],
  );

  const cancel = () => {
    setDateRange({ start: null, end: null });
    setSelectedPeriod(undefined);
    onClose();
  };

  const apply = () => {
    if (pending) {
      throw new Error("Custom date range not set");
    }

    setFilters({
      [filterKey]: {
        start: dateRange.start as string,
        end: dateRange.end as string,
        comparisonPeriod:
          type === "comparison" ? selectedPeriod : datePeriod.comparisonPeriod,
      },
    });
    setDateRange({
      start: null,
      end: null,
    });
    setSelectedPeriod(undefined);
    onClose();
  };

  return (
    <Modal heading="Custom date range" onClose={onClose} {...props}>
      <div
        className={cx(
          styles.content,
          type === "comparison" && styles["with-compare"],
        )}
      >
        <h3 className={styles.heading}>
          {type === "comparison" && "1. "}Select a date range
        </h3>
        <div className={styles.selects}>
          <DateSelect
            label="Start date"
            selected={dateRange.start}
            onSelect={(date) => setDateRange({ start: date, end: null })}
            dates={validStartDates}
            data-testid="start-date"
          />
          <DateSelect
            label="End date"
            selected={dateRange.end}
            onSelect={(date) => setDateRange((s) => ({ ...s, end: date }))}
            dates={validEndDates}
            disabled={!dateRange.start}
            data-testid="end-date"
          />
        </div>
        {availableFilters.dates?.length && (
          <span className={styles.explainer}>{`${account?.brandName} has ${
            availableFilters.dates?.length ?? 0
          } months of data tracked from ${format(
            availableFilters.dates?.[availableFilters.dates.length - 1] ?? "",
            DATE_FORMAT,
          )}. A minimum of 3 months is required for any selection`}</span>
        )}

        {type === "comparison" && invalidComparisonRange && (
          <div className={styles["compare-warning"]}>
            You don’t have enough historic data to create this comparison.
            Reduce the date range or choose a more recent time period.
          </div>
        )}

        {type === "comparison" && (
          <div className={styles.compare}>
            <h3 className={styles.heading}>
              2. Compare the selected{" "}
              {!!dateRangeLength && `${dateRangeLength} `}
              months with the...
            </h3>

            <div className={styles["compare-selects"]}>
              <RadioButton
                name="period"
                disabled={
                  pending ||
                  !availableFilters.dates?.includes(periods.preceding.start)
                }
                checked={selectedPeriod === "preceding"}
                onChange={() => setSelectedPeriod("preceding")}
                label={`Preceding period${
                  dateRangeLength
                    ? `: ${format(
                        toDate(periods.preceding.start),
                        DATE_FORMAT,
                      )} - ${format(
                        toDate(periods.preceding.end),
                        DATE_FORMAT,
                      )}`
                    : ""
                }`}
                subtext={
                  dateRangeLength
                    ? `Requires ${
                        dateRangeLength * 2
                      } months of data starting from ${format(
                        toDate(periods.preceding.start),
                        DATE_FORMAT,
                      )}`
                    : undefined
                }
              />
              <RadioButton
                name="period"
                disabled={
                  pending ||
                  !availableFilters.dates?.includes(periods.lastYear.start)
                }
                checked={selectedPeriod === "last-year"}
                onChange={() => setSelectedPeriod("last-year")}
                label={`Same period last year${
                  dateRangeLength
                    ? `: ${format(
                        toDate(periods.lastYear.start),
                        DATE_FORMAT,
                      )} - ${format(toDate(periods.lastYear.end), DATE_FORMAT)}`
                    : ""
                }`}
                subtext={
                  dateRangeLength
                    ? `Requires ${
                        dateRangeLength + 12
                      } months of data starting from ${format(
                        toDate(periods.lastYear.start),
                        DATE_FORMAT,
                      )}`
                    : undefined
                }
              />
            </div>

            <Collapsible
              className={styles["compare-info"]}
              trigger={
                <span className={styles.heading}>
                  How do date range comparisons work?
                </span>
              }
            >
              <div className={styles["compare-info-content"]}>
                <p>
                  Select a date range of 3 or more months. Choose to compare it
                  with either the ‘Preceding period’ or ‘Same period last year’.
                </p>
                <p>
                  The options available to you will depend on the the number of
                  months you’ve selected and the amount of total tracked data
                  your brand has.
                </p>
                <p>
                  <strong>For example:</strong>
                </p>
                <ul>
                  <li>Nov 2023 – Feb 2024 is selected (4 month period)</li>
                  <li>
                    Preceding period is Jul 2023 – Oct 2023 (requiring 8 months
                    of total data)
                  </li>
                  <li>
                    Same period last year is Nov 2022 – Feb 2023 (requiring 16
                    months of total data)
                  </li>
                </ul>
              </div>
            </Collapsible>
          </div>
        )}
      </div>

      <div className={styles.actions}>
        <Button theme="ghost" label="Cancel" onClick={cancel} />
        <Button
          theme="primary"
          label="Apply"
          disabled={pending || (type === "comparison" && !selectedPeriod)}
          onClick={apply}
        />
      </div>
    </Modal>
  );
};

const DateSelect = ({
  dates,
  selected,
  onSelect,
  ...props
}: {
  dates: string[];
  selected: string | null;
  onSelect(date: string): void;
} & Omit<DropdownProps, "open" | "onChange" | "selected">) => {
  const [open, setOpen] = useState(false);

  return (
    <ControlledDropdown
      open={open}
      onChange={setOpen}
      className={styles.select}
      theme="select"
      selected={selected ? format(toDate(selected), DATE_FORMAT) : undefined}
      constrained
      {...props}
    >
      {dates.map((date) => (
        <DropdownItem
          id="date"
          data-testid="date-option"
          onClick={() => {
            onSelect(date);
            setOpen(false);
          }}
        >
          {format(toDate(date), DATE_FORMAT)}
        </DropdownItem>
      ))}
    </ControlledDropdown>
  );
};
