import type { QuestionType } from "@tracksuit/frontend/schemas";

// TODO: Get rid of this shit
export const Question: { [key in any]: string } = {
  UNPROMPTED_AWARENESS: "Unprompted awareness",
  PROMPTED_AWARENESS: "Awareness",
  CONSIDERATION: "Consideration",
  INVESTIGATION: "Investigation",
  USAGE: "Usage",
  PREFERENCE: "Preference",
  QUALIFYING: "Whole category",
  DEMOGRAPHIC: "Demographic",
  CATEGORY_PENETRATION: "Category penetration",
};
