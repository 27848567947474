import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";

import { useFilters } from "../stores/useFilters";

export const useProfile = () => {
  const [{ question }, filtersReady] = useFilters((s) => [s.filters, s.ready]);
  const accountBrand = useAccount((s) => s.active);
  const { data, isLoading: loading } = apiQueryClient.profile.get.useQuery(
    {
      accountBrandId: accountBrand!.accountBrandId,
      hasTrackers: accountBrand!.trackerTopLevel === "REGIONAL",
      questionType: question,
    },
    {
      enabled: !!accountBrand && filtersReady,
    },
  );

  return {
    data,
    loading,
  };
};
