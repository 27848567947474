import Skeleton from "react-loading-skeleton";

import styles from "./stat.module.css";

type StatProps = {
  value?: number;
  label: string;
  description: string;
  loading: boolean;
};

export const Stat = ({ value, label, description, loading }: StatProps) => {
  return (
    <div className={styles.stat}>
      <span className={styles.value}>
        {loading ? <Skeleton width="5ch" /> : value?.toLocaleString()}
      </span>
      <div>
        <h3 className={styles.label}>{label}</h3>
        <span className={styles.description}>{description}</span>
      </div>
    </div>
  );
};
