import { formatNumberRepresentation } from "$src/lib/utils";
import { Text } from "@visx/text";

import type { DataRepresentation } from "@tracksuit/frontend/schemas";

import type { LineData } from "../line-chart";

const WIDTH = 50;
const HEIGHT = 30;

export function MarkerStart({
  data,
  numberFormat,
  visible,
}: {
  data: LineData;
  numberFormat: DataRepresentation;
  visible: boolean;
}) {
  return (
    <marker
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      markerWidth={WIDTH}
      markerUnits="userSpaceOnUse"
      markerHeight={HEIGHT}
      refX={WIDTH}
      refY={HEIGHT / 2}
      id={`${data.id}-active-start`}
      data-testid="line-chart-marker-start"
    >
      <rect
        width={WIDTH}
        height={HEIGHT}
        ry={HEIGHT / 2}
        fill={data.color}
        visibility={visible ? "visible" : "hidden"}
      />
      <Text
        y={0}
        x={0}
        dx={WIDTH / 2}
        dy={HEIGHT / 2}
        width={WIDTH - 12}
        textAnchor="middle"
        verticalAnchor="middle"
        fill="var(--color-off-white)"
        scaleToFit="shrink-only"
        visibility={visible ? "visible" : "hidden"}
        style={{
          fontWeight: "var(--weight-semibold)",
        }}
      >
        {formatNumberRepresentation(data.points[0]?.y ?? 0, numberFormat)}
      </Text>
    </marker>
  );
}
