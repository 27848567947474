import { z } from "zod";

import { naturalNumber } from "./numbers";

export const userRole = z.enum(["user", "admin", "super_admin"]);

export const user = z.object({
  userId: z.string().uuid(),
  email: z.string().email(),
  role: userRole,
  status: z.string(),
  accounts: z
    .object({
      accountId: naturalNumber,
      accountName: z.string(),
    })
    .array(),
});
