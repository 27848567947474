import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";

export const useSurveyDetails = () => {
  const accountBrand = useAccount((s) => s.active);
  const { data, isLoading: loading } =
    apiQueryClient.surveydetails.get.useQuery(
      {
        accountBrandId: accountBrand!.accountBrandId,
        geographyId: accountBrand!.geography.id,
        clientId: accountBrand!.clientId as number,
      },
      {
        enabled: !!accountBrand,
      },
    );

  return {
    data,
    loading,
  };
};
