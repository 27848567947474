import { Button } from "$src/components/button/button";
import { ControlledCollapsible } from "$src/components/collapsible/collapsible";
import { Input } from "$src/components/input/input";
import { raygun } from "$src/lib/raygun";
import { Auth } from "aws-amplify";
import { type FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./routes.module.css";

const ERRORS = {
  500: (
    <span>
      Something went wrong. Try again or reach out to our{" "}
      <a href="https://help.gotracksuit.com/">Customer Team</a>.
    </span>
  ),
};

export function ActivateRoute() {
  const [newPassword, setNewPassword] = useState("");
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [error, setError] = useState<keyof typeof ERRORS | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.has("username") || !searchParams.has("password")) {
      navigate("/login", { replace: true });
    }
  }, [search, searchParams]);

  async function activate(e: FormEvent) {
    e.preventDefault();
    const username = searchParams.get("username") as string;
    let password = searchParams.get("password") as string;

    // HACK for our hacky flow
    if (!password.endsWith("!")) {
      password = `${password}!`;
    }

    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(user, newPassword);
        navigate("/");
      } else {
        throw new Error("Unexpected challenge name");
      }
    } catch (err) {
      setError(500);
      console.error(err);
      raygun?.("send", err);
    }
  }

  return (
    <form onSubmit={activate}>
      <ControlledCollapsible open={!!error} fast>
        <div className={styles.error}>{error && ERRORS[error]}</div>
      </ControlledCollapsible>
      <Input
        className={styles.input}
        type="password"
        label="New Password"
        value={newPassword}
        minLength={8}
        required
        onChange={({ target }) => setNewPassword(target.value)}
      />
      <div className={styles.actions}>
        <Button className={styles.action} type="submit" label="Set Password" />
      </div>
    </form>
  );
}
