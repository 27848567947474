import { Button } from "$src/components/button/button";
import { Checkbox } from "$src/components/checkbox/checkbox";
import { Input } from "$src/components/input/input";
import { useAmplifyAuth } from "$src/hooks/useAmplifyAuth";
import { UserStatus } from "$src/models/User";
import { useSettings } from "$src/queries/useSettings";
import { useAdminSettings } from "$src/stores/useAdminSettings";
import {
  useDebouncedEffect,
  useDeepCompareEffect,
  useIntersectionObserver,
} from "@react-hookz/web";
import Fuse from "fuse.js";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Search } from "lucide-react";
import { useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import type { BFFOutput } from "@tracksuit/bff/trpc";

import { Column } from "../column/column";
import { UserRow } from "../row/user-row/user-row";
import styles from "./users-view.module.css";

const PAGE_LENGTH = 25;

type SettingsListResponse = BFFOutput["settings"]["listUsers"];

export const UsersView = () => {
  const { data, loading } = useSettings();
  const { user } = useAmplifyAuth();
  const [editing, setEditing] = useState<
    NonNullable<SettingsListResponse>[0] | null
  >();
  const endRef = useRef<HTMLDivElement>(null);
  const [fuse, setFuse] =
    useState<Fuse<NonNullable<SettingsListResponse>[0]>>();
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState<SettingsListResponse>([]);
  const [page, setPage] = useState<number>(1);
  const { removeIncompleteMonths } = useFlags();
  const [adminSettings, setAdminSettings] = useAdminSettings((s) => [
    s.settings,
    s.set,
  ]);
  const intersection = useIntersectionObserver(endRef);

  useDeepCompareEffect(() => {
    if (!data) {
      return;
    }

    setFuse(
      new Fuse(data, {
        keys: ["email", "accounts.accountName"],
        threshold: 0.4,
      }),
    );
  }, [data]);

  useDebouncedEffect(
    () => {
      if (fuse) {
        setUsers(
          (!search ? data : fuse.search(search).map(({ item }) => item)) ?? [],
        );
      }
    },
    [search, data, fuse],
    100,
  );

  useDebouncedEffect(
    () => {
      if (intersection?.isIntersecting) {
        const pages = Math.ceil((users?.length ?? 0) / PAGE_LENGTH);
        setPage((page) => (pages > page ? page + 1 : pages));
      }
    },
    [intersection?.isIntersecting, users?.length],
    250,
  );

  return (
    <>
      <h2 className={styles.title}>
        Create and manage Tracksuit users and their permissions
      </h2>
      {user?.attributes["custom:isadmin"] === "Y" && (
        <>
          <Checkbox
            className={styles["admin-toggle"]}
            label="Impersonate anonymous user"
            checked={adminSettings.impersonateAnonymous}
            onChange={(checked) =>
              setAdminSettings({
                impersonateAnonymous: checked,
              })
            }
          />
          {removeIncompleteMonths && (
            <Checkbox
              className={styles["admin-toggle"]}
              label="Show incomplete months"
              checked={adminSettings.includeIncompleteMonths}
              onChange={(checked) =>
                setAdminSettings({
                  includeIncompleteMonths: checked,
                })
              }
            />
          )}
        </>
      )}
      <div className={styles.nav}>
        <div className={styles.search}>
          <Input
            label="Search"
            icon={Search}
            className={styles.input}
            value={search}
            clearable
            onClear={() => setSearch("")}
            onChange={({ target }) => setSearch(target.value)}
          />
          <span>Total Users: {users?.length}</span>
        </div>
        <Button
          label="Add user"
          onClick={() =>
            setEditing({
              userId: "",
              email: "",
              role: "user",
              status: UserStatus.CONFIRMED,
              accounts: [],
            })
          }
          disabled={!!editing}
          className={styles.button}
        />
      </div>
      <div className={styles.list}>
        <Column header first>
          Email
        </Column>
        <Column header>Role</Column>
        <Column header>Access to</Column>
        <Column header>Status</Column>
        <Column header last></Column>

        {loading &&
          Array.from({ length: 5 })
            .fill(undefined)
            .map((_, i) => (
              <Column
                first={i === 0}
                last={i === 4}
                hoverable={false}
                key={i}
                data-testid="skeleton"
              >
                <Skeleton width="10ch" />
              </Column>
            ))}

        {editing?.userId === "" && (
          <UserRow
            key="new-user"
            user={editing}
            editing
            onEdit={(edit: any) => !edit && setEditing(null)}
          />
        )}

        {users?.slice(0, PAGE_LENGTH * page).map((user: any) => (
          <UserRow
            key={user.userId}
            user={user}
            onEdit={(enabled: any) =>
              enabled ? setEditing(user) : setEditing(null)
            }
            editing={editing === user}
          />
        ))}
      </div>
      <div ref={endRef} />
    </>
  );
};
