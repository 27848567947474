import {
  differenceInCalendarMonths,
  differenceInMonths,
  format,
  isSameMonth,
  subMonths,
} from "date-fns";

import { type DateComparisonPeriod } from "@tracksuit/frontend/schemas";

export enum TimePeriod {
  NoMonths = "Since start",
  ThreeMonths = "Last 3 months",
  SixMonths = "Last 6 months",
  TwelveMonths = "Last 12 months",
  Custom = "Custom date range",
  AllTime = "All time",
}

/**
 * Convert string to UTC date object and and respect user's timezone
 */
export const toDate = (date: string | number | Date) => {
  return new Date(
    new Intl.DateTimeFormat("en-US", {
      timeZone: "Pacific/Auckland",
    }).format(new Date(date)),
  );
};

/**
 * Formats a date range
 */
export const formatDateRange = (start?: string, end?: string) => {
  const dateFormat = "MMM yyyy";

  if (!start) {
    return;
  }

  if (!end) {
    return `Since ${format(toDate(start), "MMMM")}`;
  }

  return `${format(toDate(start), dateFormat)} — ${format(
    toDate(end),
    dateFormat,
  )}`;
};

/**
 * Gets a date period
 */
export const getDatePeriod = (
  start?: string,
  end?: string,
  period?: DateComparisonPeriod,
) => {
  if (!start || !end || !period) {
    return {
      start: "",
      end: "",
      period: 0,
    };
  }

  const periodLength =
    differenceInCalendarMonths(toDate(end), toDate(start)) + 1;
  const getPeriodPoint = (date: string, length: number) =>
    format(subMonths(toDate(date), length), "y-MM-dd");
  let periodStart;
  let periodEnd;

  switch (period) {
    case "preceding":
      periodStart = getPeriodPoint(start, periodLength);
      periodEnd = getPeriodPoint(end, periodLength);
      break;
    case "last-year":
      periodStart = getPeriodPoint(start, 12);
      periodEnd = getPeriodPoint(end, 12);
      break;
  }

  return {
    start: periodStart,
    end: periodEnd,
    period: Number(periodLength),
  };
};

export const getDateRangeLabel = (
  start?: string,
  end?: string,
  customRangeLabel?: boolean,
) => {
  if (!start || !end) {
    return "";
  }

  const fallbackDateLabel =
    customRangeLabel === true
      ? "Custom date range"
      : formatDateRange(start, end);
  const duration = differenceInMonths(toDate(end), toDate(start)) + 1;

  if (!isSameMonth(toDate(end), new Date())) {
    return fallbackDateLabel;
  }

  switch (duration) {
    case 3:
      return TimePeriod.ThreeMonths;
    case 6:
      return TimePeriod.SixMonths;
    case 12:
      return TimePeriod.TwelveMonths;
    default:
      return fallbackDateLabel;
  }
};

export const getComparisonPeriodLabel = (period?: DateComparisonPeriod) => {
  if (!period) {
    return undefined;
  }

  return period === "preceding" ? "Preceding period" : "Same period last year";
};
