import { type RouteObject } from "react-router-dom";

import { FunnelLayout } from "./layout";
import { FunnelComparisonRoute } from "./routes/comparison/comparison";
import { FunnelOverviewRoute } from "./routes/overview/overview";
import { FunnelTimelineLayout } from "./routes/timeline/layout";
import { FunnelTimelineMetricRoute } from "./routes/timeline/metric/metric";
import { FunnelTimelineOverviewRoute } from "./routes/timeline/overview/overview";

export const funnelRoutes: RouteObject["children"] = [
  {
    Component: FunnelLayout,
    children: [
      {
        index: true,
        Component: FunnelOverviewRoute,
      },
      {
        path: "comparison/:question?",
        Component: FunnelComparisonRoute,
      },
      {
        path: "timeline",
        Component: FunnelTimelineLayout,
        children: [
          {
            index: true,
            Component: FunnelTimelineOverviewRoute,
          },
          {
            path: "metric/:question?",
            Component: FunnelTimelineMetricRoute,
          },
        ],
      },
    ],
  },
];
