import { Button } from "$src/components/button/button";
import {
  ControlledDropdown,
  DropdownItem,
} from "$src/components/dropdown/dropdown";
import { Modal } from "$src/components/modal/modal";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { regionalize } from "$src/lib/regionalization";
import { cx } from "$src/lib/utils";
import { useAccount } from "$src/stores/useAccount";
import { sentenceCase } from "change-case";
import ky from "ky";
import { useFlags } from "launchdarkly-react-client-sdk";
import { type ComponentProps, useCallback, useEffect, useState } from "react";

import type { ExportFormat } from "@tracksuit/frontend/schemas";

import styles from "./download.module.css";

export type DownloadProps = {
  /** Whether download modal is open */
  open: boolean;
  /** On modal close callback */
  onClose(): void;
  /** The download fetcher to run */
  fetcher: (type?: string) => Promise<string | undefined>;
  /** Heading for the modal */
  heading: string;
  /** Format to download as */
  format: ExportFormat;
  /** Format label */
  label: string;
  /** List of optional subtypes */
  types?: string[];
  /** Optional stonly ID for a guide */
  stonly?: string;
} & ComponentProps<"div">;

const FORMAT_LABEL: { [format in ExportFormat]: string } = {
  CSV: "(.csv)",
  XLSX: "(Excel)",
};

/**
 * @component
 * Download button
 */
export const Download = ({
  open,
  onClose,
  fetcher,
  heading,
  format,
  label,
  types,
  stonly,
  children,
}: DownloadProps) => {
  const [type, setType] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const analytics = useAnalytics();
  const account = useAccount((s) => s.active);
  const { customQuestionsDownload } = useFlags();

  const filterSubtype = useCallback(
    (subtype: string) => {
      switch (subtype) {
        case "STATEMENTS":
          return account?.featuresConfig.statementsEnabled;
        case "UNPROMPTED":
          return account?.featuresConfig.unpromptedEnabled;
        case "IMAGERY":
          return !account?.featuresConfig.imageryEnabled;
        case "CUSTOM_QUESTIONS":
          return customQuestionsDownload;
        default:
          return true;
      }
    },
    [account, customQuestionsDownload],
  );

  /* v8 ignore next */
  // This is better tested with E2E rather than mocking out our Downloads API
  const download = () => {
    setLoading(true);
    const checkUrl = async (exportUrl: string, timeout: number = 0) => {
      try {
        const res = await ky.get(exportUrl, {
          headers: {
            Range: "bytes=0-0",
          },
          throwHttpErrors: false,
        });

        if (res.ok) {
          setLoading(false);
          window.open(exportUrl);
          onClose();
          analytics?.track(AnalyticsEvents.Download, {
            format,
            type,
          });
        } else if (
          (res.status === 403 || res.status === 404) &&
          timeout < 120 * 1000
        ) {
          setTimeout(() => {
            checkUrl(exportUrl, timeout + 1000);
          }, 1000);
        } else {
          throw new Error(res.statusText);
        }
      } catch (e) {
        onClose();
        throw new Error(e as any);
      }
    };
    fetcher(type)
      .then((exportUrl?: string) => {
        checkUrl(exportUrl ?? "", 0);
      })
      .catch((error) => {
        throw new Error(`${error}`);
      });
  };

  // v8 ignore next
  const openStonly = (guideId: string) => {
    window.StonlyWidget("openGuide", { guideId });
  };

  useEffect(() => {
    if (!type && types) {
      setType(types[0]);
    }
  }, [types]);

  return (
    <Modal open={open} onClose={onClose} heading={heading}>
      <div className={styles.text}>
        {children}
        <p>
          <span
            className={styles.stonly}
            onClick={() => {
              openStonly(stonly ?? "");
              onClose();
            }}
          >
            Click here
          </span>{" "}
          for more formats.
        </p>
      </div>
      {
        <ControlledDropdown
          disabled={
            loading || Number(types) < 2 || !types?.filter(filterSubtype)
          }
          className={cx(styles.filetype)}
          theme="select"
          label={label}
          selected={type ? sentenceCase(type) : FORMAT_LABEL[format]}
          open={openDropdown}
          onChange={setOpenDropdown as any}
        >
          {types?.filter(filterSubtype).map((t) => (
            <DropdownItem
              id="competitor-dropdown"
              onClick={() => {
                setType(t);
                setOpenDropdown(false);
              }}
              active={t === type}
              key={t}
            >
              {sentenceCase(regionalize(t, account?.geography.name))}
            </DropdownItem>
          ))}
        </ControlledDropdown>
      }

      <div className={styles.actions}>
        <Button
          className={styles.action}
          theme="ghost"
          label="Cancel"
          onClick={onClose}
          disabled={loading}
        />
        <Button
          className={styles.action}
          label="Download"
          onClick={download}
          disabled={loading}
        />
      </div>
    </Modal>
  );
};
