import { z } from "zod";

const AddOnKind = z.enum([
  "STATEMENTS",
  "SUB_CATEGORIES",
  "UNPROMPTED_AWARENESS",
  "ADDITIONAL_QUESTIONS",
  "EXTRA_DASHBOARD",
  "ADDITIONAL_SAMPLE",
  "INSIGHT_REPORTS",
  "API_ACCESS",
  "OTHER",
]);

type AddOnKind = z.infer<typeof AddOnKind>;

const AddOnCheck = z.object({
  id: z.number(),
  kind: AddOnKind,
  enabled: z.boolean(),
});

export const SurveyAddOn = z.discriminatedUnion("kind", [
  AddOnCheck.extend({
    kind: z.literal(AddOnKind.enum.STATEMENTS),
    content: z.null(),
  }),
  AddOnCheck.extend({
    kind: z.literal(AddOnKind.enum.SUB_CATEGORIES),
    content: z.null(),
  }),
  AddOnCheck.extend({
    kind: z.literal(AddOnKind.enum.UNPROMPTED_AWARENESS),
    content: z
      .object({
        goal: z.string().min(1),
        brandAliases: z.object({ value: z.string().min(1) }).array(),
      })
      .nullish(),
  }),
  AddOnCheck.extend({
    kind: z.literal(AddOnKind.enum.ADDITIONAL_QUESTIONS),
    content: z.null(),
  }),
  AddOnCheck.extend({
    kind: z.literal(AddOnKind.enum.EXTRA_DASHBOARD),
    content: z.null(),
  }),
  AddOnCheck.extend({
    kind: z.literal(AddOnKind.enum.ADDITIONAL_SAMPLE),
    content: z
      .object({
        quantity: z.coerce.number().positive().int(),
      })
      .nullish(),
  }),
  AddOnCheck.extend({
    kind: z.literal(AddOnKind.enum.INSIGHT_REPORTS),
    content: z
      .discriminatedUnion("cadence", [
        z.object({
          cadence: z.literal("One-off"),
          deliveryDate: z.string(),
          reportContent: z.string().min(1),
          billed: z.coerce.boolean(),
        }),
        z.object({
          cadence: z.enum(["Quarterly", "Bi-annually", "Annually"]),
          deliverySchedule: z.string().min(1),
          reportContent: z.string().min(1),
          billed: z.coerce.boolean(),
        }),
      ])
      .nullish(),
  }),
  AddOnCheck.extend({
    kind: z.literal(AddOnKind.enum.API_ACCESS),
    content: z.null(),
  }),
  AddOnCheck.extend({
    kind: z.literal(AddOnKind.enum.OTHER),
    content: z
      .object({
        billed: z.coerce.boolean(),
      })
      .nullish(),
  }),
]);

export type SurveyAddOn = z.infer<typeof SurveyAddOn>;

export type SurveyAddOnMap = {
  [K in AddOnKind]?: Extract<SurveyAddOn, { kind: K }>;
};
