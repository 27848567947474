import { useEffect, useState } from "react";

import { useAmplifyAuth } from "../useAmplifyAuth";
import trailblazers from "./trailblazers.json";

export const useIsTrailblazer = () => {
  const { user } = useAmplifyAuth();
  const [isTrailblazer, setIsTrailblazer] = useState(false);

  useEffect(() => {
    if (user) {
      setIsTrailblazer(
        trailblazers.includes(user.attributes.email?.toLowerCase() ?? ""),
      );
    }
  }, [user]);

  return isTrailblazer;
};
