import { Switch } from "$src/components/switch/switch";
import { Tooltip, type TooltipProps } from "$src/components/tooltip/tooltip";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useFilters } from "$src/stores/useFilters";
import { useEffect } from "react";

export type DataRepresentationProps = Partial<TooltipProps>;

/**
 * @component
 * Set data representation for a view
 */
export const ToggleDataRepresentation = ({
  ...props
}: DataRepresentationProps) => {
  const [{ dataRepresentation, hasNoPopulationData }, setFilters] = useFilters(
    (s) => [s.filters, s.set],
  );
  const analytics = useAnalytics();

  useEffect(() => {
    dataRepresentation &&
      analytics?.track(AnalyticsEvents.ChangeDataType, {
        type: dataRepresentation,
      });
  }, [dataRepresentation, analytics]);

  return (
    <Tooltip
      disabled={!hasNoPopulationData}
      title={`Why is some data missing?`}
      tip={
        "We can only estimate the # of people with Age, Gender, or Region filters."
      }
      data-x-hidden-from-screenshot
      {...props}
    >
      <Switch
        labels={{
          off: "%",
          on: "#",
        }}
        active={dataRepresentation === "population"}
        disabled={hasNoPopulationData}
        onChange={(active) =>
          setFilters({
            dataRepresentation: active ? "population" : "percentage",
          })
        }
      />
    </Tooltip>
  );
};
