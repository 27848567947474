import { Tooltip, type TooltipProps } from "$src/components/tooltip/tooltip";
import { cx } from "$src/lib/utils";
import type { Placement } from "@floating-ui/react-dom";
import type { LucideIcon } from "lucide-react";
import { type ComponentPropsWithoutRef, forwardRef } from "react";

import styles from "./icon-button.module.css";

export type IconButtonProps = {
  /** Icon for the button */
  icon: LucideIcon;
  /** Optional active state */
  active?: boolean;
  /** Optional large version */
  large?: boolean;
  /** Optional disabled state */
  disabled?: boolean;
  /** Optional hidden state */
  hidden?: boolean;
  /** Optional tooltip title */
  title?: string;
  /** Optional tooltip */
  tip?: string;
  /** Optional tip placement */
  tipPlacement?: Placement;
} & Partial<TooltipProps>;

/**
 * @component
 * Simple button with icon
 */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      icon: Icon,
      active = false,
      large = false,
      disabled = false,
      hidden = false,
      title,
      tip,
      tipPlacement,
      className,
      ...props
    },
    ref,
  ) => (
    <Tooltip
      className={cx(
        styles.button,
        active && styles.active,
        large && styles.large,
        disabled && styles.disabled,
        hidden && styles.hidden,
        className,
      )}
      placement={tipPlacement}
      disabled={tip === undefined}
      title={title}
      tip={tip}
      {...props}
    >
      <button ref={ref} data-testid="icon-button">
        <Icon data-testid="icon" className={styles.icon} />
      </button>
    </Tooltip>
  ),
);
IconButton.displayName = "IconButton";
