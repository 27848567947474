import { Layout } from "$src/components/layout/layout";
import { Milestones } from "$src/views/milestones/milestones";

import styles from "./milestones.module.css";

export const MilestonesRoute = () => {
  return (
    <Layout>
      <h2 className={styles.heading}>Milestones</h2>
      <div className={styles.container}>
        <Milestones />
      </div>
    </Layout>
  );
};
