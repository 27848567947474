import { cx } from "$src/lib/utils";
import { motion } from "framer-motion";
import { type ComponentProps } from "react";

import styles from "./radial-bar-chart.module.css";

export type RadialBarChartProps = {
  /** Percentage complete for the bar */
  value: number;
  /** Size (width) of the chart */
  size?: number;
  /** Width of the bar */
  barWidth?: number;
} & ComponentProps<"svg">;

/**
 * @component
 * Radial style progress bar chart
 */
export function RadialBarChart({
  value,
  size = 220,
  barWidth = 36,
  className,
  ...props
}: RadialBarChartProps) {
  return (
    <svg
      className={cx(className, styles.chart)}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      data-testid="radial-bar-chart"
      {...props}
    >
      <circle
        className={styles.track}
        cx={size / 2}
        cy={size / 2}
        r={size / 2 - barWidth / 2}
        strokeWidth={barWidth}
        fill="none"
      />
      <motion.circle
        initial={{ strokeDashoffset: 100 }}
        animate={{
          strokeDashoffset: 100 - value * 100,
          transition: { duration: value },
        }}
        className={styles.bar}
        cx={size / 2}
        cy={size / 2}
        r={size / 2 - barWidth / 2}
        strokeWidth={barWidth}
        pathLength={100}
        fill="none"
        data-testid="radial-bar-chart-bar"
      />
    </svg>
  );
}
