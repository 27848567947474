import { Layout } from "$src/components/layout/layout";
import { NavBar } from "$src/components/nav-bar/nav-bar";
import { useAccount } from "$src/stores/useAccount";
import { Navigate, Outlet } from "react-router-dom";

import styles from "./layout.module.css";

export const StatementsLayout = () => {
  const accountBrand = useAccount((s) => s.active);

  return !!accountBrand && !accountBrand?.featuresConfig.statementsEnabled ? (
    <Navigate to="/funnel" replace />
  ) : (
    <>
      <NavBar
        items={[
          { label: "Overview", pathname: "/statements" },
          {
            label: "Comparison",
            pathname: "/statements/comparison",
            matchSubpaths: true,
          },
        ]}
      />
      <Layout>
        <div className={styles.container}>
          <Outlet />
        </div>
      </Layout>
    </>
  );
};
