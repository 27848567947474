import { cx, percentageFormatter } from "$src/lib/utils";
import { ArrowDownRight, ArrowUpRight, Minus } from "lucide-react";
import { type ComponentProps, type Ref, forwardRef, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { Tooltip } from "../tooltip/tooltip";
import styles from "./difference-label.module.css";

export type DifferenceLabelProps = {
  /** Difference change in percentage */
  value: number;
  /** Whether the difference is statistically significant */
  significant?: boolean;
  /** Whether the value is loading */
  loading?: boolean;
} & ComponentProps<"div">;

export const DIRECTIONS = {
  positive: {
    icon: ArrowUpRight,
    color: "var(--color-green-500)",
  },
  negative: {
    icon: ArrowDownRight,
    color: "var(--color-red-700)",
  },
  neutral: {
    icon: Minus,
    color: "var(--color-purple-800)",
  },
};

/**
 * @component
 * Label showing the difference change in a number
 */
export const DifferenceLabel = forwardRef(function DifferenceLabel(
  {
    value,
    significant,
    loading = false,
    className,
    ...props
  }: DifferenceLabelProps,
  ref: Ref<HTMLDivElement>,
) {
  const direction = useMemo(() => {
    // TODO: Remove this hacky rounding once rounding done API side
    if (Number(value.toFixed(2)) === 0) {
      return "neutral";
    }
    return Number(value.toFixed(2)) > 0 ? "positive" : "negative";
  }, [value]);

  const Icon = DIRECTIONS[direction].icon;
  const formatLabel = (val: number) =>
    Number(val.toFixed(2)) === 0
      ? "No change"
      : percentageFormatter.format(val).replace("-", "");

  return (
    <div
      className={cx(
        styles.difference,
        direction === "neutral" && styles.neutral,
        className,
      )}
      data-testid="difference-label"
      ref={ref}
      {...props}
    >
      <Tooltip
        tip={
          significant === true ? (
            <span>This is a statistically significant change.</span>
          ) : (
            <span>This isn’t yet significant</span>
          )
        }
        disabled={value === 0}
      >
        <div
          className={styles.inner}
          style={{
            color:
              significant === true
                ? DIRECTIONS[direction].color
                : "var(--color-purple-700)",
          }}
        >
          {loading ? (
            <div data-testid="loading-skeleton">
              <Skeleton width="5ch" />
            </div>
          ) : (
            <>
              {direction !== "neutral" && (
                <Icon
                  className={styles.icon}
                  data-testid={`${direction}-icon`}
                />
              )}
              <span className={styles.label}>{formatLabel(value)}</span>
            </>
          )}
        </div>
      </Tooltip>
    </div>
  );
});
