import { useMemo } from "react";

import { useAvailableFilters } from "../queries/useAvailableFilters";

export const useRequiredDatesCount = (length: number) => {
  const { availableFilters } = useAvailableFilters();
  const sufficient = useMemo(
    () => !!availableFilters.dates && availableFilters.dates.length >= length,
    [availableFilters.dates],
  );

  return sufficient;
};
