import { useEffect, useState } from "react";

export function useFocus(ref: React.RefObject<HTMLElement>) {
  const [focus, setFocus] = useState(false);
  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  useEffect(() => {
    const node = ref.current;
    setFocus(node === document.activeElement);
    if (node) {
      node.addEventListener("focus", handleFocus);
      node.addEventListener("blur", handleBlur);
      return () => {
        node.removeEventListener("focus", handleFocus);
        node.removeEventListener("blur", handleBlur);
      };
    }
  }, [ref]);

  return focus;
}
