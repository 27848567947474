import { cx } from "$src/lib/utils";
import type { ComponentProps, MouseEvent } from "react";

import styles from "./switch.module.css";

export type SwitchProps = {
  /** Whether the switch is enabled */
  active: boolean;
  /** Labels for the switch */
  labels: { off: string; on: string };
  /** Callback when changing options */
  onChange(active: boolean): void;
  /** Whether the switch is disabled */
  disabled?: boolean;
} & Omit<ComponentProps<"div">, "onChange">;

/**
 * @component
 * UI switch
 */
export function Switch({
  active,
  labels,
  onChange,
  disabled,
  className,
  ...props
}: SwitchProps) {
  const handleChange = (e: MouseEvent, state: boolean) => {
    e.stopPropagation();
    onChange(state);
  };

  return (
    <div
      className={cx(
        styles.switch,
        disabled === true && styles.disabled,
        className,
      )}
      {...props}
    >
      <div className={styles["switch-inner"]}>
        <span
          onClick={(e) => handleChange(e, false)}
          className={cx(styles.option, !active && styles.active)}
        >
          {labels.off}
        </span>
        <span
          onClick={(e) => handleChange(e, true)}
          className={cx(styles.option, active && styles.active)}
        >
          {labels.on}
        </span>
      </div>
      <div className={cx(styles.button, active && styles.active)} />
    </div>
  );
}
