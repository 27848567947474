import { ColumnChart } from "$src/components/column-chart/column-chart";
import { RadialBarChart } from "$src/components/radial-bar-chart/radial-bar-chart";
import { percentageFormatter } from "$src/lib/utils";
import { useMeasure } from "@react-hookz/web";
import {
  type CSSProperties,
  type ComponentProps,
  Fragment,
  type ReactNode,
} from "react";

import { COLORS, Profile } from "../profile";
import styles from "./profile-graphic.module.css";

export type ProfileGraphicProps = {
  /** Filter type for the profile */
  type: string;
  /** Data for the graphic */
  data: Profile[];
} & ComponentProps<"div">;

/* v8 ignore next */
const SVGGradient = ({
  data,
  viewBox,
  children,
}: {
  data: Profile[];
  viewBox: string;
  children: ReactNode;
}) => {
  let sum = 0;
  const total = data.reduce((acc, { percentage }) => acc + percentage, 0);
  const runningPercentage = data.map(({ percentage }) => (sum += percentage));

  return (
    <svg width="100%" viewBox={viewBox} data-testid="svg-gradient">
      <defs>
        <linearGradient id="gradient" gradientTransform="rotate(90)">
          {data.map((item, i) => (
            <Fragment key={i}>
              <stop
                offset={percentageFormatter.format(
                  runningPercentage[i - 1] ?? 0 / total,
                )}
                stopColor={COLORS[i]}
              />
              <stop
                offset={percentageFormatter.format(
                  runningPercentage[i] ?? 0 / total,
                )}
                stopColor={COLORS[i]}
              />
            </Fragment>
          ))}
        </linearGradient>
      </defs>
      {children}
    </svg>
  );
};

/**
 * @component
 * Graphic for profile cards
 */
export const ProfileGraphic = ({ type, data }: ProfileGraphicProps) => {
  const [measures, radialRef] = useMeasure();

  switch (type) {
    case "Gender":
      return (
        <div ref={radialRef as any}>
          <RadialBarChart
            size={measures?.width}
            style={
              {
                "--track-color": COLORS[1],
                "--bar-color": COLORS[0],
              } as CSSProperties
            }
            barWidth={24}
            value={data[0]?.percentage ?? 0}
          />
        </div>
      );
    case "Age":
      return (
        <div className={styles.age}>
          <ColumnChart
            compact
            theme="rounded"
            data={data.map((item, i) => ({
              value: item.percentage,
              color: COLORS[i] ?? "var(--color-purple-500)",
            }))}
          />
        </div>
      );
    case "Region":
    case "Region - New Zealand":
    case "Region - Australia":
    case "Region - United States":
    case "Census division":
    case "Region - United Kingdom":
    case "Region - Auckland":
    case "Region - Canada":
      return (
        <SVGGradient data={data} viewBox="0 0 146 146">
          <path
            fill="url(#gradient)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121.331 44.721C121.331 52.6862 118.96 60.1539 114.817 66.5857L80.7311 140.871C77.9019 147.037 69.158 147.089 66.2374 140.958L31.5281 68.0906C26.7604 61.3309 24 53.3155 24 44.721C24 20.5745 45.7882 1 72.6653 1C99.5424 1 121.331 20.5745 121.331 44.721ZM73.0922 59.9106C83.2301 59.9106 91.4484 51.6922 91.4484 41.5544C91.4484 31.4165 83.2301 23.1982 73.0922 23.1982C62.9544 23.1982 54.736 31.4165 54.736 41.5544C54.736 51.6922 62.9544 59.9106 73.0922 59.9106Z"
          />
        </SVGGradient>
      );
    case "Income":
    case "Household income":
      return (
        <SVGGradient data={data} viewBox="0 0 146 146">
          <path
            fill="url(#gradient)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.9376 112.052V13.1041C59.9376 6.15065 65.5745 0.513763 72.528 0.513763C79.4814 0.513763 85.1183 6.15065 85.1183 13.1041V112.105C88.7373 115.574 91 120.515 91 126C91 136.493 82.7173 145 72.5 145C62.2827 145 54 136.493 54 126C54 120.487 56.286 115.523 59.9376 112.052Z"
          />
        </SVGGradient>
      );
    case "Ethnicity":
      return (
        <SVGGradient data={data} viewBox="0 0 146 146">
          <path
            fill="url(#gradient)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M92.1607 69.9853C103.64 63.7139 111.336 52.1847 111.336 39C111.336 19.1177 93.8363 3 72.2496 3C50.663 3 33.1635 19.1177 33.1635 39C33.1635 52.3832 41.0924 64.0607 52.8597 70.265C28.7411 80.9078 11.3301 110.291 11 144.995C10.9948 145.548 11.4245 146 11.9768 146H72.833H133.689C134.242 146 134.671 145.548 134.666 144.995C134.333 109.982 116.614 80.3852 92.1607 69.9853Z"
          />
        </SVGGradient>
      );
    case "Household Status":
    case "Household status":
      return (
        <SVGGradient data={data} viewBox="0 0 146 146">
          <path
            fill="url(#gradient)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71.4755 1.37256C72.3011 0.972921 73.2642 0.972921 74.0897 1.37256L123.868 25.4684C126.736 26.8568 125.747 31.1687 122.561 31.1687H120.519V75H120.519V139.537C120.519 142.298 118.28 144.537 115.519 144.537H88.5186C88.6128 144.114 88.6625 143.675 88.6625 143.225V112.741C88.6625 109.427 85.9762 106.741 82.6625 106.741H62.9484C59.6347 106.741 56.9484 109.427 56.9484 112.741V143.225C56.9484 143.675 56.9981 144.114 57.0923 144.537H30.1987C27.4373 144.537 25.1987 142.298 25.1987 139.537V77H25.1986V31.1687H23.0044C19.8178 31.1687 18.829 26.8568 21.6972 25.4684L71.4755 1.37256Z"
          />
          ;
        </SVGGradient>
      );
    default:
      return null;
  }
};
