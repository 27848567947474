import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";

export const useStatementsList = () => {
  const accountBrand = useAccount((s) => s.active);
  const { data, isLoading: loading } =
    apiQueryClient.statements.getList.useQuery(
      {
        accountBrandId: accountBrand!.accountBrandId,
      },
      {
        enabled: !!accountBrand,
      },
    );

  return {
    data,
    loading,
  };
};
