import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";

export const useFunnelTimelineMetricSummary = ({
  enabled,
}: {
  enabled: boolean;
}) => {
  const accountBrand = useAccount((s) => s.active);
  const [{ dateRange, brandId, brandIdList, demographics }, filtersReady] =
    useFilters((s) => [s.filters, s.ready]);

  const {
    data,
    isLoading: loading,
    error,
  } = apiQueryClient.timeline.getMetricSummary.useQuery(
    {
      accountBrandId: accountBrand!.accountBrandId,
      brandId: brandId!,
      demographicFilterIds: demographics?.map(({ id }) => id) ?? [],
      waveRange: { start: dateRange.start!, end: dateRange.end! },
      includedBrandIds: brandIdList,
    },
    {
      enabled: filtersReady && !!accountBrand && enabled,
    },
  );

  return {
    data,
    loading,
    error,
  };
};
