import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";

export const useFunnel = () => {
  const account = useAccount((s) => s.active);
  const [{ datePeriod, demographics }, filtersReady] = useFilters((s) => [
    s.filters,
    s.ready,
  ]);
  const { data, isLoading: loading } =
    apiQueryClient.funnel.getMetrics.useQuery(
      {
        accountBrandId: account!.accountBrandId,
        startDate: datePeriod.start!,
        endDate: datePeriod.end!,
        filters: demographics?.map(({ filter }) => filter) ?? [],
        filterIds: demographics?.map(({ id }) => id ?? "") ?? [],
      },
      {
        enabled: !!account && filtersReady,
      },
    );

  return {
    data,
    loading,
  };
};
