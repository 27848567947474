import { useState } from "react";

export const imageryThemes = [
  "All themes",
  "Common themes",
  "Unique themes",
] as const;

export type ImageryTheme = (typeof imageryThemes)[number];

export function useTheme(defaultTheme: ImageryTheme = "All themes") {
  const [theme, setTheme] = useState<ImageryTheme>(defaultTheme);
  const toggleTheme = (theme: ImageryTheme) =>
    setTheme((currentTheme) => (theme === currentTheme ? defaultTheme : theme));
  return [theme, toggleTheme, setTheme] as const;
}
