import { RouteSelector } from "$src/components/route-selector/route-selector";
import { StatSigExplainer } from "$src/components/stat-sig-explainer/stat-sig-explainer";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useFunnelQuestions } from "$src/hooks/useFunnelQuestions";
import { useValidatedParam } from "$src/hooks/useValidatedParam";
import { COMPETITOR_AVERAGE_ID } from "$src/lib/consts";
import { regionalize } from "$src/lib/regionalization";
import { Question } from "$src/models/Funnel";
import { useFunnelComparison } from "$src/queries/funnel";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { Comparison } from "$src/views/comparison/comparison";
import { FilterBar } from "$src/views/filter-bar/filter-bar";
import { constantCase, sentenceCase } from "change-case";
import { useCallback, useEffect, useMemo, useRef } from "react";

import type { BFFOutput } from "@tracksuit/bff/trpc";
import { FunnelQuestionType } from "@tracksuit/frontend/schemas";
import {
  formatDateRange,
  getComparisonPeriodLabel,
  getDatePeriod,
  getDateRangeLabel,
  sortBrands,
} from "@tracksuit/frontend/utils";

import styles from "./comparison.module.css";

export const FunnelComparisonRoute = () => {
  const availableQuestions = useFunnelQuestions({});
  const validateQuestion = useCallback(
    (q: FunnelQuestionType) => availableQuestions.includes(q),
    [availableQuestions],
  );
  const question = useValidatedParam<FunnelQuestionType>({
    param: "question",
    deseralize: constantCase,
    validate: validateQuestion,
    fallback: "/funnel/comparison/prompted-awareness",
  });
  const [{ datePeriod, dataRepresentation, brandIdList }] = useFilters((s) => [
    s.filters,
  ]);
  const account = useAccount((s) => s.active);
  const analytics = useAnalytics();
  const comparisonRef = useRef<HTMLDivElement>(null);
  const { data, sufficient, loading } = useFunnelComparison({ question });
  const getQuestionLabel = useCallback(
    (q: FunnelQuestionType) =>
      sentenceCase(regionalize(Question[q] as string, account?.geography.name)),
    [account],
  );
  const comparisonRange = useMemo(
    () =>
      getDatePeriod(
        datePeriod.start,
        datePeriod.end,
        datePeriod.comparisonPeriod,
      ),
    [datePeriod.start, datePeriod.end, datePeriod.comparisonPeriod],
  );
  const chartData = useMemo(
    () =>
      (
        [
          brandIdList?.includes(COMPETITOR_AVERAGE_ID) &&
            data?.competitorAverage,
          ...(data?.brands ?? []),
        ].filter(Boolean) as BFFOutput["funnel"]["getComparison"]["brands"]
      )
        .sort(sortBrands)
        .map((metric) => ({
          label: metric.brandName ?? "",
          differences: [metric.difference],
          metrics: [
            metric.sourceMetrics[dataRepresentation],
            metric.targetMetrics[dataRepresentation],
          ],
        }))
        .sort(sortBrands),
    [data, dataRepresentation],
  );

  useEffect(() => {
    // v8 ignore next
    analytics?.track(AnalyticsEvents.Comparison, {
      dates: datePeriod,
    });
  }, [datePeriod, analytics]);

  return (
    <div className={styles.page}>
      {question && (
        <RouteSelector
          title="Show comparisons for:"
          selected={{ id: question, label: getQuestionLabel(question) }}
          options={availableQuestions.map((questionType) => ({
            id: questionType,
            label: getQuestionLabel(questionType as FunnelQuestionType),
          }))}
          pathname="/funnel/comparison"
        />
      )}
      <FilterBar
        filters={{
          demographic: true,
          brand: { multiSelect: true, includeCompetitorAverage: true },
        }}
        actions={{
          copyAsImage: sufficient ? comparisonRef : undefined,
        }}
        quality={data?.quality}
      />
      <Comparison
        ref={comparisonRef}
        titlePrefix={`Comparing ${question ? getQuestionLabel(question) : ""}`}
        data={chartData}
        quality={data?.quality}
        sufficient={sufficient}
        configs={[
          {
            label:
              formatDateRange(comparisonRange.start, comparisonRange.end) ?? "",
            subLabel: getComparisonPeriodLabel(datePeriod.comparisonPeriod),
            color: "var(--color-purple-200)",
            valueColor: "var(--color-purple-800)",
          },
          {
            label: formatDateRange(datePeriod.start, datePeriod.end) ?? "",
            subLabel: getDateRangeLabel(datePeriod.start, datePeriod.end, true),
            color: "var(--color-purple-500)",
          },
        ]}
        loading={loading}
      />
      {!loading && data?.quality !== "INSUFFICIENT" && (
        <StatSigExplainer className={styles["stat-sig-explainer"]} />
      )}
    </div>
  );
};
