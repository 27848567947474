import { useAccount } from "$src/stores/useAccount";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type MilestonesWarningStore = {
  enabled: boolean;
  dismiss(): void;
};

export function useCreateMilesontesWarningStore() {
  const account = useAccount((s) => s.active);
  return create<MilestonesWarningStore>()(
    persist(
      (set) => ({
        enabled: true as boolean,
        dismiss: () => set({ enabled: false }),
      }),
      { name: `milestones-warning-${account?.accountBrandId}` },
    ),
  );
}
