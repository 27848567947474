import { createWithEqualityFn } from "zustand/traditional";

type TimelineConfigOptions = {
  xAxis: boolean;
  yAxis: boolean;
  milestones: boolean;
  categoryPenetration: boolean;
  dataPoints: boolean;
  incompleteMonths?: boolean;
};

type TimelineConfigStore = {
  config: TimelineConfigOptions;
  set: (config: Partial<TimelineConfigOptions>) => void;
};

const DEFAULT_STATE: TimelineConfigOptions = {
  xAxis: true,
  yAxis: true,
  milestones: true,
  categoryPenetration: true,
  dataPoints: false,
  incompleteMonths: false,
};

export const useTimelineConfig = createWithEqualityFn<TimelineConfigStore>(
  (set) => ({
    config: DEFAULT_STATE,
    set: (opts) => set((s) => ({ config: { ...s.config, ...opts } })),
  }),
);
