import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";

export const useFunnelTimelineOverview = () => {
  const accountBrand = useAccount((s) => s.active);
  const [{ dateRange, demographics, brandId, questionList }, filtersReady] =
    useFilters((s) => [s.filters, s.ready]);
  const { data, isLoading: loading } =
    apiQueryClient.funnel.getTimelineOverview.useQuery(
      {
        accountBrandId: accountBrand!.accountBrandId,
        startDate: dateRange.start!,
        endDate: dateRange.end!,
        filters: demographics.map(({ id }) => id),
        brandId: brandId!,
        questions: questionList,
      },
      {
        enabled: filtersReady && !!accountBrand,
      },
    );

  return {
    data,
    loading,
  };
};
