import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";

export const useConversionSummary = ({ enabled }: { enabled: boolean }) => {
  const accountBrand = useAccount((s) => s.active);
  const [{ datePeriod, demographics, brandIdList }, filtersReady] = useFilters(
    (s) => [s.filters, s.ready],
  );
  const {
    data,
    isLoading: loading,
    error,
  } = apiQueryClient.conversion.summary.useQuery(
    {
      accountBrandId: accountBrand!.accountBrandId,
      demographicFilterIds: demographics?.map(({ id }) => id) ?? [],
      waveRange: {
        start: datePeriod.start!,
        end: datePeriod.end!,
      },
      includedBrandIds: brandIdList,
    },
    {
      enabled: filtersReady && !!accountBrand && enabled,
    },
  );

  return {
    data,
    loading,
    error,
  };
};
