import { useAccount } from "$src/stores/useAccount";
import { Config } from "$src/stores/useConfig";
import { Auth } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";

import { raygun } from "./raygun";

/* v8 ignore next */
export const amplifyConfig = (appConfig: Config) => ({
  aws_project_region: "ap-southeast-2",
  aws_cognito_identity_pool_id: appConfig?.AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: appConfig?.AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: appConfig?.AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: appConfig?.AWS_COGNITO_OAUTH_DOMAIN,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "http://localhost:8000/",
    redirectSignOut: "http://localhost:8000/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cloud_logic_custom: [
    {
      name: "tracksuitAPI",
      endpoint: appConfig?.BACKEND_API_URL,
      region: "ap-southeast-2",
    },
  ],
});

/* v8 ignore next */
export const useLogout = () => {
  const clearAccount = useAccount((s) => s.reset);
  const navigate = useNavigate();

  return async function logout() {
    clearAccount();
    localStorage.clear();
    raygun?.("endSession");
    await Auth.signOut();
    navigate("/login");
  };
};
