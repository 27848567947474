import { Header } from "$src/components/header/header";
import { Toast } from "$src/components/toast/toast";
import { useAmplifyAuth } from "$src/hooks/useAmplifyAuth";
import { useInitUser } from "$src/hooks/useInitUser";
import { useShallowUpdateSearchParams } from "$src/hooks/useShallowUpdateSearchParams";
import { cx } from "$src/lib/utils";
import { useAccount } from "$src/stores/useAccount";
import { useInitFilters, useReflectFiltersToUrl } from "$src/stores/useFilters";
import mixins from "$src/styles/mixins.module.css";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import styles from "./layout.module.css";

export const AppLayout = () => {
  const { session, ready: authReady } = useAmplifyAuth();
  const navigate = useNavigate();
  const account = useAccount((s) => s.active);
  const initialised = useInitUser();

  useEffect(() => {
    if (authReady && !session) {
      navigate("/login", {
        replace: true,
      });
    }
  }, [session, authReady]);

  useInitFilters();
  useReflectFiltersToUrl();
  useShallowUpdateSearchParams("accountBrandId", account?.accountBrandId);

  return account ? (
    <main className={cx(styles.app, mixins["grid-page"])}>
      <Header />
      <div className={cx(styles.page, mixins["grid-page"])}>
        <Toast />
        <div className={cx(styles.page, mixins["grid-page"])}>
          {initialised && <Outlet />}
        </div>
      </div>
    </main>
  ) : null;
};
