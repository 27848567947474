import { Button } from "$src/components/button/button";
import { Input } from "$src/components/input/input";
import { Modal } from "$src/components/modal/modal";
import { useAmplifyAuth } from "$src/hooks/useAmplifyAuth";
import { useCopyText } from "$src/hooks/useCopyText";
import { raygun } from "$src/lib/raygun";
import { cx } from "$src/lib/utils";
import { useMutateTokens, useTokens } from "$src/queries/useTokens";
import { Copy } from "lucide-react";
import { FormEvent, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { Column } from "../column/column";
import { type Token, TokenRow } from "../row/tokens-row/token-row";
import styles from "./tokens-view.module.css";

export const TokensView = () => {
  const { user } = useAmplifyAuth();
  const { data, loading } = useTokens();
  const [tokenName, setTokenName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [showApiToken, setShowApiToken] = useState(false);
  const [copyButton, setCopyButton] = useState("Copy token");
  const [invalidInput, setInvalidInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { createToken } = useMutateTokens();
  const [accessToken, setAccessToken] = useState("");

  const copyToken = useCopyText(accessToken, "API token", () => {
    setCopyButton("Token copied");
    setTimeout(() => {
      setCopyButton("Copy token");
    }, 4000);
  });

  const getStatusRank = (token: Token) => {
    const currentDate = new Date();

    switch (true) {
      case !!token.revokedAt:
        return 2;
      case new Date(token.expiresAt) < currentDate:
        return 0;
      default:
        return 1;
    }
  };

  const sortedTokens = useMemo(() => {
    if (!data || loading) {
      return [];
    }

    return data.slice().sort((a, b) => {
      const statusComparison = getStatusRank(a) - getStatusRank(b);
      if (statusComparison !== 0) {
        return statusComparison;
      }

      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return dateB - dateA;
    });
  }, [data, loading]);

  const disableInput = useMemo(
    () => loading || showApiToken,
    [loading, showApiToken],
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (tokenName.trim() !== "") {
      user &&
        createToken.mutate(
          {
            userId: user.username,
            tokenName,
          },
          {
            onSuccess: (data: any) => {
              setShowApiToken(true);
              setInvalidInput(false);
              setAccessToken(data.accessToken);
            },
            onError: () => {
              raygun?.("send", new Error("Failed to generate token"));
              setShowApiToken(false);
              setInvalidInput(true);
              setErrorMessage(
                "Failed to generate token, please try again or contact support",
              );
            },
          },
        );
    } else {
      setInvalidInput(true);
      setErrorMessage("This field is required");
    }
  };

  const handleCancel = () => {
    setTokenName("");
    setShowApiToken(false);
    setInvalidInput(false);
    setOpenModal(false);
  };

  return (
    <>
      <div className={styles["header-items"]}>
        <h2 className={styles.title}>
          Create API Tokens to integrate Tracksuit data
        </h2>
        <Button
          label={"Generate new token"}
          onClick={() => {
            setOpenModal(true);
          }}
        />
      </div>
      {data && data.length > 0 ? (
        <div className={styles.list} data-testid="tokens-list">
          <Column header first>
            Token name
          </Column>
          <Column header>Date created</Column>
          <Column header>Expires on</Column>
          <Column header>Status</Column>
          <Column header last></Column>

          {loading
            ? Array.from({ length: 5 })
                .fill(undefined)
                .map((_, i) => (
                  <Column
                    first={i === 0}
                    last={i === 4}
                    hoverable={false}
                    key={i}
                  >
                    <Skeleton width={i < 3 ? "10ch" : "5ch"} />
                  </Column>
                ))
            : sortedTokens.map((token) => (
                <TokenRow key={token.id} token={token} />
              ))}
        </div>
      ) : (
        <div className={styles["no-tokens"]} data-testid="no-tokens" />
      )}
      <Modal
        open={openModal}
        onClose={() => {
          handleCancel();
        }}
        heading={loading ? "Loading..." : "Generate API token"}
      >
        <form onSubmit={handleSubmit} data-testid="modal-form">
          {showApiToken ? (
            <div className={styles["text-container"]}>
              <p className={cx(styles.text, loading && styles["grey-text"])}>
                The following token can only be copied once.
              </p>
              <p
                className={cx(
                  styles.text,
                  styles["last-line-of-text"],
                  loading && styles["grey-text"],
                )}
              >
                A new token must be generated there after.
              </p>
            </div>
          ) : (
            <p className={cx(styles.text, styles["last-line-of-text"])}>
              Create a name for your token
            </p>
          )}

          <Input
            label={"Token name"}
            value={tokenName}
            onChange={(e) => setTokenName(e.target.value)}
            required
            className={styles.input}
            invalid={invalidInput}
            disabled={disableInput}
            errorMessage={errorMessage}
          />

          {showApiToken && (
            <Input label={"API token"} value={accessToken} disabled={true} />
          )}

          <div
            className={cx(
              styles["button-container"],
              showApiToken ? styles["token-button"] : styles["token-name"],
            )}
          >
            <Button
              theme={"ghost"}
              label={"Cancel"}
              onClick={(e) => {
                e.preventDefault();
                handleCancel();
              }}
            />

            {showApiToken ? (
              <Button
                label={copyButton}
                icon={Copy}
                onClick={(e) => {
                  e.preventDefault();
                  handleCancel();
                  copyToken();
                }}
              />
            ) : (
              <Button
                type="submit"
                label={"Generate token"}
                loading={loading}
              />
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};
