import { cx } from "$src/lib/utils";
import mixins from "$src/styles/mixins.module.css";
import { useClickOutside, useKeyboardEvent } from "@react-hookz/web";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronDown } from "lucide-react";
import { type ComponentProps, type ReactNode, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { FloatingLabel } from "../floating-label/floating-label";
import styles from "./dropdown.module.css";

export { DropdownItem } from "./lib/dropdown-item/dropdown-item";
export { DropdownSection } from "./lib/dropdown-section/dropdown-section";
export { DropdownSubmenu } from "./lib/dropdown-submenu/dropdown-submenu";

export type DropdownProps = {
  /** Label of the dropdown */
  label?: string | ReactNode;
  /** Optional selected value alongside label */
  selected?: string;
  /** Whether dropdown is open */
  open: boolean;
  /** Whether dropdown is in a loading state */
  loading?: boolean;
  /** Whether to be compact mode */
  compact?: boolean;
  /** Whether dropdown is disabled */
  disabled?: boolean;
  /** Whether dropdown menu should be constrained to trigger width */
  fixedWidth?: boolean;
  /** Visual theme of dropdown */
  theme?: "select" | "pill" | "inline";
  /** Called when dropdown requests open */
  onChange(state: boolean): void;
  /* Option to hide or show chevron */
  chevronVisible?: boolean;
  /* Choose which side the dropdown displays on */
  align?: "left" | "right" | "center";
  /** Whether height is constrailed — HIDES OVERFLOW */
  constrained?: boolean;
  /** Optional information or help text */
  informationText?: string;
  /** Max width of label */
  maxWidth?: string;
} & Omit<ComponentProps<"div">, "onChange" | "onSelect">;

/**
 * @component
 * Generic dropdown menu
 */
export const ControlledDropdown = ({
  open,
  disabled,
  label,
  selected,
  compact,
  constrained,
  fixedWidth,
  informationText,
  theme,
  loading,
  onChange,
  chevronVisible = true,
  align = "center",
  maxWidth,
  className,
  children,
  ...props
}: DropdownProps) => {
  const container = useRef(null);

  // These are excluded from coverage because the hooks themselves are well tested
  /* v8 ignore next */
  useClickOutside(container, () => onChange(false));
  /* v8 ignore next */
  useKeyboardEvent("keydown", (e) => {
    e.key === "Escape" && onChange(false);
  });

  return (
    <div
      className={cx(
        styles.container,
        open && styles.active,
        disabled === true && styles.disabled,
        className,
      )}
      ref={container}
      data-disabled={disabled}
      {...props}
    >
      <div
        className={cx(
          styles["dropdown-trigger"],
          theme && styles[theme],
          compact && styles.compact,
          open && styles.active,
        )}
        onClick={() => !disabled && onChange(!open)}
      >
        <div
          className={cx(
            styles["trigger-label"],
            chevronVisible && styles["with-caret"],
            maxWidth && styles["label-maxwidth"],
          )}
          style={{ maxWidth }}
        >
          {loading ? (
            <Skeleton width="10ch" />
          ) : (
            <>
              {theme === "select" ? (
                <FloatingLabel label={label ?? ""} value={selected} />
              ) : (
                selected ?? label
              )}
            </>
          )}
        </div>
        {!disabled && !loading && chevronVisible && (
          <ChevronDown
            data-testid="dropdown-chevron"
            className={styles["trigger-icon"]}
            data-x-hidden-from-screenshot
          />
        )}
      </div>
      {informationText && (
        <div
          className={cx(
            open && styles.hidden,
            styles["information-text"],
            disabled && styles.disabled,
          )}
        >
          {informationText}
        </div>
      )}
      <AnimatePresence>
        {open && (
          <motion.div
            data-testid="menu"
            className={cx(
              styles["dropdown-menu"],
              constrained && styles.constrained,
              constrained && mixins.scrollable,
              fixedWidth && styles.fixed,
              align === "left" && styles.openLeft,
              align === "right" && styles.openRight,
            )}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 0 }}
            transition={{ duration: 0.1 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export function Dropdown({
  children,
  ...props
}: Omit<DropdownProps, "open" | "onChange">) {
  const [open, setOpen] = useState(false);
  return (
    <ControlledDropdown open={open} onChange={setOpen as any} {...props}>
      {children}
    </ControlledDropdown>
  );
}
