/**
 * Sort brands by name with account brands first, competitor average second, and the rest alphabetically
 */
type BrandLike = {
  brandName?: string;
  isAccountBrand?: boolean;
  isCompetitorAverage?: boolean;
  [key: string]: any;
};

export function sortBrands(a: BrandLike, b: BrandLike) {
  if (a.isAccountBrand === true) {
    return -1;
  } else if (b.isAccountBrand === true) {
    return 1;
  }

  if (a.isCompetitorAverage === true) {
    return -1;
  } else if (b.isCompetitorAverage === true) {
    return 1;
  }

  if (a.brandName === undefined || b.brandName === undefined) {
    return 0;
  }

  return a.brandName.localeCompare(b.brandName);
}
