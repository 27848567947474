import { Auth } from "@aws-amplify/auth";
import { createTRPCClient, httpBatchLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import { v4 as uuid } from "uuid";

import type { AppRouter } from "@tracksuit/bff/trpc";

export const apiQueryClient = createTRPCReact<AppRouter>();

export const trpcHttp = () => {
  return httpBatchLink({
    url: `${window.location.origin}/api`,
    // TRPC will call headers() on every request
    async headers() {
      // Amplify will refresh expired sessions automaically
      const session = await Auth.currentSession();
      return {
        authorization: `Bearer ${await session?.getIdToken().getJwtToken()}`,
        "X-TRACE-ID": uuid(),
      };
    },
  });
};

export const api = createTRPCClient<AppRouter>({
  links: [trpcHttp()],
});
