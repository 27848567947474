import { Button } from "$src/components/button/button";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useCopyText } from "$src/hooks/useCopyText";
import { cx } from "$src/lib/utils";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { useAccount } from "$src/stores/useAccount";
import { kebabCase } from "change-case";
import { format } from "date-fns";
import { lowerFirst } from "lodash-es";
import { ArrowDownRight, ArrowUpRight, Copy, Sparkles } from "lucide-react";
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { FunnelQuestionType, QuestionType } from "@tracksuit/frontend/schemas";
import { toDate } from "@tracksuit/frontend/utils";

import { Question } from "../../../models/Funnel";
import styles from "./insight.module.css";

export type InsightProps = {
  type: "funnel" | "category";
  startDate: string;
  questionType?: FunnelQuestionType;
  title?: string;
  overall?: string;
  demographics?: {
    content: string;
    filterId: string;
    change: "positive" | "negative";
  }[];
  competitors?: string | null;
  interpretation?: string;
  loading?: boolean;
};

export const insightPlaintext = ({
  type,
  questionType,
  title,
  startDate,
  overall,
  demographics,
  competitors,
  interpretation,
}: Partial<InsightProps>) => {
  const formatDrivers = (drivers: string) =>
    type === "category"
      ? `Category size has ${drivers}`
      : `${Question[questionType as QuestionType]} has ${drivers}`;

  return `Since ${format(toDate(startDate!), "MMM yyyy")}, ${lowerFirst(
    title,
  )}. ${overall}. ${formatDrivers(
    demographics
      ? `${demographics
          .map(
            ({ content }, i) =>
              `${
                demographics.length > 2 && i === demographics.length - 1
                  ? "and "
                  : ""
              }${lowerFirst(content)}`,
          )
          .join(`, `)}.`
      : "",
  )}${competitors ? ` ${competitors}.` : ""} ${interpretation}`;
};

export const Insight = ({
  type,
  startDate,
  questionType,
  title,
  overall,
  demographics,
  competitors,
  interpretation,
  loading,
  ...props
}: InsightProps) => {
  const account = useAccount((s) => s.active);
  const { availableFilters } = useAvailableFilters();
  const dateRange = useMemo(
    () => ({
      start: availableFilters.dates?.[5],
      end: availableFilters.dates?.[0],
    }),
    [availableFilters],
  );
  const copyTextContent = useMemo(
    () =>
      insightPlaintext({
        type,
        questionType,
        title,
        overall,
        startDate,
        demographics,
        competitors,
        interpretation,
      }),
    [title, startDate, overall, demographics, competitors, interpretation],
  );
  const analytics = useAnalytics();
  const copyText = useCopyText(copyTextContent, {
    type: "TL;DR insight",
    questionType: questionType as string,
  });

  const getUrl = (filterId?: string) => {
    const basePath = `/funnel/timeline${
      type === "funnel" ? `/metric/${kebabCase(questionType ?? "")}` : ""
    }`;
    const params = new URLSearchParams({
      accountBrandId: String(account?.accountBrandId),
      rangeStartDate: dateRange.start!,
      rangeEndDate: dateRange.end!,
      ...(filterId ? { demographics: String(filterId) } : {}),
    });

    return `${basePath}?${params.toString()}`;
  };

  const getDemographicFilterById = (id: string) => {
    const allFilters = availableFilters?.demographics
      .map(({ filters }) => filters)
      .flat();
    return allFilters.find((f) => f.id === id);
  };

  return (
    <div className={styles.insight} {...props}>
      <h2 className={styles.title}>
        <span>👉</span>
        {loading ? <Skeleton width="20ch" /> : title}
      </h2>
      <p className={cx(styles.content, styles.overall)}>
        {overall}
        {type !== "category" && (
          <>
            {" "}
            -{" "}
            <a
              className={styles.link}
              onClick={() =>
                analytics?.track(AnalyticsEvents.OpenInsightOverview, {
                  questionType,
                })
              }
              href={getUrl()}
              target="_blank"
            >
              view timeline
            </a>
          </>
        )}
      </p>
      <div className={styles.changes}>
        <div>
          <h3 className={styles.subtitle}>What's impacting this?</h3>
          {demographics?.length ? (
            <ul className={styles.demographics}>
              {demographics?.map(({ content, change, filterId }) => (
                <li className={styles.demographic}>
                  {change === "positive" ? (
                    <ArrowUpRight
                      className={cx(
                        styles["demographic-icon"],
                        styles.positive,
                      )}
                    />
                  ) : (
                    <ArrowDownRight
                      className={cx(
                        styles["demographic-icon"],
                        styles.negative,
                      )}
                    />
                  )}
                  {type === "category" ? (
                    content
                  ) : (
                    <a
                      className={styles.link}
                      onClick={() =>
                        analytics?.track(
                          AnalyticsEvents.OpenDemographicDriver,
                          {
                            questionType,
                            change,
                            filter: getDemographicFilterById(filterId)?.filter,
                          },
                        )
                      }
                      href={getUrl(filterId)}
                      target="_blank"
                    >
                      {content}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <span className={styles.placeholder}>
              No significant demographic shifts have occurred in this time
              period
            </span>
          )}
        </div>
        {type === "funnel" && (
          <div className={styles.competitors}>
            <h3 className={styles.subtitle}>
              How does this compare to competitors?
            </h3>
            <p className={styles.content}>{competitors}</p>
          </div>
        )}
      </div>

      <div className={styles.interpretation}>
        <div className={cx(styles["interpretation-title"], styles.subtitle)}>
          <h3>So what?</h3>
          <span className={styles["interpretation-credit"]}>
            <Sparkles className={styles["interpretation-credit-icon"]} />{" "}
            Powered by AI
          </span>
        </div>
        <p className={styles.content}>
          {loading ? <Skeleton width="50ch" /> : interpretation}
        </p>
      </div>

      <div className={styles.foot}>
        <Button
          theme="secondary"
          icon={Copy}
          label="Copy text"
          onClick={copyText}
        />
      </div>
    </div>
  );
};
