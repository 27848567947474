import { Button } from "$src/components/button/button";
import { EmptyState } from "$src/components/empty-state/empty-state";
import { Layout } from "$src/components/layout/layout";
import { StatSigExplainer } from "$src/components/stat-sig-explainer/stat-sig-explainer";
import { Textarea } from "$src/components/textarea/textarea";
import { useCopyText } from "$src/hooks/useCopyText";
import { api } from "$src/lib/api";
import { raygun } from "$src/lib/raygun";
import { cx } from "$src/lib/utils";
import { useAccount } from "$src/stores/useAccount";
import { useToasts } from "$src/stores/useToast";
import mixins from "$src/styles/mixins.module.css";
import {
  Insight,
  InsightProps,
  insightPlaintext,
} from "$src/views/tldr/insight/insight";
import { Stats } from "$src/views/tldr/stats/stats";
import { format, subMonths } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { CopyIcon } from "lucide-react";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FunnelQuestionType } from "@tracksuit/frontend/schemas";

import styles from "./tldr.module.css";

export const TLDRRoute = () => {
  const account = useAccount((s) => s.active);
  const { tldrPage } = useFlags();
  const navigate = useNavigate();
  const dateRange = {
    start: format(subMonths(new Date(), 7), "yyyy-MM-01"),
    end: format(subMonths(new Date(), 1), "yyyy-MM-01"),
  };
  const [rawData, setRawData] = useState<typeof import("./lib/data.json")>();
  const data = useMemo(
    () => rawData?.find((d) => d.accountBrandId === account?.accountBrandId),
    [account, rawData],
  );
  const [feedback, setFeedback] = useState<string>();
  const addToast = useToasts((s) => s.add);
  const [feedbackBusy, setFeedbackBusy] = useState(false);
  const loading = useMemo(() => !rawData, [tldrPage, data, dateRange]);
  const noData = useMemo(() => {
    return !!rawData && !data;
  }, [rawData, data]);
  const allInsightPlaintext = useMemo(
    () =>
      data &&
      `${insightPlaintext({
        type: "category",
        startDate: dateRange.start,
        ...data?.category,
      } as any)}\n\n${data.funnel
        ?.map((insight) =>
          insightPlaintext({
            type: "demographic",
            startDate: dateRange.start,
            ...insight,
          } as any),
        )
        .join("\n\n")}`,
    [data],
  );
  const copyText = useCopyText(allInsightPlaintext ?? "", "TL;DR all insights");

  const submitFeedback = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!feedback || !account) {
      return;
    }

    setFeedbackBusy(true);

    try {
      await api.tldr.sendFeedback.mutate({
        accountBrandId: account.accountBrandId,
        brandName: account.brandName,
        message: feedback,
      });
      setFeedback("");
      addToast("success", {
        id: "tldr-feedback",
        title: "Feedback sent",
        message:
          "Thanks for your feedback, our product team will review it shortly",
      });
    } catch (e) {
      raygun?.("send", e);
      addToast("error", {
        id: "tldr-feedback-error",
        title: "Something went wrong",
        message:
          "They don't call it beta for nothing, our engineers have been notified",
      });
    }

    setFeedbackBusy(false);
  };

  useEffect(() => {
    import("./lib/data.json").then(({ default: data }) => setRawData(data));
  }, []);

  useEffect(() => {
    if (!tldrPage) {
      navigate("/funnel");
    }
  }, [tldrPage, navigate]);

  return noData ? (
    <div className={styles.empty}>
      <EmptyState
        emoji="🏄"
        heading="Hang tight, good things take time."
        text="You need at least 6 months of data so for us to analyze your insights"
      />
    </div>
  ) : (
    <Layout className={styles.tldr}>
      <div className={cx(mixins["grid-page"], styles.head)}>
        <div className={styles.tags}>
          <span className={styles.tag}>{account?.brandName}</span>
          <span className={styles.tag}>{account?.category.name}</span>
          <span className={styles.tag}>{account?.geography.name}</span>
        </div>

        <h1 className={styles.heading}>
          The <em>TL;DR</em> on what's changed in the last 6 months
        </h1>
        <span className={styles["heading-explainer"]}>
          (a.k.a. Too Long; Didn’t Read)
        </span>
      </div>

      <div className={styles.toolbar}>
        <p>
          Heads up, this page is in beta – it may not be perfect and may still
          change based on your feedback.
        </p>
        {/* <p className={styles["toolbar-explainer"]}>
          Heads up, these changes are all{" "}
          <StatSigExplainer
            description="A statistically significant change means we’re confident that something real caused the increase or decrease."
            label="statistically significant"
            includeNeutral={false}
          />
        </p> */}
        <Button icon={CopyIcon} label="Copy all text" onClick={copyText} />
      </div>

      <div className={styles.main}>
        <div className={cx(styles.insights)}>
          <Insight
            type="category"
            startDate={dateRange.start}
            title={data?.category.title}
            overall={data?.category.overall}
            demographics={
              data?.category.demographics as InsightProps["demographics"]
            }
            competitors={null}
            interpretation={data?.category.interpretation}
            loading={loading}
          />
          {loading
            ? Array.from({ length: 3 }).map(() => (
                <Insight type="funnel" loading startDate={dateRange.start} />
              ))
            : data?.funnel?.map((insight) => (
                <Insight
                  type="funnel"
                  startDate={dateRange.start}
                  questionType={insight.questionType as FunnelQuestionType}
                  title={insight.title}
                  overall={insight.overall}
                  demographics={
                    insight.demographics as InsightProps["demographics"]
                  }
                  competitors={insight.competitors}
                  interpretation={insight.interpretation}
                />
              ))}
        </div>

        <section className={styles.sidebar}>
          <Stats />
          <div className={styles.feedback}>
            <h2 className={styles["feedback-title"]}>
              <span>🛠️</span>
              How could we improve?
            </h2>
            <div className={cx(styles["feedback-content"], mixins.richtext)}>
              <ul>
                <li>Was there anything you found challenging?</li>
                <li>
                  What would you add or change about this page to make it more
                  valuable to you?
                </li>
                <li>
                  When you share the insights externally, what edits do you make
                  (if any)?
                </li>
              </ul>
            </div>
            <form className={styles["feedback-form"]} onSubmit={submitFeedback}>
              <Textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                label="Share your thoughts here..."
                disabled={feedbackBusy}
                required
              />
              <div className={styles["feedback-form-actions"]}>
                <Button
                  theme="secondary"
                  label="Send feedback"
                  disabled={feedbackBusy}
                  type="submit"
                />
              </div>
            </form>
          </div>
        </section>
      </div>
    </Layout>
  );
};
