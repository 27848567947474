import { useAccount } from "$src/stores/useAccount";
import { useMemo } from "react";

const TRANSLATIONS = {
  prompted: "aided",
  unprompted: "unaided",
};

const REGIONS: Record<string, boolean> = {
  "United States": true,
  "United Kingdom": true,
  Canada: true,
};

export const regionalize = (input: string, region?: string): string => {
  if (region === undefined || !REGIONS[region]) {
    return input;
  }

  const replacementRules = Object.keys(TRANSLATIONS).map((key) => ({
    from: new RegExp(`\\b${key}\\b`, "gi"),
    to: TRANSLATIONS[key as keyof typeof TRANSLATIONS],
  }));

  return replacementRules.reduce(
    (result, { from, to }) => result.replace(from, to),
    input,
  );
};

export const useRegionalize = (input: string) => {
  const account = useAccount((s) => s.active);
  const regionalized = useMemo(
    () => regionalize(input, account?.geography.name),
    [input, account],
  );

  return regionalized;
};
