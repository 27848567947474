import { Layout } from "$src/components/layout/layout";
import { cx } from "$src/lib/utils";
import { Outlet } from "react-router-dom";

import styles from "./layout.module.css";

export function SettingsLayout() {
  return (
    <Layout className={cx(styles.container)}>
      <Outlet />
    </Layout>
  );
}
