import { Card } from "$src/components/card/card";
import { ControlledCollapsible } from "$src/components/collapsible/collapsible";
import { CopyButton } from "$src/components/copy-button/copy-button";
import { ToggleDataRepresentation } from "$src/components/filters/data-representation/data-representation";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useFunnelQuestions } from "$src/hooks/useFunnelQuestions";
import { STAGE_EXPLANATIONS } from "$src/lib/consts";
import { useRegionalize } from "$src/lib/regionalization";
import { cx } from "$src/lib/utils";
import { Question } from "$src/models/Funnel";
import { useFunnel } from "$src/queries/funnel";
import { media } from "$src/styles";
import { HiddenItems } from "$src/views/hidden-items/hidden-items";
import { useMediaQuery } from "@react-hookz/web";
import { sentenceCase } from "change-case";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  type CSSProperties,
  type ComponentProps,
  Fragment,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import type {
  FunnelQuestionType,
  QuestionType,
} from "@tracksuit/frontend/schemas";

import { useAccount } from "../../../stores/useAccount";
import { BrandComparison } from "./lib/brand-comparison/brand-comparison";
import styles from "./overview.module.css";

export type FunnelStageProps = {
  /** Stage of the funnel */
  stage: QuestionType;
  /** Whether stage is open */
  open: boolean;
  /** Callback when the stage closes */
  onToggleOpen: () => void;
  /** Optional action area */
  action?: ReactNode;
} & ComponentProps<"div">;

/**
 * @component
 * Visual representation of a stage in the brand funnel
 */
const Stage = ({
  stage,
  open,
  onToggleOpen,
  action,
  className,
  ...props
}: FunnelStageProps) => {
  const analytics = useAnalytics();
  const ref = useRef<HTMLDivElement>(null);
  const { data } = useFunnel();
  const question = data?.stages?.[stage as FunnelQuestionType]?.question;
  const isDesktop = useMediaQuery(media.mobile);

  /* v8 ignore next */
  // Not tested as it just calls Segment if open
  useEffect(() => {
    open &&
      analytics?.track(AnalyticsEvents.ExpandAccordion, {
        view: "Funnel Snapshot",
        metadata: {
          metric: question?.questionType,
        },
      });
  }, [open, analytics, question?.questionType]);

  return (
    <div
      ref={ref}
      className={cx(styles.stage, open && styles.open, className)}
      {...props}
    >
      <div>
        <ControlledCollapsible
          onTrigger={() => isDesktop && onToggleOpen()}
          trigger={
            <div
              className={styles["stage-heading"]}
              data-testid="trigger-heading"
            >
              <h3 className={styles["stage-heading-text"]}>
                {sentenceCase(useRegionalize(Question[stage] ?? ""))}
              </h3>
              {open ? (
                <CopyButton
                  imageAreaElement={ref}
                  className={styles["copy-button"]}
                />
              ) : (
                action
              )}
            </div>
          }
          open={open}
        >
          <p className={styles.description} data-testid="description">
            The percentage of people involved in the{" "}
            <strong>{data?.category?.name} category</strong> who{" "}
            {STAGE_EXPLANATIONS[stage]?.verb}{" "}
            <strong>
              {useRegionalize(STAGE_EXPLANATIONS[stage]?.explanation ?? "")}
            </strong>
            .
            {stage === "PREFERENCE"
              ? ` Note: consumers can only select one favourite brand. This makes Preference a difficult metric to move.`
              : ""}
          </p>
        </ControlledCollapsible>
        <BrandComparison
          stage={stage}
          className={styles.details}
          single={!open}
        />
        <ControlledCollapsible open={open}>
          <HiddenItems type="brands" className={styles["hidden-competitors"]} />
          <Card
            className={styles.question}
            heading="How is this asked in the survey?"
          >
            {question?.questionText}
          </Card>
        </ControlledCollapsible>
      </div>
    </div>
  );
};

export const FunnelOverview = (props: ComponentProps<"div">) => {
  const [openStage, setOpenStage] = useState<QuestionType | null>(null);
  const stages = useFunnelQuestions({});
  const account = useAccount((s) => s.active);
  const { singleBrandFunnel } = useFlags();

  const pluralize = (brandName: string) => {
    const endsWithS = brandName.charAt(brandName.length - 1) === "s";

    return `${brandName}’${endsWithS ? "" : "s"}`;
  };

  return (
    <div {...props}>
      {singleBrandFunnel && (
        <div className={styles.header}>
          <h1 className={styles.heading}>
            {account?.brandName ? pluralize(account.brandName) : ""} funnel
          </h1>
          <ToggleDataRepresentation />
        </div>
      )}
      <div
        className={cx(
          styles.stages,
          singleBrandFunnel && styles["single-funnel"],
        )}
      >
        {stages.map((stage, i) => (
          <Fragment key={stage}>
            <Stage
              stage={stage}
              open={openStage === stage}
              action={
                !singleBrandFunnel && i === 0 ? (
                  <ToggleDataRepresentation
                    style={
                      {
                        "--switch-button-bg": "var(--color-purple-100)",
                      } as CSSProperties
                    }
                  />
                ) : undefined
              }
              onToggleOpen={() =>
                setOpenStage(openStage === stage ? null : stage)
              }
            />
            <span
              className={cx(
                styles.border,
                openStage !== stage &&
                  openStage !== stages[i + 1] &&
                  stage !== stages[stages.length - 1] &&
                  styles.visible,
              )}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};
