import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { useDeepCompareEffect } from "@react-hookz/web";
import { useMemo, useState } from "react";

import { formatDateRange } from "@tracksuit/frontend/utils";

import { DateFilterType } from "../components/filters/date/date";
import { useRegionalize } from "../lib/regionalization";
import { Question } from "../models/Funnel";

/**
 * Return a label of demographic and date filters
 */
export const useActiveFiltersLabel: (config?: {
  includeDates?: DateFilterType | boolean;
  includeQuestion?: boolean;
}) => string = ({ includeDates = "period", includeQuestion = false } = {}) => {
  const account = useAccount((s) => s.active);
  const dateFilterKey = useMemo(
    () => (includeDates === "range" ? "dateRange" : "datePeriod"),
    [includeDates],
  );
  const filters = useFilters((s) => s.filters);
  const questionLabel = useRegionalize(String(Question[filters.question]));
  const dateLabel = useMemo(
    () =>
      formatDateRange(filters[dateFilterKey].start, filters[dateFilterKey].end),
    [filters],
  );
  const [labels, setLabels] = useState<string[]>([]);

  useDeepCompareEffect(() => {
    setLabels(
      [
        includeDates && dateLabel,
        filters.demographics?.length
          ? filters.demographics
              .map(({ filter }) => filter)
              .sort((a, b) => a.localeCompare(b))
              .join(", ")
          : "All demographics",
        includeQuestion && questionLabel,
        account?.category.name,
        account?.geography.name,
      ].filter(Boolean) as string[],
    );
  }, [filters, dateLabel, account]);

  return labels.join("  |  ");
};
