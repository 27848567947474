import { BeadChart } from "$src/components/bead-chart/bead-chart";
import { Button } from "$src/components/button/button";
import { Modal } from "$src/components/modal/modal";
import { Tag } from "$src/components/tag/tag";
import { useActiveFiltersLabel } from "$src/hooks/useActiveFiltersLabel";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useCopyImage } from "$src/hooks/useCopyImage";
import { cx, percentageFormatter } from "$src/lib/utils";
import { useFilters } from "$src/stores/useFilters";
import mixins from "$src/styles/mixins.module.css";
import { X } from "lucide-react";
import {
  type ComponentProps,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";

import type { BFFOutput } from "@tracksuit/bff/trpc";

import { ActiveBrandContext, useMetricLabel } from "../../statements";
import { Axis } from "../axis/axis";
import { getMaxValue } from "../getMaxValue";
import styles from "./statement.module.css";

export type StatementProps = {
  /** Statement */
  statement?: string;
  /** Metrics */
  data?: (NonNullable<
    BFFOutput["statements"]["getMetrics"]["statements"][0]["metrics"]
  >[0] & {
    color: {
      active: string;
      default: string;
    };
  })[];
  /** Whether data is loading */
  loading?: boolean;
  /** Click callback */
  onClick?(): void;
  maxValue?: number;
} & ComponentProps<"div">;

const StatementChart = ({
  data,
  maxValue,
  ...props
}: Pick<StatementProps, "data" | "maxValue">) => {
  const { active: activeBrands, setActive } = useContext(ActiveBrandContext);
  const analytics = useAnalytics();
  return (
    <BeadChart
      {...props}
      activeDataPoints={activeBrands?.length ? activeBrands : []}
      onActiveDataPointChange={(id) => {
        setActive?.([id]);
        analytics?.track(AnalyticsEvents.SwappedCompetitor, {
          competitor: data?.find((d) => d.brandId === id)?.brandName,
          view: "Statements",
          metadata: {
            method: "Chart",
          },
        });
      }}
      maxValue={maxValue}
      data={
        data?.map(({ brandId, brandName, percentage, color }) => ({
          id: brandId,
          value: percentage,
          label: brandName,
          color,
        })) ?? []
      }
      {...props}
    />
  );
};

/**
 * @component
 * Single statement in list
 */
export const Statement = ({
  statement,
  data,
  loading,
  maxValue = 1,
  className,
  ...props
}: StatementProps) => {
  const { question } = useFilters((s) => s.filters);
  const imageRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { active: activeBrands, setActive } = useContext(ActiveBrandContext);
  const copyAsImage = useCopyImage(imageRef);
  const filterLabel = useActiveFiltersLabel();
  const analytics = useAnalytics();
  const ownMaxValue = useMemo(
    () => getMaxValue(data ? ([{ metrics: data }] as any) : undefined),
    [data],
  );
  const metricLabel = useMetricLabel(question);

  const copyStatementImage = async () => {
    await copyAsImage?.();
    analytics?.track(AnalyticsEvents.CopyImage, {
      view: "Statement Focus",
      metadata: { statement },
    });
    setOpen(false);
  };

  useEffect(() => {
    open &&
      analytics?.track(AnalyticsEvents.OpenStatement, {
        statement,
      });
  }, [open, analytics, statement]);

  return (
    <div className={cx(className)} {...props}>
      <div className={styles.statement}>
        <div className={styles["statement-inner"]}>
          <div
            className={mixins["clamped-2"]}
            data-testid="statement"
            onClick={() => setOpen(true)}
          >
            {loading ? (
              <Skeleton containerTestId="loading" width="20ch" />
            ) : (
              statement
            )}
          </div>
        </div>
        <div className={styles["statement-graph"]}>
          <StatementChart data={data} maxValue={maxValue} />
        </div>
      </div>

      {!loading && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          large
          ref={imageRef}
          footer={
            <div className={styles["details-actions"]}>
              <Button
                label="Close"
                theme="ghost"
                onClick={() => setOpen(false)}
              />
              {!!copyAsImage && (
                <Button
                  label="Copy as image"
                  theme="primary"
                  onClick={copyStatementImage}
                />
              )}
            </div>
          }
        >
          <X
            className={styles["details-close"]}
            onClick={() => setOpen(false)}
            data-x-hidden-from-screenshot
          />
          <div data-testid="statements-graph">
            <div>
              <span className={styles["details-subheading"]}>
                {metricLabel}
              </span>
              <h1
                className={styles["details-heading"]}
                data-testid="modal-details-heading"
              >
                {statement}
              </h1>
              <span className={styles["details-filters"]}>{filterLabel}</span>
            </div>
            <div className={styles["details-chart"]}>
              <StatementChart
                data={data}
                maxValue={ownMaxValue}
                data-testid="statement-chart"
              />
              <Axis className={styles.axis} maxValue={ownMaxValue} />
            </div>
            <div
              className={styles["details-legend"]}
              data-testid="details-legend"
            >
              {data
                ?.sort((a, b) => a.percentage - b.percentage)
                .map(({ brandId, brandName, percentage, color }) => (
                  <Tag
                    className={styles["details-legend-tag"]}
                    color={color?.active}
                    active={activeBrands?.includes(brandId)}
                    value={percentageFormatter.format(percentage)}
                    onClick={() => {
                      setActive?.([brandId]);
                      analytics?.track(AnalyticsEvents.SwappedCompetitor, {
                        competitor: brandName,
                        view: "Statements Focus",
                        metadata: {
                          method: "Legend",
                          statement,
                        },
                      });
                    }}
                    label={brandName}
                    key={brandId}
                  />
                ))}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
