import {
  EMPTY_STATE_CONTENT,
  EmptyState,
} from "$src/components/empty-state/empty-state";
import { Layout } from "$src/components/layout/layout";
import { STAGE_EXPLANATIONS } from "$src/lib/consts";
import { useProfile } from "$src/queries/useProfile";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { FilterBar } from "$src/views/filter-bar/filter-bar";
import { Profile } from "$src/views/profile/profile";
import { useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { Navigate } from "react-router-dom";

import {
  type DemographicFilterType,
  demographicFilterTypes,
} from "@tracksuit/frontend/utils";

import styles from "./profile.module.css";

export const ProfileRoute = () => {
  const { question } = useFilters((s) => s.filters);
  const account = useAccount((s) => s.active);
  const { data, loading } = useProfile();
  const imageAreaElement = useRef<HTMLDivElement>(null);

  return !!account && !account?.featuresConfig.profileEnabled ? (
    <Navigate to="/funnel" replace />
  ) : (
    <Layout>
      <FilterBar
        filters={{
          question: true,
        }}
        actions={{
          copyAsImage: imageAreaElement,
        }}
        quality={data?.quality}
      />

      {data?.quality !== "INSUFFICIENT" && question ? (
        <>
          <h1 className={styles.title}>{`Of those who ${
            STAGE_EXPLANATIONS[question]?.verb
          } ${STAGE_EXPLANATIONS[question]?.explanation.replace(
            "the",
            "your",
          )}`}</h1>
          <div className={styles.cards} ref={imageAreaElement}>
            {loading
              ? Array.from({ length: 3 }).map((_, i) => (
                  <div className={styles.placeholder} key={i}>
                    <Skeleton width="10ch" />
                    <div className={styles["placeholder-graphic"]}></div>
                    <Skeleton width="10ch" />
                  </div>
                ))
              : data?.profiles &&
                Object.keys(data.profiles)
                  .filter((key) => key !== "Demographic")
                  .sort(
                    (a, b) =>
                      demographicFilterTypes.indexOf(
                        data.profiles[a]?.[0]
                          ?.filterDisplayType as DemographicFilterType,
                      ) -
                      demographicFilterTypes.indexOf(
                        data.profiles[b]?.[0]
                          ?.filterDisplayType as DemographicFilterType,
                      ),
                  )
                  .map((type) =>
                    data.profiles[type] !== undefined ? (
                      <Profile
                        title={
                          data.profiles[type]?.[0]?.filterDisplayType ?? ""
                        }
                        data={data.profiles[type]!}
                        key={type}
                      />
                    ) : null,
                  )}
          </div>
        </>
      ) : (
        <div className={styles.empty}>
          <EmptyState {...EMPTY_STATE_CONTENT.sample} />
        </div>
      )}
    </Layout>
  );
};
