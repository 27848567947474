import { useCopyImage } from "$src/hooks/useCopyImage";
import { Copy } from "lucide-react";
import { type RefObject, useRef } from "react";

import { IconButton, IconButtonProps } from "../icon-button/icon-button";

type CopyButtonProps = {
  imageAreaElement: RefObject<HTMLDivElement>;
} & Omit<IconButtonProps, "icon">;

/**
 * @component
 * copy image logo
 */
export function CopyButton({ imageAreaElement, ...props }: CopyButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);

  const copyImage = useCopyImage(imageAreaElement);

  return (
    <IconButton
      data-x-hidden-from-screenshot
      ref={ref as any}
      tip={"Copy as image"}
      tipPlacement="top-end"
      onClick={(e) => {
        e.stopPropagation();
        copyImage();
      }}
      icon={Copy}
      {...props}
    />
  );
}
