import { useAmplifyAuth } from "$src/hooks/useAmplifyAuth";
import { apiQueryClient } from "$src/lib/api";

export const useTokens = () => {
  const { user } = useAmplifyAuth();
  const { data, isLoading: loading } =
    apiQueryClient.settings.listTokens.useQuery(user?.username ?? "", {
      enabled: !!user,
    });

  return {
    data,
    loading,
  };
};

export const useMutateTokens = () => {
  const utils = apiQueryClient.useUtils();
  const onSettled = () => utils.settings.listTokens.invalidate();

  return {
    createToken: apiQueryClient.settings.createToken.useMutation({
      onSettled,
    }),
    revokeToken: apiQueryClient.settings.revokeToken.useMutation({
      onSettled,
      onMutate({ userId, tokenId }) {
        utils.settings.listTokens.setData(userId, (old) => {
          return old?.map((token) => {
            if (token.id !== tokenId) {
              return {
                ...token,
                revokedAt: new Date().toString(),
              };
            }
            return token;
          });
        });
      },
    }),
  };
};
