import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";

import { StatementsQuestionType } from "@tracksuit/frontend/schemas";

import { useRequiredDatesCount } from "../../hooks/useRequiredDatesCount";
import { useValidDatePeriod } from "../../hooks/useValidDatePeriod";

export const useStatementsComparison = () => {
  const [{ datePeriod, demographics, brandIdList, question }, filtersReady] =
    useFilters((s) => [s.filters, s.ready]);
  const accountBrand = useAccount((s) => s.active);
  const sufficient = useRequiredDatesCount(6);
  const valid = useValidDatePeriod();
  const { data, isLoading: loading } =
    apiQueryClient.statements.getComparison.useQuery(
      {
        accountBrandId: accountBrand!.accountBrandId,
        startDate: datePeriod.start!,
        endDate: datePeriod.end!,
        filters: demographics?.map(({ filter }) => filter) ?? [],
        filterIds: demographics?.map(({ id }) => id) ?? [],
        comparisonPeriod: datePeriod.comparisonPeriod!,
        question: question as StatementsQuestionType,
        brandIds: brandIdList,
      },
      {
        enabled:
          sufficient && valid.comparison && !!accountBrand && filtersReady,
      },
    );

  return {
    data,
    sufficient,
    loading,
  };
};
