import { media } from "$src/styles";
import { useMediaQuery } from "@react-hookz/web";
import { useEffect, useState } from "react";

export function useChunkLength() {
  const isMobile = useMediaQuery(`(min-width: 700px)`);
  const isTablet = useMediaQuery(`(min-width: 900px)`);
  const isLaptop = useMediaQuery(media.laptop);
  const isDesktop = useMediaQuery(media.screen);
  const [chunkLength, setChunkLength] = useState<number>();

  /* v8 ignore next */
  // Media queries mocked in tests, ignored from coverage
  useEffect(() => {
    if (isDesktop) {
      setChunkLength(5);
    } else if (isLaptop) {
      setChunkLength(4);
    } else if (isTablet) {
      setChunkLength(3);
    } else if (isMobile) {
      setChunkLength(2);
    } else {
      setChunkLength(1);
    }
  }, [isDesktop, isLaptop, isTablet, isMobile]);

  return chunkLength;
}
