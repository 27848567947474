import { cx } from "$src/lib/utils";
import mixins from "$src/styles/mixins.module.css";
import { Outlet } from "react-router-dom";

export function PublicLayout() {
  return (
    <main className={cx(mixins["grid-page"])}>
      <Outlet />
    </main>
  );
}
