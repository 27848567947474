import { cx } from "$src/lib/utils";
import { ArrowDownRight, ArrowUpRight } from "lucide-react";

import { Tooltip } from "../tooltip/tooltip";
import styles from "./stat-sig-explainer.module.css";

type StatSigExplainerProps = {
  description?: string;
  label?: string;
  includeNeutral?: boolean;
  className?: string;
};

export const StatSigExplainer = ({
  description = "A statistically significant change means we’re confident something real caused the increase or decrease. This is calculated and displayed using the key below. You can also hover over the change indicator in the chart to verify.",
  label = "What is a statistically significant change?",
  includeNeutral = true,
  className,
}: StatSigExplainerProps) => {
  return (
    <Tooltip
      large={true}
      compact={true}
      className={cx(styles.tooltip, className)}
      tip={
        <div className={styles["tooltip-inner"]}>
          <p>{description}</p>

          <div className={styles.movements}>
            <div className={styles.movement}>
              <ArrowUpRight
                className={cx(styles["movement-arrow"], styles["positive"])}
              />
              <p>Indicates a statistically significant increase.</p>
            </div>
            <div className={styles.movement}>
              <ArrowDownRight
                className={cx(styles["movement-arrow"], styles["negative"])}
              />
              <p>Indicates a statistically significant decrease.</p>
            </div>
            {includeNeutral && (
              <p>Black arrows indicate an insignificant change.</p>
            )}
          </div>
        </div>
      }
    >
      <p className={styles["tooltip-trigger"]}>{label}</p>
    </Tooltip>
  );
};
