import { create } from "zustand";

type AdminSettings = {
  impersonateAnonymous: boolean;
  includeIncompleteMonths: boolean;
};

type AdminSettingsStore = {
  settings: AdminSettings;
  set: (settings: Partial<AdminSettings>) => void;
};

export const useAdminSettings = create<AdminSettingsStore>()((set) => ({
  settings: {
    impersonateAnonymous: false,
    includeIncompleteMonths: false,
  },
  set: (settings) => set((s) => ({ settings: { ...s.settings, ...settings } })),
}));
