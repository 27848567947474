import { Tooltip } from "$src/components/tooltip/tooltip";
import { HoverContext } from "$src/hooks/useHover";
import { cx } from "$src/lib/utils";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { useMutateMilestones } from "$src/queries/useMilestones";
import { useAccount } from "$src/stores/useAccount";
import mixins from "$src/styles/mixins.module.css";
import { format, isAfter } from "date-fns";
import { Eye, EyeOff } from "lucide-react";
import { type ComponentProps, useContext, useMemo, useState } from "react";

import type { BFFInput } from "@tracksuit/bff/trpc";
import { toDate } from "@tracksuit/frontend/utils";

import type { Milestone } from "../../milestones";
import styles from "./milestone-list-item.module.css";

export type MilestoneListItemProps = {
  /** Data for item */
  milestone: Milestone;
  /** Callback when opening the item */
  onOpen(): void;
};

/**
 * @component
 * List item for milestones
 */
export const MilestoneListItem = ({
  milestone,
  onOpen,
}: MilestoneListItemProps) => {
  const VisibilityIcon = milestone.visible ? Eye : EyeOff;
  const account = useAccount((s) => s.active);
  const { availableFilters } = useAvailableFilters();
  const [hovering, setHovering] = useState(false);
  const { updateMilestone } = useMutateMilestones();
  const isCampaign = useMemo(() => milestone.type === "CAMPAIGN", [milestone]);
  const isFuture = useMemo(
    () =>
      !!milestone.startDate &&
      !!availableFilters.dates?.[0] &&
      isAfter(toDate(milestone.startDate), toDate(availableFilters.dates[0])),
    [milestone.startDate, availableFilters.dates],
  );
  // v8 ignore next
  const toggleVisibility = () => {
    if (!account?.accountBrandId || !milestone.id) {
      throw new Error("No account or milestone");
    }

    updateMilestone.mutate({
      ...milestone,
      accountBrandId: account?.accountBrandId,
      milestoneId: milestone.id,
      categoryEvent: !milestone.brand,
      brandId: Number(milestone.brand?.brandId),
      visibility: !milestone.visible,
    } as BFFInput["milestones"]["update"]);
  };

  return (
    <HoverContext.Provider value={{ hovering, setHovering }}>
      <Column onClick={onOpen} className={styles.first} isFuture={isFuture}>
        <span
          data-testid="milestone-description"
          className={styles.description}
        >
          {milestone.description}
        </span>
      </Column>
      <Column onClick={onOpen} isFuture={isFuture}>
        <span className={styles["brand-name"]}>
          {milestone.brand?.brandName}
        </span>
      </Column>
      <Column onClick={onOpen} isFuture={isFuture}>
        {milestone.startDate
          ? format(toDate(milestone.startDate), "dd/MM/yyyy")
          : ""}
      </Column>
      <Column onClick={onOpen} isFuture={isFuture}>
        {isCampaign && milestone.endDate
          ? format(toDate(milestone.endDate), "dd/MM/yyyy")
          : ""}
      </Column>
      <Column onClick={onOpen} isFuture={isFuture}>
        <span className={mixins["clamped-3"]}>
          {isCampaign ? milestone.channel : ""}
        </span>
      </Column>
      <Column onClick={onOpen} isFuture={isFuture}>
        {isCampaign ? milestone.cost?.toLocaleString() : ""}
      </Column>
      <Column onClick={onOpen} className={styles.last} isFuture={isFuture}>
        {!isFuture && (
          <Tooltip
            title={
              milestone.visible ? (
                <div className={styles["tooltip-title"]}>
                  <EyeOff className={styles["eye-icon"]} />{" "}
                  <div>Hide on timeline</div>
                </div>
              ) : (
                <div className={styles["tooltip-title"]}>
                  <Eye className={styles["eye-icon"]} />
                  <div>Unhide on timeline</div>
                </div>
              )
            }
            tip={
              milestone.visible ? (
                <div className={styles["tooltip-text"]}>
                  This is currently visible on the timeline page
                </div>
              ) : (
                <div className={styles["tooltip-text"]}>
                  This is currently hidden on the timeline page
                </div>
              )
            }
          >
            <div
              className={styles.action}
              onClick={(e) => {
                // v8 ignore next
                e.stopPropagation();
                // v8 ignore next
                toggleVisibility();
              }}
              data-testid="milestone-visibility-toggle"
            >
              <VisibilityIcon className={styles["action-icon"]} />
            </div>
          </Tooltip>
        )}
      </Column>
    </HoverContext.Provider>
  );
};

const Column = ({
  isFuture,
  children,
  className,
  ...props
}: { isFuture: boolean } & ComponentProps<"div">) => {
  const { hovering, setHovering } = useContext(HoverContext);

  return (
    <div
      // v8 ignore next
      onMouseEnter={() => setHovering(true)}
      // v8 ignore next
      onMouseLeave={() => setHovering(false)}
      data-test-hovering={hovering}
      className={cx(
        className,
        styles.column,
        isFuture && styles.future,
        hovering && styles.hovering,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
